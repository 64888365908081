<template>
    <div class="resumeAdopt">
        <div class="content">
            <div class="reject-title">通过筛选，请选择您近期方便安排面试的时间段</div>
            <div class="reject-text">如果未选择，系统默认你近两日都有空</div>
            <div class="table-title">
                <div class="table-left">
                    <div class="table-title-text">时间</div>
                </div>
                <div class="table-right">
                    <div class="table-title-text" v-for="(item,index) in weekList" @click="_week(index)">
                        {{item.week}}<br>
                        {{item.day}}
                    </div>
                </div>
            </div>
            <div class="table-content">
                <div class="table-content-left">
                    <div class="table-content-left-text" v-for="(item,index) in timeList" @click="_time(index)">
                        {{item}}
                    </div>
                </div>
                <div class="table-content-right">
                    <div class="table-content-right-text" v-for="(item,index) in itemList"
                         :class="item.checked==1?'table-content-right-text-active':''" @click="setTime(index)">
                        {{item.checked==1?'ok':''}}
                    </div>
                </div>
            </div>
            <a-textarea
                    style="width: 556px;margin-top: 12px;height: 120px"
                    placeholder="填写相关信息"
                    v-model="Remark"
            />
        </div>
        <div
                :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
                style=" z-index: 100000"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                取消
            </a-button>
            <a-button type="primary" @click="_action()">
                确定
            </a-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "resumeAdopt",
        data() {
            return {
                timeList: [
                    '09:30',
                    '10:00',
                    '10:30',
                    '11:00',
                    '11:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                    '17:00',
                    '17:30'
                ],
                itemList: [],
                weekList: [{'week': '星期一', 'day': '03-01'}, {'week': '星期一', 'day': '03-01'}, {
                    'week': '星期一',
                    'day': '03-01'
                }, {'week': '星期一', 'day': '03-01'}, {'week': '星期一', 'day': '03-01'}, {
                    'week': '星期一',
                    'day': '03-01'
                }, {'week': '星期一', 'day': '03-01'}],
                Time: [],
                Thedate: [],
                Remark: '',
                dataTime: [],

            }
        },
        props: {
            ResumeId: {
                type: String
            }
        },
        created() {
            this.interviewUserDate();
        },
        methods: {
            _action() {
                let dayTime = [];
                let self = this;
                if(self.itemList==0){
                    this.$message.error('请先选择时间');
                    return false;
                }
                this.$message.loading('操作中......',0);
                for (let i=0;i<self.itemList.length;i++){
                    if(self.itemList[i].checked==1){
                        let str = self.itemList[i].year+' '+self.itemList[i].time
                        dayTime.push(str);
                    }
                }
                this.$axios.post(12915, {
                    ResumeId:self.ResumeId,
                    Thedate:JSON.stringify(dayTime),
                    Remark:self.Remark,
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.weekList = res.data.dayList;
                        //self.Remark = res.data.Remark;
                        self.timeList = res.data.TimeList;
                        self.dataTime = res.data.data;
                        self._empty();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _empty() {
                this.itemList = [];
                this.Thedate = [];
                this.Thedate = [];
                this.dataTime = [];
                this.Remark = '';
                this.$emit('visible', false)
            },
            interviewUserDate() {
                let self = this;
                this.$axios.get(12916, {
                    ResumeId: self.ResumeId,
                }, res => {
                    if (res.data.code == 0) {
                        self.weekList = res.data.dayList;
                        //self.Remark = res.data.Remark;
                        self.timeList = res.data.TimeList;
                        self.dataTime = res.data.data;
                        self.getItemList();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            getItemList() {
                let num = (this.timeList.length) * 7;
                let self = this;
                for (let i = 0; i < num; i++) {
                    let arr = {'day': '', 'time': '', "checked": 0, "year": "", "value": ""};
                    let value = '';
                    if (i <= 6) {
                        arr.day = self.weekList[i].day;
                        arr.year = self.weekList[i].year;
                        arr.time = self.timeList[0];
                        arr.value = self.weekList[i].year + ' ' + self.timeList[0];
                        value = self.weekList[i].year + ' ' + self.timeList[0];
                    } else {
                        let daynum = Math.floor(i / 7);
                        let dayKey = i - (daynum * 7);
                        arr.day = self.weekList[dayKey].day;
                        arr.year = self.weekList[dayKey].year;
                        arr.time = self.timeList[daynum];
                        arr.value = self.weekList[dayKey].year + ' ' + self.timeList[daynum];
                        value = self.weekList[dayKey].year + ' ' + self.timeList[daynum];
                    }

                    if (self.dataTime.indexOf(value) != -1) {
                        arr.checked = 1;
                    }
                    self.itemList.push(arr);

                }
                console.log(self.itemList);
            },
            setTime(i) {
                if (this.itemList[i].checked == 1) {
                    this.itemList[i].checked = 0;
                } else {
                    this.itemList[i].checked = 1;
                }
            },
            _week(key) {
                let self = this;
                let day = this.weekList[key].day;
                let checked = this.itemList[key].checked;
                for (let i = 0; i < self.itemList.length; i++) {
                    if (checked == 1) {
                        if (day == self.itemList[i].day) {
                            self.itemList[i].checked = 0;
                        }
                    } else {
                        if (day == self.itemList[i].day) {
                            self.itemList[i].checked = 1;
                        }
                    }
                }
            },
            _time(key) {
                let self = this;
                let daynum = key * 7;
                let time = this.timeList[key];

                console.log(time);
                // let keyNum = key*6;
                let checked = self.itemList[daynum].checked;
                // if(!this.timeKey){
                //     checked = 0 ;
                //
                // }
                for (let i = 0; i < self.itemList.length; i++) {
                    if (checked == 1) {
                        if (time == self.itemList[i].time) {
                            self.itemList[i].checked = 0;
                        }
                    } else {
                        if (time == self.itemList[i].time) {
                            self.itemList[i].checked = 1;
                        }
                    }
                }
                this.timeKey = !this.timeKey;
            },

            onClose() {
                this._empty()

            },
        }

    }
</script>

<style scoped lang="less">
    .reject-title {
        color: #121316;
        font-size: 16px;
        margin-bottom: 6px;
    }

    .reject-text {
        color: #ABABAB;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .table-content {
        display: flex;
        .table-content-left {
            padding-top: 5px;
            border-right: 1px solid #E0E1E2;
            padding-right: 5px;
            .table-content-left-text {
                width: 68px;
                height: 58px;
                text-align: center;
                line-height: 58px;
                background: #FFFFFF;
                border: 1px solid #E0E1E2;
                color: #182347;
                font-size: 12px;
                /*padding-bottom: 0.06rem;*/
                border-bottom: 0px;

            }
            .table-content-left-text:last-child {
                border-bottom: 1px solid #E0E1E2;
            }

        }
        .table-content-right {
            display: flex;
            flex-wrap: wrap;
            padding-left: 5px;
            padding-top: 5px;
            width: 482px;
            .table-content-right-text {
                width: 68px;
                height: 58px;
                text-align: center;
                line-height: 58px;
                background: #FFFFFF;
                border: 1px solid #E0E1E2;
                color: #182347;
                font-size: 12px;
                /*padding-bottom: 0.06rem;*/
                border-bottom: 0px;
                border-right: 0px;
            }
            .table-content-right-text:nth-child(7n) {
                border-right: 1px solid #E0E1E2;
            }
            .table-content-right-text:nth-child(n +113) {
                border-bottom: 1px solid #e0e1e2;
            }
            .table-content-right-text-active {
                background: #EDF6FF;
            }
        }
    }

    .table-title {
        display: flex;
        .table-left {
            padding-right: 5px;
            border-right: 1px solid #E0E1E2;
            border-bottom: 1px solid #E0E1E2;
            padding-bottom: 5px;
            .table-title-text {
                width: 68px;
                height: 58px;
                text-align: center;
                line-height: 58px;
                background: #FFFFFF;
                border: 1px solid #E0E1E2;
                color: #182347;
                font-size: 12px;
                /*padding-bottom: 0.06rem;*/

            }
        }
        .table-right {
            display: flex;
            padding-left: 5px;
            border-bottom: 1px solid #E0E1E2;
            padding-bottom: 5px;
            /*flex-wrap: wrap;*/
            .table-title-text {
                width: 68px;
                height: 58px;
                text-align: center;
                /*line-height: 58px;*/
                background: #FFFFFF;
                border: 1px solid #E0E1E2;
                color: #182347;
                font-size: 12px;
                border-right: 0px;
                padding-top: 10px;
            }
            .table-title-text:last-child {
                border-right: 1px solid #E0E1E2;
            }
        }

    }
</style>