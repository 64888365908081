<template>
    <div class="resumEnclosure">
        <div class="title">附件</div>
        <a-button class="upload">
            上传附件
            <input @change="_upload($event, 'upload')" type="file" id="upload">
        </a-button>
        <p class="tips">支持文档、图片、视频、音频、JPG、PNG文件等格式文件。单次上传文件的总容量小于50MB</p>
        <!--https://sk-fenxiao.qitianzhen.cn/222222222222.png 压缩包  icon-tubiao_ppt   icon-tubiao_xlsx  icon-tubiao_yinpin  icon-tubiao_qita icon-tubiao_pdf icon-tubiao_shipin icon-tubiao_docx  icon-tubiao_tupian-->
        <div  v-if="EnclosureList.length>0">
            <div v-for="(item,index) in EnclosureList">
                <div class="list" >
                    <i class="iconfont icon-tubiao"
                       :class="item.FileName.indexOf('.pdf')!=-1?'icon-tubiao_pdf':
               item.FileName.indexOf('.doc') !=-1?'icon-tubiao_docx':
               Imageicon.indexOf(item.FileName.split('.')[1]) !=-1?'icon-tubiao_tupian':
               item.FileName.indexOf('.ppt')!= -1?'icon-tubiao_ppt':
               item.FileName.indexOf('.xls')!= -1?'icon-tubiao_xlsx':
               item.FileName.indexOf('.zip') != -1 || item.FileName.indexOf('.rar') !=-1?'package':
               item.FileType.indexOf('video/') != -1?'icon-tubiao_shipin':
               item.FileType.indexOf('audio/') != -1?'icon-tubiao_yinpin':'icon-tubiao_qita'
"></i>
                    <div class="list-text" @click="preview(index)"  v-viewer >
                        {{item.FileName}}
                        <img v-if="Imageicon.indexOf(item.FileName.split('.')[1]) !=-1" style="display: none" id="image" :src="item.FileUrl" alt="">
                    </div>

                    <i :class="mouseKey=='icon-xiazai-'+index+item.FileUrl?'color-blue':''"
                       @mouseover="changeSave('icon-xiazai-'+index+item.FileUrl)" @mouseout="_emptyKey"
                       @click="downFile(item.FileUrl,item.FileName)" class="iconfont icon-xiazai- head-item"></i>
                    <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_delFile(index)"
                            okText="确定"
                            cancelText="取消"
                    >
                        <i :class="mouseKey=='del'+index+item.FileName?'color-blue':''"
                           @mouseover="changeSave('del'+index+item.FileName)" @mouseout="_emptyKey"
                           class="iconfont icon-shanchu- head-item"></i>
                    </a-popconfirm>
                </div>

                <audio class="audio" id="audio" v-if="audioKey==index && audioStatus" controls autoplay  :src="item.FileUrl"></audio>

            </div>


        </div>


        <div class="title" style="margin-top: 36px">个人链接</div>
        <div class="personal">
            <a-input style="width: 20%;margin-right: 10px" v-model="LinkName" placeholder="链接名称"/>
            <a-input style="width: 60%;margin-right: 10px" v-model="LinkContent" placeholder="链接内容"/>
            <a-button type="primary" @click="_linkAction">确定</a-button>

        </div>
        <div class="person-list" v-if="PersonaLlinkList.length>0" v-for="(item,index) in PersonaLlinkList">
            <div class="person-list-text">
                <span>{{item.LinkName}}：</span>
                <!--:href="item.LinkContent" target="_blank"-->
                <a @click="_linkOpen(item.LinkContent)">{{item.LinkContent}}</a>
            </div>
            <a-popconfirm
                    title="您确定要删除吗?"
                    @confirm="_delLink(index)"

                    okText="确定"
                    cancelText="取消"
            >
                <i :class="mouseKey=='del_'+index+item.LinkName?'color-blue':''"
                   @mouseover="changeSave('del_'+index+item.LinkName)" @mouseout="_emptyKey"
                   class="iconfont icon-shanchu- head-item"></i>
            </a-popconfirm>


        </div>
        <a-drawer
                title="附件预览"
                width="1100"
                :closable="false"
                :visible="previewStatus"
                @close="previewStatusClose"
        >
            <div id="container" style="min-height: 800px">
               
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="previewStatusClose">
                    关闭
                </a-button>
                <!--<a-button type="primary" @click="previewStatusClose">-->
                <!--关闭-->
                <!--</a-button>-->
            </div>
        </a-drawer>

        <div class="shadw-modal" v-if="videoStatus">
            <div class="shadw" @click="closeVideo"></div>
            <div class="shadw-content">
                <video class="video"  controls autoplay :src="FileUrl"></video>
            </div>
        </div>
    </div>
</template>

<script>
    import Viewer from 'v-viewer';
    import Vue from 'vue';
    Vue.use(Viewer)
    export default {
        name: "resumEnclosure",
        data() {
            return {
                mouseKey: '',
                EnclosureList: [],
                PersonaLlinkList: [],
                LinkName: undefined,
                LinkContent: undefined,
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                Imageicon: ['png', 'jpeg', 'gif', 'jpg', 'PNG', 'JPEG', 'GOF', 'JPG'],
                audioKey: -1,
                audioStatus: false,
                previewStatus:false,
                FileUrl:'',
                videoStatus:false,

            }
        },
        props: {
            infoData: {
                type: Object,
            },
            Pid: {
                //简历Id
                type: String,
            }
        },
        created() {
            console.log(this.infoData);
            if (this.infoData.PersonaLlinkList && this.infoData.PersonaLlinkList != 'null') {
                this.PersonaLlinkList = this.infoData.PersonaLlinkList;

            }
            if (this.infoData.EnclosureList && this.infoData.EnclosureList != 'null') {

                this.EnclosureList = this.infoData.EnclosureList;
                console.log(this.EnclosureList)
            }


        },
        methods: {
            previewStatusClose(){
                this.previewStatus = false;
            },
            closeVideo(){
                this.videoStatus = false;
                this.FileUrl = '';
            },
            preview(index) {
                let files = this.EnclosureList[index];
                if (files.FileType.indexOf('audio/') != -1) {
                    this.audioStatus = true;
                    this.audioKey = index;
                    return false;
                }else{
                    this.audioKey = -1;
                    this.audioStatus = false;
                }
                if(files.FileType.indexOf('video/') != -1){
                    this.videoStatus = true;
                    this.FileUrl = files.FileUrl;

                }else if(this.Imageicon.indexOf(files.FileName.split('.')[1]) !=-1){
                    var e = document.createEvent('MouseEvents') //这里的clickME可以换成你想触发行为的DOM结点
                    e.initEvent('click', true, true) //这里的click可以换成你想触发的行为
                    document.getElementById('image').dispatchEvent(e);
                    console.log(222)
                }else if(files.FileName.indexOf('.zip') != -1 || files.FileName.indexOf('.rar') !=-1){
                    this.$message.error('文件类型不支持预览!');
                    return false;
                }else{
                   if(files.SrcUri){
                       this.previewStatus =true;
                       this.getPreviewUrl(files.SrcUri);
                   }else{
                       this.$message.error('文件类型不支持预览');
                       return false;
                   }
                }


            },
            getPreviewUrl(SrcUri){
                //
                this.$axios.get(
                    1281,
                    {SrcUri: SrcUri},
                    res => {
                        if (res.data.code == 1) {

                            let PreviewURL = res.data.data.PreviewURL
                            let aliyuns = require('../../assets/js/aliyun.js')
                            let demo = aliyun.config({
                                mount: document.querySelector('#container'),
                                url: PreviewURL //设置文档预览URL地址。
                            })
                            //设置AccessToken。
                            demo.setToken({token: res.data.data.AccessToken})
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            downFile(url, name) {
                window.open(url + '?attname=' + name);
            },
            _delFile(i) {
                if (this.EnclosureList.length == 1) {
                    this.EnclosureList = [];
                } else {
                    this.EnclosureList.splice(i, 1);
                }
                this.linkAdd(this.PersonaLlinkList, this.EnclosureList, 0);
            },
            _upload(e, name) {
                this.$message.loading('正在上传中....', 0)
                let self = this;
                let files = document.getElementById(name).files;
                let EnclosureListArr = self.EnclosureList;
                this.__uploadFile__(files).then(res => {
                    this.$message.destroy()
                    if (res.length > 0) {
                        for (let i = 0; i < res.length; i++) {
                            res[i].UserId = this.userinfo.uid;
                            res[i].UserName = this.userinfo.name;
                            res[i].SrcUri = '';
                            EnclosureListArr.push(res[i]);
                        }
                        self.linkAdd(self.PersonaLlinkList, EnclosureListArr, 0);
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },
            _linkAction() {
                if (typeof (this.LinkName) == 'undefined' && !this.LinkName) {
                    this.$message.error('请输入链接名称');
                    return false;
                }
                if (typeof (this.LinkContent) == 'undefined' && !this.LinkContent) {
                    this.$message.error('请输入链接内容');
                    return false;
                }
                let arr = {};
                arr.LinkName = this.LinkName;
                arr.LinkContent = this.LinkContent;
                arr.UserId = this.userinfo.uid;
                arr.UserName = this.userinfo.name;
                let PersonaLlinkListArr = this.PersonaLlinkList;
                PersonaLlinkListArr.push(arr);
                this.linkAdd(PersonaLlinkListArr, this.EnclosureList, 1);

            },
            linkAdd(PersonaLlinkList, EnclosureList, type) {
                let self = this;
                this.$message.loading('操作中...', 0);
                ;
                this.$axios.get(129425, {
                    PersonaLlinkList: JSON.stringify(PersonaLlinkList),
                    EnclosureList: JSON.stringify(EnclosureList),
                    Id: self.Pid,
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        this.$emit('EnclosureCount', res.data.EnclosureCount);
                        if (type == 1) {
                            self.PersonaLlinkList = PersonaLlinkList;
                            self.LinkName = undefined;
                            self.LinkContent = undefined;
                        }
                        if (res.data.data.EnclosureList && typeof(res.data.data.EnclosureList) != 'null') {
                            self.EnclosureList = JSON.parse(res.data.data.EnclosureList);
                        }

                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _delLink(i) {
                if (this.PersonaLlinkList.length == 1) {
                    this.PersonaLlinkList = [];
                } else {
                    this.PersonaLlinkList.splice(i, 1);
                }
                this.linkAdd(this.PersonaLlinkList, this.EnclosureList, 0);
            },
            _linkOpen(val) {
                console.log(val);
                window.open(val);
            },

            changeSave(val) {
                console.log(val);
                this.mouseKey = val;
            },
            _emptyKey() {
                this.mouseKey = '';
            },
        }
    }
</script>

<style scoped lang="less">
    .shadw-modal{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000000;
        .shadw{
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 10000000;
            background-color: rgba(0,0,0,.45);
        }
        .shadw-content{
            width: 500px;
            height: 500px;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index: 100000000;
            .video{
                border: 0px;
                outline: none;
                max-width: 500px;
                max-height: 500px;
            }

        }
    }
    .title {
        color: #121316;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .person-list {
        display: flex;
        width: 514px;
        padding: 10px 12px;
        border: 1px solid #EAEBED;
        margin-top: 10px;
        text-align: center;

        .person-list-text {
            color: #666666;
            font-size: 13px;
            flex: 1;
            text-align: left;
        }

    }

    .upload {
        width: 89px;
        background: #F4F4F5;
        color: #666666;
        position: relative;

        #upload {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            left: 0;
            top: 0;
        }
    }

    .tips {
        color: #666666;
        font-size: 13px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .list {
        display: flex;
        align-items: center;
        border: 1px solid #EAEBED;
        padding: 0px 12px;
        margin-bottom: 10px;
        /*width: 520px;*/
        .head-item {
            margin-left: 8px;
            font-size: 16px;
            color: #9c9c9c;
        }
        .list-text {
            margin-left: 8px;
            color: #353B45;
            font-size: 14px;
            width: 400px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer
        }

    }

    .audio {
        width: 514px;
        margin-bottom: 10px;
        border: 0px;
        outline: none;
    }

    .personal {
        display: flex;

    }

    .color-blue {
        color: rgb(24, 144, 255) !important;
    }

    .icon-tubiao {
        font-size: 28px;
    }

    .icon-tubiao_pdf {
        color: red;
    }

    .icon-tubiao_tupian {
        color: #d93838;
    }

    .icon-tubiao_ppt {
        color: #fab800;

    }

    .icon-tubiao_xlsx {
        color: #16a359;
    }

    .package {
        width: 28.6px;
        height: 44px;
        position: relative;
    }

    .icon-tubiao_shipin {
        color: #d93838;
    }

    .icon-tubiao_yinpin {
        color: #2f77f1;
    }

    .icon-tubiao_docx {
        color: #3581ef;
    }

    .package::after {
        content: '';
        position: absolute;
        width: 22px;
        height: 22px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("https://sk-fenxiao.qitianzhen.cn/222222222222.png") no-repeat;
        background-size: 100% 100%;
    }
</style>