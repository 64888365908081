<template>
    <div class="resumeInterview">
        <a-button class="interview-add" @click="addInterview">添加面试</a-button>
        <div v-for="(item, index) in dataList">
            {{item.Id}}
        </div>
        <interview v-if="interviewStatus" @interviewData="interviewData" :infoData="infoData" :Pid="Pid"></interview>

        <a-drawer
                :title="'安排面试给候选人:' + infoData.Name"
                width="1100"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >
            <div class="interview" v-if="visible">
                <div class="interview-left">
                    <div class="interview-text">
                        {{ infoData.Sex == 1 ? '女' : infoData.Sex == 2 ? '未知' : '男' }} ·
                        {{ infoData.Age }}岁 · {{ infoData.Mobile }} ·
                        {{ infoData.WorkExperience }}年 · {{ infoData.QualificationStr }} ·
                        {{ infoData.WorkPlace }}
                    </div>
                    <div class="group">
                        <div class="group-label">时间</div>
                        <div class="grou-item">
                            <a-date-picker
                                    v-if="TheDate"
                                    :default-value="moment(TheDate, 'YYYY-MM-DD')"
                                    @change="changeTime2($event, 'TheDate')"
                                    style="width: 224px;margin-right: 8px"
                            />
                            <a-date-picker
                                    v-if="!TheDate"
                                    @change="changeTime2($event, 'TheDate')"
                                    style="width: 224px;margin-right: 8px"
                            />
                            <a-select
                                    style="width: 156px"
                                    v-model="InterviewType"
                                    @change="changewangeditor"
                            >
                                <a-select-option value="1">
                                    现场面试
                                </a-select-option>
                                <a-select-option value="2">
                                    线上面试
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="group">
                        <div class="group-label">地点</div>
                        <div class="grou-item">
                            <a-input
                                    style="width: 390px;"
                                    @blur="changewangeditor"
                                    v-model="InterviewAddress"
                                    placeholder="请输入面试地址"
                            />
                        </div>
                    </div>
                    <div class="group" style="width: 620px;">
                        <div class="group-label">安排</div>
                        <div
                                class="grou-item"
                                style="display: flex;align-items: center;"
                                v-for="(item, index) in Stage"
                                :class="index > 0 ? 'grou-item-add' : ''"
                        >
                            <a-select
                                    class="grou-item-arrangement"
                                    v-model="item.InterviewType"
                                    placeholder="轮次"
                            >
                                <a-select-option
                                        v-for="interviewItem in interviewTypeObj"
                                        :value="interviewItem.value"
                                >
                                    {{ interviewItem.name }}
                                </a-select-option>
                            </a-select>

                            <a-time-picker
                                    v-if="item.InterviewTime"
                                    @change="changeTime($event, index)"
                                    class="grou-item-arrangement"
                                    :default-value="moment(item.InterviewTime, 'HH:mm')"
                                    format="HH:mm"
                                    placeholder="时间"
                            />
                            <a-time-picker
                                    v-if="!item.InterviewTime"
                                    @change="changeTime($event, index)"
                                    class="grou-item-arrangement"
                                    format="HH:mm"
                                    placeholder="时间"
                            />
                            <a-select
                                    class="grou-item-arrangement"
                                    v-model="item.InterviewMinute"
                                    @change="changewangeditor"
                                    placeholder="时长"
                            >
                                <a-select-option
                                        v-for="minuteItem in minute"
                                        :value="minuteItem"
                                >
                                    {{ minuteItem }}分钟
                                </a-select-option>
                            </a-select>

                            <person
                                    class="grou-item-arrangement"
                                    v-if="item.InterviewUserList.length>0"
                                    v-bind="item.recruitObj"
                                    @getData="ChangeSearchUser($event, 'InterviewUserName', index)"
                            ></person>

                            <div v-if="item.InterviewUserList.length==0" class="grou-item-arrangement"
                                 style="position: relative;    border: 1px solid #d9d9d9;height: 32px;border-radius: 5px;color: #bfbfbf;text-align: center;line-height: 32px">
                                {{item.InterviewUserList.length==0?'面试官':''}}
                                <person
                                        style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;z-index: 10"
                                        class="grou-item-arrangement"
                                        :style="item.InterviewUserList.length>0?'opacity:1':''"
                                        v-bind="recruitObj"
                                        @getData="ChangeSearchUser($event, 'InterviewUserName', index)"
                                ></person>
                            </div>

                            <i
                                    v-if="index > 0"
                                    @click="removeArrangement(index)"
                                    class="iconfont icon-shanchu-"
                            ></i>
                        </div>
                    </div>
                    <div class="grou-item-add" @click="addArrangement">添加面试</div>

                    <div class="group" style="align-items: baseline;">
                        <div class="group-label">相关人</div>
                        <div class="grou-item">
                            <div>
                                <a-checkbox
                                        :checked="IsCopyYourself == 1 ? true : false"
                                        @change="changeSend($event, 'IsCopyYourself')"
                                        class="grou-item-check"
                                >抄送自己
                                </a-checkbox>
                            </div>
                            <div>
                                <a-checkbox
                                        :checked="IsInformCandidateByEmail == 1 ? true : false"
                                        @change="changeSend($event, 'IsInformCandidateByEmail')"
                                        class="grou-item-check"
                                >
                                    邮件通知候选人
                                </a-checkbox>
                            </div>
                            <div>
                                <a-checkbox
                                        :checked="IsAddInfo == 1 ? true : false"
                                        @change="changeSend($event, 'IsAddInfo')"
                                        class="grou-item-check"
                                >
                                    添加信息登记表
                                </a-checkbox>
                            </div>
                            <div>
                                <a-checkbox
                                        :checked="IsInformInchargeUserByEmail == 1 ? true : false"
                                        @change="changeSend($event, 'IsInformInchargeUserByEmail')"
                                        class="grou-item-check"
                                >
                                    抄送给招聘负责人
                                </a-checkbox>
                            </div>
                            <!--<div>-->
                            <!--<a-checkbox :checked="IsInformCandidateByShortMessage==1?true:false"-->
                            <!--@change="changeSend($event,'IsInformCandidateByShortMessage')"-->
                            <!--class="grou-item-check">短信通知候选人-->
                            <!--</a-checkbox>-->
                            <!--</div>-->
                        </div>
                    </div>
                    <div class="group" style="align-items: baseline;">
                        <div class="group-label"></div>
                        <div class="grou-item">
                            <div
                                    id="websiteEditorElem"
                                    class="websiteEditorElem"
                                    style="width: 450px"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="interview-rigth">
                    <div class="remark" v-if="DemandUserName">用人部门负责人-{{DemandUserName}}备注：</div>
                    <div class="remark-detail">
                        {{Remark}}
                    </div>
                    <div class="table-list">
                        <div class="table-left">
                            <div class="table-left-title">时间</div>
                            <div class="table-datail" v-for="item in timeList">
                                {{ item }}
                            </div>
                        </div>
                        <div class="table-rigth">
                            <div style="display: flex">
                                <div
                                        v-if="dayList"
                                        v-for="item in dayList"
                                        class="table-left-title table-left-title-active"
                                >
                                    {{ item.week }}
                                    <div style="margin-top: 5px">{{ item.day }}</div>

                                </div>
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                                <!--<div class="table-left-title table-left-title-active">星期三<br>03-01</div>-->
                            </div>
                            <div style="display: flex;flex-wrap: wrap">
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[0]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[1]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[2]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[3]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[4]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[5]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[6]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[7]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[8]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[9]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[10]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[11]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[12]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[13]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[14]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[15]"
                                >
                                    {{ item }}
                                </div>
                                <div
                                        class="table-datail table-datail-active"
                                        v-if="InterviewerTimeList"
                                        v-for="item in InterviewerTimeList[16]"
                                >
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-tips">如果未选择，系统默认你近两日都有空</div>
                </div>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="toAction">
                    确认
                </a-button>
            </div>
        </a-drawer>


    </div>
</template>

<script>
    import E from 'wangeditor'
    import moment from 'moment'
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import interview from 'pages/Hr/interview';
    import Vue from 'vue'
    import VueClipboard from 'vue-clipboard2';//复制
    Vue.use(VueClipboard)
    export default {
        components: {person,interview},
        name: 'resumeInterview',
        data() {
            return {
                Remark: '',
                DemandUserName: '',
                InterviewResultArr: {'0': '未评估', '1': '非常满意', '2': '合适', '3': '不合适', '4': '非常不满意'},
                InterviewTypeArr: {'10': '初试', '20': '复试', '30': '终试'},
                InterviewstypeArr: {'1': '现场面试', '2': '线上面试'},
                Id: '', //面试的id
                visible: false,
                Stage: [
                    {
                        InterviewType: undefined,
                        InterviewTime: undefined,
                        InterviewMinute: undefined,
                        InterviewUserName: '',
                        InterviewEvaluation: '',
                        InterviewResult: '未评估',
                        InterviewUserList: [],
                        recruitObj: {
                            rank: 0, //职级 0为不选，1为选
                            nums: 1, //选的数量
                            department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                            contacts: 'recent11', //常用联系人 不要常用联系人传空''
                            selectArr: [] //要传入的数据
                        }
                    }
                ],

                minute: [30, 60, 90, 120],
                interviewTypeObj: [
                    {name: '初试', value: '10'},
                    {name: '复试', value: '20'},
                    {name: '终试', value: '30'}
                ],
                timeList: [],
                dayList: '',
                InterviewerTimeList: '',
                visible2: false,

                checkCabcelMessage: true,

                TheDate: '',
                InterviewType: '1',
                QuestionnaireId: '',
                InterviewAddress: '',
                IsCopyYourself: 1,
                IsInformCandidateByEmail: 1,
                IsAddInfo: 1,
                IsInformInchargeUserByEmail:1,
                IsInformCandidateByShortMessage: 1,
                Content:
                    '<p><p id="UserName" class="wangeditor-style" style="background: #F4F4F6"> 您好</p> 感谢关注！                                                  很高兴收到您的简历，想和您就 <p id="Position" style="background: #F4F4F6" class="wangeditor-style"> 职位安排一次面试。</p><br> <p id="TheDate" class="wangeditor-style" style="background: #F4F4F6">面试日期</p> <p id="InterviewTime" style="background: #F4F4F6" class="wangeditor-style">面试时间</p> <p id="InterviewMinute" class="wangeditor-style" style="background: #F4F4F6">面试时长</p> （可能视面试情况缩减或延长，请预留一定缓冲时间）<p id="InterviewType"  class="wangeditor-style" style="background: #F4F4F6">面试形式</p> <p id="InterviewAddress"  class="wangeditor-style" style="background: #F4F4F6">面试地址</p> <p id="hrName" style="background: #F4F4F6" class="wangeditor-style">联系人:</p> <p id="hrMovePhone" style="background: #F4F4F6" class="wangeditor-style">电话:</p> <p id="hrMail" style="background: #F4F4F6" class="wangeditor-style"> 邮箱:</p>',
                recruitObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent11', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                dataList: [],
                wangeditor: '',
                interviewStatus:true,
            }
        },
        props: {
            infoData: {
                type: Object
            },
            Pid: {
                //简历Id
                type: String
            }
        },
        created() {

        },
        methods: {
            moment,

            onClose() {
                document.getElementsByClassName('websiteEditorElem').innerHTML = ''
                this._empty()
                this.visible = false
            },
            addInterview() {
                this.InterviewAddress = this.infoData.FullDetailAddr
                this.visible = true
                this.interviewStatus = false;
                this._interviewUserDate()
                this._wangeditor()
            },
            _interviewUserDate() {
                let self = this
                this.$message.loading('加载中...', 0)
                this.$axios.get(1271, {ResumeId: self.Pid}, res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        self.timeList = res.data.TimeList
                        self.dayList = res.data.dayList
                        self.InterviewerTimeList = res.data.data;
                        self.Remark = res.data.Remark;
                        self.DemandUserName = res.data.DemandUserName;
                    }
                })
            },
            addArrangement() {
                let arr = {
                    InterviewType: undefined,
                    InterviewTime: undefined,
                    InterviewMinute: undefined,
                    // InterviewStatus: '面试未进行',
                    InterviewUserName: '',
                    InterviewEvaluation: '',
                    InterviewResult: '未评估',
                    InterviewUserList: [],
                    recruitObj: {
                        rank: 0, //职级 0为不选，1为选
                        nums: 1, //选的数量
                        department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                        contacts: 'recent11', //常用联系人 不要常用联系人传空''
                        selectArr: [] //要传入的数据
                    }
                }
                this.Stage.push(arr)
            },
            removeArrangement(i) {
                this.Stage.splice(i, 1)
            },
            changeTime2(value, name) {
                let str1 = value._d
                let date1 = new Date(str1)
                let startDate =
                    date1.getFullYear() +
                    '-' +
                    (date1.getMonth() + 1 < 10
                        ? '0' + (date1.getMonth() + 1)
                        : date1.getMonth() + 1) +
                    '-' +
                    (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
                if (name == 'TheDate') {
                    this.TheDate = startDate
                    this.changewangeditor()
                }
            },
            changeTime(value, i) {
                let str1 = value._d
                let date1 = new Date(str1)
                let hours =
                    date1.getHours() < 10 ? '0' + date1.getHours() : date1.getHours()
                let minutes =
                    date1.getMinutes() < 10 ? '0' + date1.getMinutes() : date1.getMinutes()
                let seconds =
                    date1.getSeconds() < 10 ? '0' + date1.getSeconds() : date1.getSeconds()
                let hoursStr = hours + ':' + minutes + ':' + seconds
                this.Stage[i].InterviewTime = hoursStr
                this.changewangeditor();
                // this._wangeditor()
            },
            changewangeditor() {
                let self = this;
                let startMinute = ''
                let endMinute = ''
                let num = 0
                if (self.Stage.length > 0) {
                    startMinute = self.Stage[0].InterviewTime
                    if (startMinute) {
                        if (self.Stage.length == 1) {
                            let startMinutestrs = new Array() //定义一数组
                            startMinutestrs = startMinute.split(':') //字符分割
                            if (
                                parseInt(startMinutestrs[1]) +
                                parseInt(self.Stage[0].InterviewMinute) >
                                60
                            ) {
                                endMinute =
                                    parseInt(startMinutestrs[0]) +
                                    1 +
                                    ':' +
                                    (parseInt(startMinutestrs[1]) +
                                        parseInt(self.Stage[0].InterviewMinute) -
                                        60)
                            } else {
                                endMinute =
                                    startMinutestrs[0] +
                                    ':' +
                                    (parseInt(startMinutestrs[1]) +
                                        parseInt(self.Stage[0].InterviewMinute))
                            }
                        } else {
                            let key = parseInt(self.Stage.length) - 1
                            endMinute = self.Stage[key].InterviewTime
                        }
                        let InterviewMinute = 0
                        for (let i = 0; i < self.Stage.length; i++) {
                            if (self.Stage[i].InterviewMinute) {
                                InterviewMinute += self.Stage[i].InterviewMinute
                            }

                        }
                        num = InterviewMinute / 60
                        num = num.toFixed(1) // 输出结果为 2.45
                    }
                }

                document.getElementById("Position").innerText = self.infoData.NeedsPositionName ? self.infoData.NeedsPositionName + "职位安排一次面试" : '职位安排一次面试';
                document.getElementById("UserName").innerText = self.infoData.Name ? self.infoData.Name + '您好：' : '';
                document.getElementById("TheDate").innerText = self.TheDate ? "面试日期:" + self.TheDate : '面试日期:';
                if (startMinute) {
                    document.getElementById("InterviewTime").innerText = "面试时间:" + (startMinute + '-' + endMinute);
                } else {
                    document.getElementById("InterviewTime").innerText = "面试时间:";
                }

                document.getElementById("InterviewMinute").innerText = num > 0 ? "面试时长:" + (num + '小时') : '面试时长:';
                document.getElementById("InterviewType").innerText = self.InterviewType ? "面试形式:" + self.InterviewstypeArr[self.InterviewType] : '面试形式:';
                document.getElementById("InterviewAddress").innerText = self.InterviewAddress ? "面试地址:" + self.InterviewAddress : '面试地址:';

                document.getElementById("hrName").innerText = self.infoData.hrName ? "联系人:" + self.infoData.hrName : '联系人:';
                document.getElementById("hrMovePhone").innerText = self.infoData.hrMovePhone ? "电话:" + self.infoData.hrMovePhone : '电话:';
                document.getElementById("hrMail").innerText = self.infoData.hrMail ? "邮箱:" + self.infoData.hrMail : '邮箱:';

                this.wangeditor.change()
            },


            _wangeditor() {
                document.getElementsByClassName('websiteEditorElem').innerHTML = ''
                let wangeditor = new E('#websiteEditorElem');
                this.wangeditor = wangeditor;
                let self = this
                setTimeout(function () {
                    wangeditor.customConfig.customUploadImg = (files, insert) => {
                        self.__uploadFile__(files).then(res => {
                            if (res) {
                                if (res) {
                                    for (var i = 0; i < res.length; i++) {
                                        insert(res[i].FileUrl)
                                    }
                                }
                            }
                        })
                    }
                    wangeditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor', // 文字颜色
                        'backColor', // 背景颜色
                        'list',
                        'link', // 插入链接
                        'justify', // 对齐方式
                        'quote', // 引用
                        'emoticon', // 表情
                        'image', // 插入图片
                        'table' // 表格
                    ]
                    // let wangeditorField =  self.fieldList[e].FieldName;
                    wangeditor.customConfig.onchange = function (html) {

                        self.Content = html
                        console.log(self.Content)
                    }
                    // 创建一个富文本编辑器

                    wangeditor.create()

                    wangeditor.txt.html(self.Content)
                }, 400)
            },
            changeSend(e, name) {
                if (e.target.checked) {
                    if (name == 'IsCopyYourself') {
                        this.IsCopyYourself = 1
                    } else if (name == 'IsInformCandidateByEmail') {
                        this.IsInformCandidateByEmail = 1
                    } else if (name == 'IsInformCandidateByShortMessage') {
                        this.IsInformCandidateByShortMessage = 1
                    } else if (name == 'IsAddInfo') {
                        this.IsAddInfo = 1;
                    }else if(name=='IsInformInchargeUserByEmail'){
                        this.IsInformInchargeUserByEmail =1;
                    }
                } else {
                    if (name == 'IsCopyYourself') {
                        this.IsCopyYourself = 0
                    } else if (name == 'IsInformCandidateByEmail') {
                        this.IsInformCandidateByEmail = 0
                    } else if (name == 'IsInformCandidateByShortMessage') {
                        this.IsInformCandidateByShortMessage = 0
                    } else if (name == 'IsAddInfo') {
                        this.IsAddInfo = 0;
                    }else if(name =='IsInformInchargeUserByEmail'){
                        this.IsInformInchargeUserByEmail =0;
                    }
                }
            },
            ChangeSearchUser(value, name, i) {
                if (name == 'InterviewUserName') {

                    if (value.length > 0) {
                        this.Stage[i].InterviewUserList = [];
                        let arr = {};
                        arr.title = value[0].title;
                        arr.icon = value[0].icon;
                        arr.id = value[0].id;
                        arr.isuser = value[0].isuser;
                        arr.position = value[0].position;
                        this.Stage[i].InterviewUserList.push(arr);
                        this.Stage[i].InterviewUserName = value[0].title;
                        console.log(this.Stage[i]);
                        this.Stage[i].recruitObj.selectArr = [];
                        this.Stage[i].recruitObj.selectArr.push(arr);

                    } else {
                        this.Stage[i].InterviewUserName = '';
                        this.Stage[i].InterviewUserList = [];
                    }
                }
            },
            toAction() {
                let self = this
                let StageArr = []
                for (let i = 0; i < self.Stage.length; i++) {
                    let str = {}
                    str.InterviewType = self.Stage[i].InterviewType;
                    str.InterviewTime = self.Stage[i].InterviewTime;
                    str.InterviewMinute = self.Stage[i].InterviewMinute;
                    str.InterviewUserName = self.Stage[i].InterviewUserName;
                    str.InterviewEvaluation = self.Stage[i].InterviewEvaluation;
                    str.InterviewResult = self.Stage[i].InterviewResult;
                    str.InterviewUserList = self.Stage[i].InterviewUserList;
                    StageArr.push(str)
                }
                this.$message.loading('操作中...', 0)
                if (!this.TheDate) {
                    this.$message.error('请输入面试日期')
                    return false
                }

                this.$axios.post(
                    1269,
                    {
                        Id: self.Id,
                        TheDate: self.TheDate,
                        InterviewType: self.InterviewType,
                        InterviewAddress: self.InterviewAddress,
                        Stage: JSON.stringify(StageArr),
                        QuestionnaireId: self.QuestionnaireId,
                        IsCopyYourself: self.IsCopyYourself,
                        IsInformCandidateByEmail: self.IsInformCandidateByEmail,
                        IsInformCandidateByShortMessage: self.IsInformCandidateByShortMessage,
                        Content: self.Content,
                        Pid: self.Pid,
                        IsAddInfo: self.IsAddInfo,
                        IsInformInchargeUserByEmail:self.IsInformInchargeUserByEmail,
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg);
                            self._empty();
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            interviewData(infoData){
                let self = this;
                self.TheDate = infoData.TheDate
                self.InterviewType = infoData.InterviewType + ''
                self.QuestionnaireId = infoData.QuestionnaireId
                if (infoData.InterviewAddress) {
                    self.InterviewAddress = infoData.InterviewAddress
                } else {
                    self.InterviewAddress = self.infoData.FullDetailAddr
                }
                self.IsAddInfo = infoData.IsAddInfo;
                self.IsInformInchargeUserByEmail = infoData.IsInformInchargeUserByEmail;
                self.IsCopyYourself = infoData.IsCopyYourself
                self.IsInformCandidateByEmail = infoData.IsInformCandidateByEmail
                self.IsInformCandidateByShortMessage =
                    infoData.IsInformCandidateByShortMessage
                if (infoData.Content) {
                    self.Content = infoData.Content
                } else {
                    self.Content = '<p><p id="UserName" class="wangeditor-style" style="background: #F4F4F6"> 您好</p> 感谢关注！                                                  很高兴收到您的简历，想和您就 <p id="Position" style="background: #F4F4F6" class="wangeditor-style"> 职位安排一次面试。</p><br> <p id="TheDate" class="wangeditor-style" style="background: #F4F4F6">面试日期</p> <p id="InterviewTime" style="background: #F4F4F6" class="wangeditor-style">面试时间</p> <p id="InterviewMinute" class="wangeditor-style" style="background: #F4F4F6">面试时长</p> （可能视面试情况缩减或延长，请预留一定缓冲时间）<p id="InterviewType"  class="wangeditor-style" style="background: #F4F4F6">面试形式</p> <p id="InterviewAddress"  class="wangeditor-style" style="background: #F4F4F6">面试地址</p> <p id="hrName" style="background: #F4F4F6" class="wangeditor-style">联系人:</p> <p id="hrMovePhone" style="background: #F4F4F6" class="wangeditor-style">电话:</p> <p id="hrMail" style="background: #F4F4F6" class="wangeditor-style"> 邮箱:</p>';
                }

                self.Id = infoData.Id

                if (infoData.hasOwnProperty('Stage') && infoData.Stage) {
                    self.Stage = infoData.Stage
                    console.log(self.Stage)
                    for (let i = 0; i < self.Stage.length; i++) {
                        let userStr = {}

                        let arr = {
                            rank: 0, //职级 0为不选，1为选
                            nums: 1, //选的数量
                            department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                            contacts: 'recent11', //常用联系人 不要常用联系人传空''
                            selectArr: [] //要传入的数据
                        }
                        if (self.Stage[i].InterviewerName) {
                            userStr.title = self.Stage[i].InterviewerName
                            userStr.id = self.Stage[i].InterviewerId
                            arr.selectArr.push(userStr)
                            self.Stage[i].InterviewUserList = []
                            self.Stage[i].InterviewUserList.push(userStr)
                        }
                        self.Stage[i].recruitObj = arr;
                        self.Stage[i].InterviewType = self.Stage[i].InterviewType + '';
                    }
                }
                this.visible = true
                this._interviewUserDate()
                this._wangeditor()
            },
            _empty() {
                this.TheDate = ''
                this.InterviewType = '1'
                this.QuestionnaireId = ''
                this.InterviewAddress = this.infoData.FullDetailAddr
                this.IsCopyYourself = 1
                this.IsInformCandidateByEmail = 1
                this.IsInformCandidateByShortMessage = 1
                this.Content = '<p><p id="UserName" class="wangeditor-style" style="background: #F4F4F6"> 您好</p> 感谢关注！                                                  很高兴收到您的简历，想和您就 <p id="Position" style="background: #F4F4F6" class="wangeditor-style"> 职位安排一次面试。</p><br> <p id="TheDate" class="wangeditor-style" style="background: #F4F4F6">面试日期</p> <p id="InterviewTime" style="background: #F4F4F6" class="wangeditor-style">面试时间</p> <p id="InterviewMinute" class="wangeditor-style" style="background: #F4F4F6">面试时长</p> （可能视面试情况缩减或延长，请预留一定缓冲时间）<p id="InterviewType"  class="wangeditor-style" style="background: #F4F4F6">面试形式</p> <p id="InterviewAddress"  class="wangeditor-style" style="background: #F4F4F6">面试地址</p> <p id="hrName" style="background: #F4F4F6" class="wangeditor-style">联系人:</p> <p id="hrMovePhone" style="background: #F4F4F6" class="wangeditor-style">电话:</p> <p id="hrMail" style="background: #F4F4F6" class="wangeditor-style"> 邮箱:</p>'
                this.Stage = [
                    {
                        InterviewType: undefined,
                        InterviewTime: undefined,
                        InterviewMinute: undefined,
                        InterviewUserName: '',
                        InterviewEvaluation: '',
                        InterviewResult: '未评估',
                        InterviewUserList: [],
                        recruitObj: {
                            rank: 0, //职级 0为不选，1为选
                            nums: 1, //选的数量
                            department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                            contacts: 'recent11', //常用联系人 不要常用联系人传空''
                            selectArr: [] //要传入的数据
                        }
                    }
                ]
                document.getElementsByClassName('websiteEditorElem').innerHTML = ''
                this.visible = false
                this.$nextTick(function () {
                    this.interviewStatus =true;
                })
            },


        }
    }
</script>

<style scoped lang="less">
    .resumeInterview {
        margin: auto;
        text-align: center;
    }

    .interview-add {
        width: 200px;
        height: 32px;
        background: #f4f4f5;
        margin-bottom: 36px;
    }

    .content {
        /*width: 667px;*/
        margin: auto;
        .interview-message {
            display: flex;
            height: 44px;
            align-items: center;
            background: #f9f9fa;
            .interview-message-title {
                margin-left: 26px;
                color: #121316;
                font-size: 14px;
                font-weight: 600;
                flex: 1;
                text-align: left;
            }
            .interview-message-feedback {
                width: 156px;
                height: 32px;
                margin-right: 5px;
            }
            .interview-message-action {
                /*width: 31px;*/
                /*font-size: 28px;*/
                border: 1px solid #ccd1dc;
                border-radius: 2px;
                cursor: pointer;
                margin-right: 5px;
                /*height: 32px;*/
            }
        }
        .content-interview {
            border: 1px solid #e4e4e4;
            border-top: 0px;
            padding: 16px 22px 20px 22px;
            .interview-group {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                /*height: 41px;*/
                .interview-label {
                    color: #121316;
                    font-size: 14px;
                    margin-right: 90px;
                    text-align: left;
                    width: 80px;
                }
                .interview-item {
                    color: #121316;
                    font-size: 14px;
                }
                .first-try {
                    width: 42px;
                    height: 21px;
                    background: #00d1db;
                    border-radius: 2px;
                    margin-right: 8px;
                    color: #ffffff;
                }
                .fill-feedback {
                    color: #2994ff;
                    font-size: 14px;
                    /*margin-right: 26px;*/
                    cursor: pointer;
                    flex: 1;
                    text-align: right;
                    margin-right: 15px;
                }
            }
            .interview-group-active {
                padding-left: 10px;
                background: #f9f9fa;
            }
        }
    }

    .interview {
        display: flex;
        .interview-left {
            width: 520px;
            .interview-text {
                color: #121316;
                font-size: 14px;
            }
            .group {
                display: flex;
                margin-top: 16px;
                align-items: center;
                flex-wrap: wrap;
                .group-label {
                    width: 50px;
                }
                .grou-item {
                    .grou-item-arrangement {
                        width: 100px !important;
                        margin-right: 8px;
                    }
                    .grou-item-check {
                        margin-bottom: 28px;
                    }
                }
            }
            .grou-item-add {
                color: #2994ff;
                margin-left: 50px;
                margin-top: 8px;
                cursor: pointer;
            }
        }
        .interview-rigth {
            flex: 1;
            background: #f4f4f5;
            padding: 45px 31px 45px 21px;
            min-height: 850px;
            .remark {
                color: #121316;
                font-size: 16px;
            }
            .remark-detail {
                color: #121316;
                font-size: 12px;
                margin-top: 15px;
                margin-bottom: 31px;
            }
        }
    }

    .table-list {
        display: flex;
        .table-left {
            padding-right: 8px;
            border-right: 1px solid #e0e1e2;
        }
        .table-rigth {
            margin-left: 8px;
            /*display: flex;*/
            /*flex-wrap: nowrap;*/
        }
    }

    .table-left-title {
        width: 60px;
        height: 45px;
        color: #182347;
        font-size: 13px;
        line-height: 45px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #e0e1e2;
        margin-bottom: 22px;
    }

    .table-left-title-active {
        line-height: 1;
        padding-top: 6px;
        border-right: 0px;
    }

    .table-left-title-active:last-child {
        border-right: 1px solid #e0e1e2;
    }

    .table-datail {
        width: 60px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #e0e1e2;
        border-bottom: 0px;
    }

    .table-datail:last-child {
        border-bottom: 1px solid #e0e1e2;
    }

    .table-datail-active {
        border-right: 0px;
    }

    .table-datail-active:nth-child(7n) {
        border-right: 1px solid #e0e1e2;
    }

    .table-datail-active:nth-child(n + 85) {
        border-bottom: 1px solid #e0e1e2;
    }

    .table-tips {
        color: #ababab;
        font-size: 12px;
        margin-top: 12px;
    }



    .feedback-tips {
        color: #121316;
        font-size: 16px;
        margin-bottom: 16px;
    }

    .cancel-interview {
        text-align: left;
        color: #121316;
        font-size: 14px;
    }


</style>
