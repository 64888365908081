<template>
    <div class="resumeRemark">
        <div class="content">
            <div class="remark-content">
                <div>
                    <a-select style="width: 156px" v-if="StatusList.length>0" v-model="Status" placeholder="联系状态">
                        <a-select-option v-for="item in StatusList" :value="item.value">
                            {{item.title}}
                        </a-select-option>

                    </a-select>
                </div>
                <a-textarea class="textarea" v-model="Remark" placeholder="内容"/>
                <div class="remark-flex">
                    <a-popover v-model="visiblePerson" trigger="click">
                        <a slot="content">
                            <a-checkbox-group @change="onChange" v-model="ToUserId">
                                <a-checkbox v-for="item in userList" :value="item.Id" class="check-person">
                                    {{item.Name}}
                                </a-checkbox>

                            </a-checkbox-group>
                        </a>
                        <div class="remark-person">@提醒谁看</div>
                    </a-popover>
                    <div class="remark-send">
                        <a-button type="primary" @click="addRemark">发布</a-button>
                    </div>
                </div>
            </div>
            <div class="remark">
                <a-timeline>
                    <a-timeline-item v-if="tableList.length >0" v-for="item in tableList">
                        <div class="remark-info">
                            <a-avatar :size="32" icon="user"
                                      :src="item.CreateUserAvatar"/>
                            <div class="remark-info-text">
                                <div class="remark-info-name">{{item.CreateUserName}}</div>
                                <div class="remark-info-time">{{item.CreateTime}}</div>
                                <div class="remark-info-content">
                                    <a-button type="primary" :class="item.Value == 888?'talent-pool':item.Value==999 ?'black-person':''" size="small">{{item.Field}}</a-button>
                                    <div class="content-text">{{item.Remark}}</div>
                                    <!--<a-button size="small" type="primary" class="black-person" v-if="item.Value ===999">黑名单 </a-button>-->
                                </div>
                            </div>
                        </div>
                    </a-timeline-item>

                    <!--<a-timeline-item>-->
                        <!--<div class="remark-info">-->
                            <!--<a-avatar :size="32" icon="user"-->
                                      <!--src="https://smart-resource.sikegroup.com/01524d9c66736697e60c6c3e5258ff58.png"/>-->
                            <!--<div class="remark-info-text">-->
                                <!--<div class="remark-info-name">马业倍</div>-->
                                <!--<div class="remark-info-time">2019.11.20 12:30</div>-->
                                <!--<div class="remark-info-content">-->
                                    <!--<a-button type="primary" size="small">已联系-不合适</a-button>-->
                                    <!--<div class="content-text">宝安中心丨老师 丨 2人 丨 吴玉婷</div>-->
                                    <!--<a-button type="primary" class="black-person">黑名单</a-button>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</a-timeline-item>-->
                    <!--<a-timeline-item>-->
                        <!--<div class="remark-info">-->
                            <!--<a-avatar :size="32" icon="user"-->
                                      <!--src="https://smart-resource.sikegroup.com/01524d9c66736697e60c6c3e5258ff58.png"/>-->
                            <!--<div class="remark-info-text">-->
                                <!--<div class="remark-info-name">马业倍</div>-->
                                <!--<div class="remark-info-time">2019.11.20 12:30</div>-->
                                <!--<div class="remark-info-content">-->
                                    <!--<a-button type="primary" size="small">已联系-不合适</a-button>-->
                                    <!--<div class="content-text">宝安中心丨老师 丨 2人 丨 吴玉婷</div>-->
                                    <!--<a-button type="primary" class="black-person">黑名单</a-button>-->
                                    <!--<a-popconfirm-->
                                            <!--title="您确定删除么?"-->
                                            <!--@confirm="delRemark()"-->
                                            <!--okText="确定"-->
                                            <!--cancelText="取消"-->
                                    <!--&gt;-->
                                        <!--<i style="margin-left: 10px" class="iconfont icon-shanchu-" ></i>-->
                                    <!--</a-popconfirm>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</a-timeline-item>-->
                </a-timeline>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "resumeRemark",
        data() {
            return {
                visiblePerson: false,
                StatusList:[],
                userList:[],
                statusList:[],
                ToUserId:[],
                Status:undefined,
                Remark:'',
                tableList:[],
                Stage:'',
            }

        },
        props: {
            infoData: {
                type: Object,
            },
            Pid: {
                //简历Id
                type: String,
            }
        },
        watch:{
            infoData(newValue, oldValue) {
                if(oldValue.NeedsInchargeUserName != newValue.NeedsInchargeUserName ){
                    this.loadData();
                    this.getUserlist();
                }
                if(oldValue.NeedsDemandUserName != newValue.NeedsDemandUserName ){
                    this.getUserlist();
                }

                this.infoData = newValue;
                if(this.infoData.Stage ==20 || this.infoData.Stage ==10){
                    this._StatusList();
                }else{
                   this.StatusList =[];
                }
                // var _this = this;
                // _this.cityName = newValue.cityName;
                // _this.reportId = newValue.reportId;
            }
        },
        created() {

            if(this.infoData.Stage ==20 || this.infoData.Stage ==10){
                this._StatusList();
            }
            this.getUserlist();
            this.loadData();
        },
        methods: {
            loadData(){

                let self = this;
                this.$axios.get(12998,{
                    ResumeId:self.Pid
                },res=>{
                    if(res.data.code==0){
                        self.tableList = res.data.data;
                    }else{
                        self.tableList = [];
                    }
                })
            },
            addRemark(){
                let self = this;
                if(!this.Remark){
                    this.$message.error('请先输入内容');
                    return false;
                }
                this.$axios.post(12997,{
                    Remark:self.Remark,
                    ToUserId:self.ToUserId.toString(),
                    Status:self.Status || '',
                    Id:self.Pid
                },res=>{
                    if(res.data.code==0){
                        self.ToUserId = [];
                        self.Status = undefined;
                        self.Remark ='';
                        self.loadData();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            getUserlist(){
                let self = this;
                this.$axios.get(12996,{
                    ResumeId:self.Pid
                },res=>{
                    if(res.data.code==0){
                        self.userList = res.data.data;
                    }else{
                        self.userList = [];
                    }
                })
            } ,
            _StatusList(){
                let self = this;
                this.$axios.get(12995,{
                    Id:self.Pid
                },res=>{
                    if(res.data.code==0){
                        self.StatusList = res.data.data;
                    }else{
                        self.StatusList = [];
                    }
                })
            },
            hide() {
                this.visiblePerson = false;
            },
            onChange(checkedValue) {
                this.ToUserId = checkedValue;
                
            },
            delRemark(){

            },
        }
    }
</script>

<style scoped lang="less">
    .resumeRemark {
        margin-right: 28px;
    }

    .content {
        border: 1px solid #ccd1dc;
        border-radius: 2px;
        padding: 27px 20px 40px 20px;

        .remark-content {
            background: #F7F8FA;
            padding: 10px 20px 12px 8px;
            border-radius: 4px;
            .textarea {
                display: block;
                width: 100%;
                height: 84px;
                margin-top: 12px;
                margin-bottom: 16px;
            }
            .remark-flex {
                display: flex;
                align-items: center;
                .remark-person {
                    text-align: left;
                    cursor: pointer;
                    color: #666666;
                    font-size: 13px;
                }
                .remark-send {
                    flex: 1;
                    text-align: right;
                }

            }
        }
        .remark {
            margin-top: 10px;
            max-height: 580px;
            overflow-y: auto;
            padding-top: 20px;
            .remark-info {
                display: flex;
                /*align-items: center;*/
                .remark-info-text {
                    flex: 1;
                    margin-left: 6px;
                    .remark-info-name {
                        color: #666666;
                        font-size: 13px;
                    }
                    .remark-info-time{
                        color: #999999;
                        font-size: 12px;
                        margin-bottom: 6px;
                    }
                    .remark-info-content{
                        display: flex;
                        align-items: center;
                        .content-text{
                            color: #666666;
                            font-size: 13px;
                            margin-left: 10px;
                        }
                        .black-person{
                            background: #9a9a9a;
                            color: #FFFFFF;
                            margin-left: 18px;
                            border: 0px;
                        }
                    }


                }
            }
        }
    }

    .check-person {
        display: block;
        margin-left: 0px;
    }
    .talent-pool{
        background: #C9CED9;
        color: #FFFFFF;
        border: 0px;
    }
</style>