<template>
    <div class="resume">
        <div class="tabs">
            <div
                    v-if="statisticsData.length > 0"
                    v-for="item in statisticsData"
                    :class="tabKey == item.value ? 'tab-item-active' : ''"
                    class="tab-item"
                    @click="_tabs(item.value)"
            >
                <div class="tab-item-count">{{ item.count }}</div>
                <div class="tab-item-text">{{ item.Stage }}</div>
            </div>
        </div>
        <!--搜索-->
        <div style="display: flex;align-items: center;margin-bottom: 20px">
            <div class="action" v-if="checkedList.length>0">
                <div class="check-all">
                    <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                    <a-icon style="margin-left: 5px" type="right"/>
                    已选择{{ checkedList.length > 0 ? (checkedList.length) : 0 }}项
                </div>

                <a-popconfirm
                        title="您确定要纳入人才库吗?"
                        @confirm="_ResumeType(1)"
                        okText="确定"
                        cancelText="取消"
                >
                    <a-button class="action-button">纳入人才库</a-button>
                </a-popconfirm>
                <a-button @click="_tags" class="action-button">添加标签</a-button>
                <a-button @click="_recommend" class="action-button">推荐给用人部门</a-button>
                <a-button @click="_relation()" class="action-button">关联招聘需求</a-button>
            </div>
            <div v-if="checkedList.length==0" class="search">
                <div class="check-all">
                    <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                    <a-icon style="margin-left: 5px" type="right"/>
                </div>

                <div class="search-list">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan3 search-list-icon"></i>
                        <div class="search-list-text">
                            {{ PositionValue }}
                            <a-icon v-if="Position" @click="clearTree('Position')" class="tree-close"
                                    type="close-circle"/>
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <a-tree-select
                            v-if="treeData"
                            show-search
                            allow-clear
                            class="search-tree"
                            :treeData="treeData.Position"
                            treeNodeFilterProp="title"
                            @select="_treeSelect"
                            labelInValue
                    >
                    </a-tree-select>
                </div>
                <div class="search-list">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan4 search-list-icon"></i>
                        <div class="search-list-text">
                            {{ TypeName }}
                            <a-icon v-if="Type" @click="clearTree('Type')" class="tree-close" type="close-circle"/>
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <a-tree-select
                            v-if="treeData"
                            show-search
                            allow-clear
                            class="search-tree"
                            :treeData="treeData.Type"
                            treeNodeFilterProp="title"
                            @select="_treeSelect2"
                            labelInValue
                    >
                    </a-tree-select>
                </div>


                <div class="search-list" @click="() => searchModal(true)">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan5 search-list-icon"
                           :style="!isSearch()?'color: #2994FF;':''"></i>
                        <div class="search-list-text" @click.stop="closeSearch()"
                             :style="!isSearch()?'color: #2994FF;z-index:999;':''">
                            {{ isSearch() ? '筛选' : '取消筛选' }}
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <div class="search-model" v-if="modalVisible">
                        <div class="search-group">
                            <div class="search-label">招聘需求：</div>
                            <a-select show-search style="width: 100%" placeholder="请选择招聘需求" v-model="NeedId"
                                      option-filter-prop="children">
                                <a-select-option v-for="item in NeedList" :value="item.Id">
                                    {{ item.PositionName }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="search-group">
                            <div class="search-label">阶段-状态：</div>
                            <a-select show-search style="width: 48%;margin-right: 4%" @change="changeStage"
                                      placeholder="请选择阶段名称"
                                      v-model="Stage"
                                      option-filter-prop="children">
                                <a-select-option v-for="item in stageArr" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            <a-select show-search style="width: 48%;" placeholder="请选择跟进状态" v-model="DetailStage"
                                      option-filter-prop="children">
                                <a-select-option v-for="item in StageObjectDetail[Stage]" :value="item.FieldValue">
                                    {{ item.FieldName }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="search-group">
                            <div class="search-label">渠道来源：</div>
                            <a-select show-search :style="FSource !=0?'width: 100%;':'width:48%'" placeholder="请选择渠道"
                                      v-model="FSource" option-filter-prop="children">
                                <a-select-option v-for="item in FSourceArr" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            <a-select v-if="FSource==0" show-search style="width: 48%;margin-left: 4%"
                                      placeholder="请选择详细渠道"
                                      v-model="Source" option-filter-prop="children">
                                <a-select-option v-for="item in SourceArr" :value="item">
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="search-group">
                            <div class="search-label">意向城市：</div>
                            <a-select show-search style="width: 100%" placeholder="请选择意向城市" v-model="WorkPlace"
                                      option-filter-prop="children">
                                <a-select-option v-for="item in cityList" :value="item">
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="search-group">
                            <div class="search-label">应聘职位：</div>
                            <a-tree-select
                                    v-if="posts_type_arr && ResumePositionTitle"
                                    show-search
                                    allow-clear
                                    style="width: 100%"
                                    :tree-data="posts_type_arr"
                                    treeNodeFilterProp="title"
                                    @select="_treeSelect3"
                                    :defaultValue="{ value: ResumePosition,label:ResumePositionTitle }"
                                    placeholder="请选择应聘职位"
                                    labelInValue
                            >

                            </a-tree-select>
                            <a-tree-select
                                    v-if="posts_type_arr && !ResumePositionTitle"
                                    show-search
                                    allow-clear
                                    style="width: 100%"
                                    :tree-data="posts_type_arr"
                                    treeNodeFilterProp="title"
                                    @select="_treeSelect3"
                                    placeholder="请选择应聘职位"
                                    labelInValue
                            ></a-tree-select>

                        </div>
                        <div class="search-group">
                            <div class="search-label">标签：</div>
                            <a-input style="width: 100%" placeholder="请输入标签" v-model="Tags"/>
                        </div>
                        <div class="search-group">
                            <div class="search-label">录入人：</div>
                            <div class="search-group-item">
                                <person
                                        class="item"
                                        v-bind="personObj"
                                        @getData="ChangeSearchUser($event, 'CreateUserId')"
                                ></person>
                            </div>
                        </div>
                        <div class="search-bottom">
                            <a-button @click.stop="toSearch" type="primary" class="search-bottom-bth">确定</a-button>
                            <a-button @click.stop="() => searchModal(false)" class="search-bottom-bth">取消</a-button>

                        </div>
                    </div>
                </div>
                <div class="search-list" style="width: 200px;display: flex;align-items: center">
                    <a-icon @click="toSearch"
                            style="color: #949FB0;width: 45px;display: block;cursor: pointer;font-size: 20px"
                            type="search"/>
                    <a-input style="border: 0px" v-model="SearchText" placeholder="候选人名字/手机号"></a-input>
                </div>

            </div>
            <a-button v-if="!ProgressNeedId &&　typeof(ProgressNeedId)=='undefined' " type="primary" style="margin-right: 20px" @click="_resumeImport()">导入简历</a-button>
            <a-button v-if="!ProgressNeedId &&　typeof(ProgressNeedId)=='undefined' "  type="primary" style="margin-right: 20px" @click="_add()">添加</a-button>
            <a-button type="primary" v-if="overviewTag" style="margin-right: 20px" @click="resurnHr()">返回</a-button>
        </div>

        <!--        列表-->
        <div class="table">
            <!--            @mouseover="changeIndex(index)"-->
            <!--     @mouseout="_emptyKey"  @mouseover="changeIndex(item.Id)"      -->
            <div class="table-list" v-for="(item,index) in tableList">
                <a-checkbox
                        class="table-checkbox"
                        :checked="searckIndex(index)"
                        @change="checkChange($event,index)">
                </a-checkbox>
                <div class="table-left" @click="_info(item.Id,1)">
                    <div class="table-info">{{ item.CreateTime }} {{ item.CreateUserName }} 录入 丨
                        {{ item.DutiesName ? item.DutiesName : item.Position }} · {{ item.FSource }}
                    </div>
                    <div class="table-user">
                        <div class="table-name">{{ item.Name }}</div>
                        <i v-if="item.Sex==2" class="iconfont icon-nansheng"></i>
                        <i v-if="item.Sex==1" class="iconfont icon-nv"></i>
                        <div class="table-phone"> {{ item.Mobile ? item.Mobile + ' . ' : '' }}
                            {{ item.Age ? item.Age + ' . ' : '' }}
                            {{ item.Qualification ? item.Qualification + ' . ' : '' }} {{ item.WorkPlace }}
                        </div>
                    </div>
                    <div class="table-need-info">
                        <div class="table-need-info-text">
                            {{ item.DepartmentName ? item.DepartmentName + ' | ' : '' }}
                            {{ item.PositionName ? item.PositionName + ' | ' : '' }}
                            {{ item.Number ? item.Number + '人 | ' : '' }}
                            {{ item.DemandUserName ? item.DemandUserName + ' | ' : '' }}
                        </div>
                        <a-avatar class="table-need-info-avatar" v-if="item.InchargeUserNameAvatar" :size="20"
                                  icon="user"
                                  :src="item.InchargeUserNameAvatar"/>
                        <div class="table-need-info-text">{{ item.InchargeUserName }}</div>
                    </div>
                    <a-tag v-if="item.Tags.length>0" v-for="tags in item.Tags">{{ tags }}</a-tag>
                </div>
                <div class="table-right">
                    <div class="table-group">
                        <div class="table-group">阶段-状态：</div>
                        <div class="teble-item">{{ stageObject[item.Stage] }}-{{ item.Status }}</div>
                    </div>
                    <!--                    v-show="index==listIndex"-->
                    <div class="table-action">
                        <a-dropdown :trigger="['click']">
                            <a-menu slot="overlay" @click="handleMenuClick($event,item.Id)">
                                <a-menu-item v-for="stageitem in stageArr" :key="stageitem.value"> {{
                                        stageitem.name
                                    }}
                                </a-menu-item>

                            </a-menu>
                            <a-button class="table-button" type="primary">
                                {{ stageObject[item.Stage] }}
                                <a-icon type="down"/>
                            </a-button>
                        </a-dropdown>

                        <!--                        <a-button class="table-button" type="primary" @click="_stage(item.Id)">用人部门筛选-->
                        <!--                            <a-icon type="down" />-->
                        <!--                        </a-button>-->
                        <a-popconfirm
                                v-if="item.isHr !=1"
                                title="您确定要淘汰掉吗?"
                                @confirm="setResumeStatus(item.Id,item.Name)"
                                okText="确定"
                                cancelText="取消"
                        >
                            <a-button class="table-button">淘汰</a-button>
                        </a-popconfirm>

                        <a-button @click="_info(item.Id,5)" class="table-button">跟踪</a-button>
                    </div>
                </div>
            </div>
        </div>
        <!--        操作-->
        <!--        分页-->
        <a-pagination
                v-if="total"
                v-model="Page"
                @change="ChangePage"
                :pageSize="Limit"
                :total="total"
                style="margin-top: 35px;text-align: right;"
                show-less-items
                show-size-changer
                @showSizeChange="onShowSizeChange"
                :page-size-options="pageSizeOptions"
        />

        <!--简历信息-->

        <a-drawer
                :title="Id ? '简历信息' : '添加简历'"
                width="1000"
                :closable="false"
                :visible="visible"

                @close="onClose"

        >
            <div style="margin-bottom: 60px">
                <resumeInfo v-if="visible" :Id="Id" :OpenType="OpenType" :ResumeKey="ResumeKey+''"
                            @visible="onClose"></resumeInfo>
            </div>
        </a-drawer>
        <!--        关联招聘需求-->
        <a-drawer
                title="招聘需求"
                width="1000"
                :closable="false"
                :visible="needvisible"

                @close="needonClose"

        >
            <div style="margin-bottom: 60px">
                <needsContent v-if="needvisible" :NeedResumeId="ResumeId" @visible="needonClose"></needsContent>
            </div>
        </a-drawer>

        <!--驳回候选人-->
        <a-drawer
                :title="ResumeName.toString()"
                width="600"
                :closable="false"
                :visible="rejectStatus"
                @close="rejectClose"
        >

            <div class="reject-title">你将要淘汰{{ ResumeName.toString() }}，请输入淘汰原因</div>
            <div class="reject-text">候选人将直接纳入人才库</div>

            <a-textarea
                    style="height: 120px"
                    class="recommend-group-item"
                    placeholder="填写相关信息"
                    v-model="rejectContent"
            />

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="rejectClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_rejection()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--添加标签-->
        <a-drawer
                title="添加标签"
                width="600"
                :closable="false"
                :visible="tagStatus"
                @close="tagClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    候选人:
                </div>
                <span class="recommend-group-item">{{ ResumeName.toString() }}</span>

            </div>
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    自定义标签:
                </div>
                <div class="recommend-group-item">
                    <template v-for="(tag, index) in tagValue">
                        <a-tag
                                :key="tag"
                                :closable="closable"
                                @close="() => handleClosetag(tag)"
                        >
                            {{ tag }}
                        </a-tag>
                    </template>
                    <a-input
                            v-if="inputVisible"
                            ref="input"
                            type="text"
                            size="small"
                            :style="{ width: '78px' }"
                            :value="inputValue"
                            @change="handleInputChange"
                            @keyup.enter="handleInputConfirm"
                    />
                    <!--@blur="handleInputConfirm"-->
                    <a-tag
                            v-else
                            style="background: #fff; borderStyle: dashed;"
                            @click="showInput"
                    >
                        <a-icon type="plus"/>
                        自定义
                    </a-tag>
                </div>

            </div>


            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="tagClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_tagAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--        更改状态-->
        <a-drawer
                title="更改阶段-状态"
                width="600"
                :closable="false"
                :visible="stageStatus"
                @close="stageClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    阶段-状态
                </div>
                <div style="flex: 1">
                    <a-select show-search style="width: 48%;margin-right: 4%" placeholder="请选择阶段名称" v-model="StageA"
                              option-filter-prop="children">
                        <a-select-option v-for="item in stageArr" :value="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                    <a-select show-search style="width: 48%;" placeholder="请选择跟进状态" v-model="DetailStageA"
                              option-filter-prop="children">
                        <a-select-option v-for="item in StageObjectDetail[StageA]" :value="item.FieldValue">
                            {{ item.FieldName }}
                        </a-select-option>
                    </a-select>
                </div>

            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="stageClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_stageAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--推荐给用人部门负责人-->
        <a-drawer
                title="推荐给用人部门负责人"
                width="600"
                :closable="false"
                :visible="recommendStatus"
                @close="recommendonClose"
        >
            <a-input :disabled="disabled" :value="ResumeName.toString()"/>
            <div class="recommend-group">
                <div class="recommend-group-label">
                    转发到<span class="recommend-group-label-active">*</span>
                </div>
                <person
                        style="width: 438px"
                        class="recommend-group-item"
                        v-bind="recruitObj"
                        @getData="ChangeSearchUser($event, 'recommendUserList')"
                ></person>
            </div>
            <div class="recommend-group">
                <div class="recommend-group-label">告诉用人部门</div>
                <a-textarea
                        style="height: 120px"
                        class="recommend-group-item"
                        placeholder="例如：大公司从业经历，有成功产品案例"
                        v-model="Remark"
                />
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="recommendonClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_recommendAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--添加简历-->
        <a-drawer
                :title="!Id ? '简历信息' : '添加简历'"
                width="800"
                :closable="false"
                :visible="addVisible"
                @close="addClose"

        >
            <div style="margin-bottom: 60px">
                <resumeAction
                        v-if="addVisible"
                        :Id="Id"
                        @visible="addClose"
                ></resumeAction>
            </div>
        </a-drawer>
        <!--导入简历-->
        <a-drawer
                title="导入简历"
                width="800"
                :closable="false"
                :visible="Importvisible"
                @close="_importClose"
        >
            <div style="margin-bottom: 60px">
                <resumeImport
                        v-if="Importvisible"
                        @visible="_importClose"

                ></resumeImport>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import resumeAction from 'pages/Hr/resumeAction'
import resumeInfo from 'pages/Hr/resumeInfo'
import relationNeed from 'pages/Hr/relationNeed'
import resumeImport from 'pages/Hr/resumeImport'
import needsContent from 'pages/Hr/needsContent'
import E from 'wangeditor'
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
import person from '../../components/SelectPersonnel/SelectPersonnel'

Vue.use(Viewer)
export default {
    name: 'resume',
    components: {resumeAction, resumeInfo, relationNeed, person, resumeImport, needsContent},
    data() {
        return {
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            listIndex: -1,
            posts_type_arr: '',
            cityList: [],
            total: '',
            Page: 0,
            Limit: 10,
            tableList: [],
            //tabs
            statisticsData: [],
            isResumeProgress: 2,//1招聘进展2阶段名称.
            ProgressStageObject: {},
            tabKey: 10,
            tabkeys: '',
            stageObject: {
                '10': '初筛',
                '20': '用人部门筛选',
                '30': '面试',
                '40': '沟通offer',
                '50': '待入职'
            },
            stageArr: [
                {value: '10', name: '初筛'},
                {value: '20', name: '用人部门筛选'},
                {value: '30', name: '面试'},
                {value: '40', name: '沟通offer'},
                {value: '50', name: '待入职'}
            ],
            checkedList: [],
            checkStatus: false,
            Id: '',
            visible: false,
            Position: '',
            PositionValue: '组织架构',
            Type: '我录入的候选人',
            TypeName: '我录入的候选人',
            SearchText: '',
            modalVisible: false,
            treeData: '',
            NeedList: [],
            NeedId: undefined,
            StageObjectDetail: {},
            Stage: undefined,
            DetailStage: undefined,
            FSourceArr: [
                {name: '社会招聘', value: '0'},
                {name: '内部推荐', value: '1'},
                {name: '校园招聘', value: "2"},
                {name: '其他', value: "3"}
            ],
            FSource: undefined,
            SourceArr: [
                '58同城',
                '猎聘网',
                '拉勾网',
                '智联招聘',
                '中华英才网',
                'BOSS直聘',
                '前程无忧(51job)',
                '其他网络招聘'
            ],
            Source: undefined,
            WorkPlace: undefined,
            Tags: '',
            personObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            CreateUserId: '',
            CreateUserIdTitle: '',
            ResumePosition: '',
            ResumePositionTitle: '',
            rejectStatus: false,
            rejectContent: '',
            ResumeId: [],
            ResumeName: [],
            ResumeKey: 1,
            //添加标签
            tagValue: [],
            tagStatus: false,
            inputVisible: false,
            inputValue: '',
            recommendStatus: false,
            recommendUserList: [],
            disabled: true,
            recruitObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 100, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent11', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            Remark: '',
            needvisible: false,
            stageStatus: false,
            StageA: undefined,
            DetailStageA: undefined,
            addVisible: false,
            Importvisible: false,
            overviewTag: this.$route.query.overviewTag ? this.$route.query.overviewTag : '',
        }
    },
    props: {
        //招聘进展
        ProgressNeedId: {
            type: String
        },
        ProgressStage: {
            type: String
        },
        resumeProgress: {
            type: String
        },
        OpenType: {
            type: String,
        },
        InchargeUserPositionName: {
            type: String,
        },
        NeedContentId:{
            type: String,
        }

    },
    watch: {
        resumeProgress: {//深度监听，可监听到对象、数组的变化
            handler(val, oldVal) {
                console.log(val)
                this.Type = '';
                this.TypeName = '全部';
                this.resumeProgress = val;
                this.Page = 0;
                this.loadData();
                this.statisticsStageNum();
            },
            deep: true //true 深度监听
        },
        NeedContentId: {
            handler(val, oldVal) {
                this.Type = '';
                this.TypeName = '全部';
                this.NeedId = val;
                this.ProgressNeedId = val;
                this.Page = 0;
                this.loadData();
                this.statisticsStageNum();
            },
            deep: true //true 深度监听
        }

    },
    mounted() {
        if (!sessionStorage.getItem('posts_type_arr')) {
            this.$axios.get(8007, {}, res => {
                if (res.data.code == 1) {
                    this.posts_type_arr = res.data.data
                    window.sessionStorage.setItem(
                            'posts_type_arr',
                            JSON.stringify(res.data.data)
                    )
                } else {
                    this.$message.error('获取数据失败!')
                }
            })
        } else {
            this.posts_type_arr = JSON.parse(
                    window.sessionStorage.getItem('posts_type_arr')
            )
        }
        this.$axios.get(1257, {}, res => {
            this.cityList = [];
            this.cityList = res.data.data;


        })
    },
    created() {
        if(this.NeedContentId && typeof(thisNeedContentId) !='undefined' ){
            this.NeedId = this.NeedContentId;
        }
        if (this.resumeProgress == 'resumeScreen' || this.resumeProgress == 'myIsDemandUser' || this.resumeProgress == 'myIsInterviewer') {
            this.Type = '';
            this.TypeName = '全部';
            this.selectedKeys = [];

        }
        if (this.overviewTag) {
            //hr总览跳转不用默认搜索
            this.Type = '';
            this.TypeName = '全部';
            this.selectedKeys = [];
        }

        if (typeof (this.ProgressNeedId) != 'undefined' && this.ProgressNeedId) {
            this.Type = '';
        }

        // this.getTagList();
        this.loadData();
        this.statisticsStageNum();
        this.getTree();
        // this._StatusList();
        this.getNeedList();
        this.getDetailStageList();
    },

    methods: {
        _importClose() {
            this.Importvisible = false;
            this.Page = 0;
            this.loadData();
            this.statisticsStageNum();
        },
        _resumeImport() {
            this.Importvisible = true;
        },
        addClose() {
            this.addVisible = false;
            this.Page = 0;
            this.loadData();
            this.statisticsStageNum();
        },
        _add() {
            // this.infoType = 2
            this.addVisible = true
        },
        stageClose() {

            this.$nextTick(() => {
                // 在 DOM 中添加 my-component 组件
                this.StageA = undefined;
                this.DetailStageA = undefined;
                this.Id = '';
                this.stageStatus = false;
            })
            this.listIndex = -1;
        },
        _stageAction() {
            let self = this;
            if (typeof (this.StageA) == 'undefined' && !this.StageA) {
                this.$message.error('请先选择阶段状态');
                return false;
            }
            if (typeof (this.DetailStageA) == 'undefined' && !this.DetailStageA) {
                this.$message.error('请先选择详细阶段状态');
                return false;
            }
            this.$axios.get(129429, {
                Id: self.Id,
                Stage: self.StageA,
                Status: self.DetailStageA
            }, res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.stageClose();
                    this.Page = 0;
                    self.loadData();
                    self.statisticsStageNum();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        handleMenuClick(key, id) {
            this.fieldAction(id, 'Stage', key.key)
        },
        fieldAction(id, field, value) {
            let self = this
            this.$axios.get(
                    1268,
                    {
                        Id: id,
                        Field: field,
                        Value: value
                    },
                    res => {
                        this.Page = 0;
                        self.loadData()
                        self.statisticsStageNum();
                        console.log(res)
                    }
            )
        },
        _stage(id) {
            this.Id = id;
            this.stageStatus = true;
        },
        resurnHr() {
            this.$router.push({path: './hrOverview'})
        },
        getDetailStageList() {
            let self = this;
            this.$axios.get(129428, {}, res => {
                self.StageObjectDetail = res.data.data;
            })
        },
        getNeedList() {
            let self = this;
            this.$axios.get(12939, {
                RelevantType: self.RelevantType,
                Position: self.Position,
                Type: self.Type,
            }, res => {
                if (res.data.code == 0) {
                    self.NeedList = res.data.data;
                } else {
                    self.NeedList = [];
                }
            })
        },
        getTree() {
            let self = this
            this.$axios.get(1265, {}, res => {
                if (res.data.code == 0) {
                    self.treeData = res.data.data
                }
            })
        },

        changeStage(val) {
            // this.tabKey = val;
            this.tabkeys = val;
        },
        _tabs(value) {
            if (this.tabKey == value) {
                return false;
            }
            this.tabKey = value;
            if(this.NeedContentId && typeof(this.NeedContentId) !='undefined'){
                this.ProgressStage = value;
            }else{
                this.tabkeys = value;

            }

            this.Page = 0;
            this.loadData();

        },
        statisticsStageNum() {
            let self = this;
            let data = {
                Position: self.Position,
                Type: self.Type,
                SearchText: self.SearchText,
                NeedId: self.NeedId || '',
                FSource: self.FSource || '',
                Source: self.Source || '',
                Stage: self.tabkeys || '',
                DetailStage: self.DetailStage || '',
                WorkPlace: self.WorkPlace || '',
                Tags: self.Tags,
                ResumePosition: self.ResumePosition,
                CreateUserId: self.CreateUserId,
                overviewTag: self.overviewTag,
                resumeProgress: self.resumeProgress,
                ProgressNeedId: self.ProgressNeedId,
                ProgressStage: self.ProgressStage,

            };
            this.$axios.get(1264, data, res => {
                if (res.data.code == 0) {
                    self.statisticsData = res.data.data;
                    self.tabKey = res.data.data[0].value;
                    //isResumeProgress:2,//1招聘进展2阶段名称
                    self.isResumeProgress = res.data.isResumeProgress;

                }
            })
        },

        loadData() {
            let self = this
            this.$message.loading('加载中...', 0);
            let data = {
                pagesize: self.Limit,
                p: self.Page,
                Position: self.Position,
                Type: self.Type,
                SearchText: self.SearchText,
                NeedId: self.NeedId || '',
                FSource: self.FSource || '',
                Source: self.Source || '',
                Stage: self.tabkeys || '',
                DetailStage: self.DetailStage || '',
                WorkPlace: self.WorkPlace || '',
                Tags: self.Tags,
                ResumePosition: self.ResumePosition,
                CreateUserId: self.CreateUserId,
                overviewTag: self.overviewTag,
                resumeProgress: self.resumeProgress,
                ProgressNeedId: self.ProgressNeedId,
                ProgressStage: self.ProgressStage,


            };
            this.$axios.get(
                    1260, data,
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            self.tableList = [];
                            self.tableList = res.data.data
                            self.total = res.data.total
                        } else {
                            self.tableList = []
                            self.total = '';
                            this.$message.error(res.data.msg)
                        }
                    }
            )
        },
        onShowSizeChange(current, pageSize) {
            this.Page = 0;
            this.Limit = pageSize;
            this.loadData();
        },
        ChangePage(val) {
            this.Page = val
            this.loadData()
        },
        //搜索
        searchModal(val) {
            if (val) {
                if (this.CreateUserId) {
                    let arr = {};
                    arr.title = this.CreateUserIdTile;
                    arr.id = this.CreateUserId;
                    this.personObj.selectArr = [];
                    this.personObj.selectArr.push(arr);
                }

            }
            this.modalVisible = val;
        },
        isSearch() {

            if ((typeof (this.NeedId) != 'undefined' && this.NeedId) || (typeof (this.FSource) != 'undefined' && this.FSource) || (typeof (this.Source) != 'undefined' && this.Source) || (typeof (this.Stage) != 'undefined' && this.Stage) || (typeof (this.DetailStage) != 'undefined' && this.DetailStage) || (typeof (this.WorkPlace) != 'undefined' && this.WorkPlace) || this.Tags || this.ResumePosition || this.CreateUserId) {
                return false;
            } else {
                return true;
            }
        },
        closeSearch() {
            if (!this.isSearch()) {
                this.NeedId = '';
                this.FSource = undefined;
                this.Source = undefined;
                this.Stage = undefined;
                this.DetailStage = undefined;
                this.WorkPlace = undefined;
                this.Tags = '';

                this.ResumePosition = '';
                this.ResumePositionTitle = '';
                this.CreateUserId = '';
                this.CreateUserIdTitle = '';
                this.personObj.selectArr = [];
                this.modalVisible = false;
                this.statisticsStageNum();
                this.getNeedList();
                this.Page = 0;
                this.loadData()
            } else {
                this.modalVisible = true;
            }
        },
        _treeSelect3(val) {
            this.ResumePosition = val.value;
            this.ResumePositionTitle = val.label;
        },
        //树
        clearTree(name) {
            if (name == 'Type') {
                // this.LeftType = 'all';
                this.TypeName = '全部';
                this.Type = '';
            } else if (name == 'Position') {
                this.Position = '';
                this.PositionValue = '组织架构';
            }
            this.statisticsStageNum();
            this.getNeedList();
            this.Page = 0;
            this.loadData()
        },
        _treeSelect(val) {
            this.Position = val.value;
            this.PositionValue = val.label;
            this.statisticsStageNum();
            this.getNeedList();
            this.Type = '';
            this.TypeName = '全部';
            this.Page = 0
            this.loadData();
        },
        _treeSelect2(val) {

            this.Type = val.value;
            this.TypeName = val.label;
            this.Position = '';
            this.PositionValue = '组织架构';
            this.statisticsStageNum();
            this.getNeedList();
            this.Page = 0
            this.loadData();
        },
        ChangeSearchUser(val, name) {
            if (val.length > 0) {
                if (name == 'CreateUserId') {
                    this.CreateUserId = val[0].id;
                    this.CreateUserIdTitle = val[0].title;
                } else if (name == 'recommendUserList') {
                    this.recommendUserList = val;
                }

            }
        },
        toSearch() {
            this.statisticsStageNum();
            this.modalVisible = false;
            this.Page = 0;
            this.loadData()
        },
        //选中
        searckIndex(val) {
            if (this.checkedList.length > 0) {
                let active = 0;
                for (let i = 0; i < this.checkedList.length; i++) {
                    if (val == this.checkedList[i]) {
                        active = 1;

                    }
                }
                if (active == 1) {
                    return true;
                }
            } else {
                return false;
            }
        },
        onCheckAllChange(e) {
            this.checkStatus = e.target.checked;
            this.checkedList = [];
            if (this.checkStatus) {
                if (this.tableList.length > 0) {
                    for (let i = 0; i < this.tableList.length; i++) {
                        this.checkedList.push(i);
                    }
                }
            }
        },
        checkChange(e, val) {
            let active = 0;
            let num = 0;
            for (let i = 0; i < this.checkedList.length; i++) {
                if (val == this.checkedList[i]) {
                    active = 1;
                    num = i;
                }
            }
            if (e.target.checked) {
                //选中
                if (active != 1) {
                    this.checkedList.push(val);
                    if ((this.checkedList.length + 1) == this.Limit) {
                        this.checkStatus = true;
                    }
                }
            } else {
                //取消
                if (active == 1) {
                    if (this.checkedList.length == 1) {
                        this.checkedList = [];
                        this.checkStatus = false;
                    } else {
                        this.checkedList.splice(num, 1);
                    }

                }
            }

        },
        //淘汰
        setResumeStatus(id, name) {
            this.rejectStatus = true;
            this.ResumeId.push(id);
            this.ResumeName.push(name);
        },
        rejectClose() {
            this.rejectStatus = false;
            this.rejectContent = '';
            this.ResumeName = [];
            this.ResumeId = [];
        },
        _rejection() {
            if (!this.rejectContent) {
                this.$message.error('填写相关信息');
                return false;
            }
            self = this;
            this.$message.loading('操作中...', 0);
            this.$axios.get(12914, {
                ResumeId: self.ResumeId[0],
                Status: 4,
                Remark: self.rejectContent
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.rejectClose();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //简历信息
        _info(id, val) {
            this.ResumeKey = val;
            this.Id = id;
            this.visible = true;
        },
        _empty() {
            this.ResumeId = [];
            this.ResumeName = [];
            this.checkStatus = false;
            this.checkedList = [];
        },
        //关联招聘需求
        _relation() {
            if (this.checkedList.length == 0) {
                this.$message.error('请先选择关联招聘需求的简历');
                return false;
            }
            for (let i = 0; i < this.checkedList.length; i++) {
                this.ResumeId.push(this.tableList[this.checkedList[i]].Id);
                this.ResumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.needvisible = true;
        },
        needonClose() {
            this.needvisible = false;
            this._empty();
        },
        //纳入人才库
        _ResumeType(value) {
            if (this.checkedList.length == 0) {
                this.$message.error('请先选择要纳入人才库的简历');
                return false;
            }
            for (let i = 0; i < this.checkedList.length; i++) {
                this.ResumeId.push(this.tableList[this.checkedList[i]].Id);
                this.ResumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            let self = this;
            this.$axios.post(1287, {
                ResumeType: value,
                ResumeId: JSON.stringify(self.ResumeId)
            }, res => {
                if (res.data.code == 0) {
                    this.Page = 0;
                    self.loadData();
                    self.statisticsStageNum();
                    self._empty();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //批量转发给部门负责人
        _recommend() {
            if (this.checkedList.length == 0) {
                this.$message.error('请先选择要批量转发给部门负责人的简历');
                return false;
            }
            for (let i = 0; i < this.checkedList.length; i++) {
                this.ResumeId.push(this.tableList[this.checkedList[i]].Id);
                this.ResumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.recommendStatus = true;
        },
        _recommendAction() {
            let self = this;
            if (self.recommendUserList.length == 0) {
                this.$message.error('请先选择要转发的人');
                return false;
            }

            this.$axios.get(1286, {
                ApprovalerList: JSON.stringify(self.recommendUserList),
                Remark: self.Remark,
                ResumeId: JSON.stringify(self.ResumeId),
            }, res => {
                if (res.data.code == 0) {
                    self.recommendonClose();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        recommendonClose() {
            this.Remark = '';
            this.recommendUserList = [];
            this.recruitObj.selectArr = [];
            this.recommendStatus = false;
            this._empty();
        },
        //添加标签
        _tags() {
            if (this.checkedList.length == 0) {
                this.$message.error('请先选择要添加标签的简历');
                return false;
            }
            for (let i = 0; i < this.checkedList.length; i++) {
                this.ResumeId.push(this.tableList[this.checkedList[i]].Id);
                this.ResumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.tagStatus = true;
        },
        tagClose() {
            this.inputValue = '';
            this.tagStatus = false;
            this.tagValue = [];
            this._empty();
        },
        handleClosetag(removedTag) {
            const tags = this.tagValue.filter(tag => tag !== removedTag)
            this.tagValue = tags
        },

        showInput() {
            this.inputVisible = true
            this.$nextTick(function () {
                this.$refs.input.focus()
            })

        },

        handleInputChange(e) {
            this.inputValue = e.target.value
        },

        handleInputConfirm() {
            const inputValue = this.inputValue
            let tags = this.tagValue
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue]
            }
            Object.assign(this, {
                tags,
                inputVisible: false,
                inputValue: ''
            })
            this.tagValue = tags;
        },
        _tagAction() {
            let self = this;

            if (self.tagValue.length == 0) {
                this.$message.error('请先添加标签');
                return false;
            }

            this.$axios.post(1288, {
                ResumeId: JSON.stringify(self.ResumeId),
                Tags: JSON.stringify(self.tagValue)
            }, res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.tagClose();
                    this.Page = 0;
                    self.loadData();

                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        onClose() {
            this.statisticsStageNum();
            this.visible = false
            this.Id = '';
            this.Page = 0;
            this.loadData()

        },
        changeIndex(i) {
            console.log(i)
            if (this.listIndex == i) {
                return false;
            }
            this.listIndex = i;
            console.log(this.listIndex)
        },
        _emptyKey() {
            this.listIndex = -1;
        },

    }
}
</script>

<style scoped lang="less">
.resume {
    background: #ffffff;
    //min-height: 800px;
    padding: 12px;
    width: 100%;
    //height: 100%;
}

.table {
    border: 1px solid #E5E7EA;
    border-bottom: 0px;

    .table-list {
        display: flex;
        //align-items: center;
        border-bottom: 1px solid #E5E7EA;
        padding: 15px;

        .table-checkbox {
            width: 50px;
        }

        .table-left {
            flex: 1;

            .table-info {
                color: #666666;
                font-size: 13px;
                margin-bottom: 8px;
            }

            .table-user {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .table-name {
                    color: #121316;
                    font-size: 16px;
                    margin-right: 12px;
                }

                .table-phone {
                    color: #989898;
                    font-size: 13px;
                }
            }

            .table-need-info {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .table-need-info-text {
                    color: #666666;
                    font-size: 13px;
                }

                .table-need-info-avatar {
                    margin-left: 8px;
                    margin-right: 5px;
                }
            }
        }

        .table-right {
            width: 35%;

            .table-group {
                display: flex;
                align-items: center;

                .table-group {
                    color: #989898;
                    font-size: 13px;
                }

                .teble-item {
                    color: #666666;
                    font-size: 14px;
                }
            }

            .table-action {
                margin-top: 35px;
                display: flex;
                align-items: center;

                .table-button {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .stageContent {
                    z-index: 100;
                    cursor: pointer;
                }
            }
        }
    }

    .table-list:hover {
        background: #F7F8F9 !important;
        cursor: pointer;
    }
}

.tabs {
    display: flex;
    border-bottom: 1px solid #dddfe3;
    //padding: 10px;
    margin-bottom: 20px;
    height: 60px;

    .tab-item {
        width: 89px;
        padding-left: 10px;

        /*margin-right: 24px;*/
        cursor: pointer;

        .tab-item-count {
            color: #242424;
            font-size: 22px;
            /*text-align: center;*/
        }

        .tab-item-text {
            color: #999999;
            font-size: 12px;
            /*text-align: center;*/
        }
    }

    .tab-item-active {
        position: relative;
    }

    .tab-item-active::after {
        content: '';
        width: 56px;
        height: 2px;
        background: #2994ff;
        position: absolute;
        left: 0;
        bottom: 0px;
    }
}

.table-list-index {
    background: #F7F8F9 !important;
    cursor: pointer;
}

.search {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 12px;
    //margin-bottom: 20px;
    //margin-top: 20px;

    .search-list {
        position: relative;
        min-width: 138px;
        margin-right: 8px;
        margin-left: 8px;
        border: 1px solid #E8E9EE;
        height: 36px;
        border-radius: 4px;

        .search-model {
            position: absolute;
            left: 0;
            top: 46px;
            width: 500px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            z-index: 1000;
            padding: 15px 18px 0px 18px;

            .search-group {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .search-label {
                    color: #A8A9AA;
                    font-size: 12px;
                    margin-right: 6px;
                    min-width: 82px;
                }

                .search-group-item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .search-bottom {
                display: flex;
                height: 53px;
                background: #F9F9F9;
                border-radius: 0px 0px 5px 5px;
                align-items: center;
                width: 100%;
                padding-right: 20px;
                text-align: right;
                flex-direction: row-reverse;

                .search-bottom-bth {
                    margin-left: 8px;
                }
            }
        }

        .search-list-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0px 8px;

            .search-list-icon {
                color: #999999;
            }

            .search-list-text {
                margin: 0px 5px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .tree-close {
                    color: #999999;
                    margin-left: 5px;
                    z-index: 1000;
                    font-size: 12px;
                }
            }

            .search-list-down {
                color: #999999;
            }
        }

        .search-tree {
            width: 100%;
            opacity: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
        }
    }

}

.foot {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 40px;


}

.action {
    display: flex;
    align-items: center;
    flex: 1;
    //margin-bottom: 20px;
    .action-button {
        width: 120px;
        margin-left: 10px;
    }
}

.icon-nv {
    color: #FF0066;
    font-size: 18px;
}

.icon-nansheng {
    color: rgb(44, 183, 255);
    font-size: 18px;
}

.reject-title {
    color: #121316;
    font-size: 16px;
    margin-bottom: 6px;
}

.reject-text {
    color: #ABABAB;
    font-size: 12px;
    margin-bottom: 12px;
}

.recommend-group {
    display: flex;
    margin-top: 18px;

    .recommend-group-label {
        width: 120px;

        .recommend-group-label-active {
            color: #ff0000;
        }
    }

    .recommend-group-item {
        width: 438px;
    }
}
</style>
