<template>
    <div class="resumeImport">
        <div class="content" v-if="!resultStatus">
            <div class="group">
                <div class="group-label">岗位</div>

                <a-tree-select
                        v-if="posts_type_arr "
                        @change="changeTree"
                        class="group-item"
                        :style="childrenTreeList.length>0?'width:200px':''"
                        :tree-data="posts_type_arr"
                        placeholder="请选择岗位"
                        labelInValue
                        :show-checked-strategy="SHOW_PARENT"
                        treeNodeFilterProp="title"
                        show-search
                />
                <a-select v-if="childrenTreeList.length>0"  class="group-item"  style="width: 200px;margin-left: 20px"  placeholder="选择详细职务" @change="changeChildrenTree"  label-in-value>
                    <a-select-option v-for="item in childrenTreeList" :value="item.Id">{{item.PositionName }}</a-select-option>
                </a-select>
                
            </div>
            <div class="group">
                <div class="group-label">渠道</div>

                <a-select class="group-item" :style="FSourceArr.indexOf(FSource) ==-1 ?'width:200px':''" placeholder="选择渠道" v-model="FSource">
                    <a-select-option v-for="item in SourceTypeArr" :value="item.value">{{item.name }}</a-select-option>
                </a-select>
                <a-select class="group-item" style="width: 200px;margin-left: 20px" v-if="FSource==0" placeholder="选择详细渠道" v-model="Source">
                    <a-select-option v-for="item in SourceArr" :value="item">{{item }}</a-select-option>
                </a-select>
                <person  v-if="FSource==1" style="width: 200px;margin-left: 20px"
                        v-bind="partObj"
                        @getData="ChangeSearchUser($event, 'RecommendUserArr')"
                ></person>
            </div>
            <div class="group">
                <div class="group-label">意向城市</div>

                <a-select class="group-item" placeholder="意向城市" v-model="WorkPlace"  show-search>
                    <a-select-option v-for="item in cityList" :value="item">
                        {{ item }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="group">
                <div class="group-label">招聘需求</div>

                <a-select class="group-item" v-model="NeedId" >
                    <a-select-option v-for="item in NeedList" :value="item.Id">
                       {{item.DepartmentName}} | {{ item.PositionName }} | {{item.Number}}人 | {{item.DemandUserName}}
                    </a-select-option>
                </a-select>
            </div>

            <div class="group">
                <div class="group-label">简历上传</div>
                <a-button class="group-item upload"  icon="cloud-upload"  >
                    上传文件
                    <input
                            type="file"
                            class="upload-bth"
                            id="ResumeUrl"
                            multiple="multiple"
                            @change="_upload($event, 'ResumeUrl')"
                    />
                </a-button>
                
            </div>
            <div class="group">
                <div class="group-label">&nbsp;</div>
                <div class="group-item" style="color: #000000;font-size: 12px">注意：支持的简历文本格式：.doc,.docx,.xls,.xlsx,.txt,.rtf,.pdf,.html,.htm,
                   .xhtml,.wml,.msg,.wml,.msg,.eml,.mht,.xml,.wps,等文本格式简历全部都支持解析
                   可批量导入多个简历</div>
            </div>
            <div class="group">
                <div class="group-label">&nbsp;</div>
                <div class="group-item" >
                    <div v-if="ResumeUrl.length>0" v-for="(item,index) in ResumeUrl">
                        <div class="file-name">{{item.FileName}}</div>
                        <div class="file-close" @click="delFile(index)">X</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-result" v-if="resultStatus">
            <a-steps :current="current" size="small">
                <a-step title="上传文件" />
                <a-step title="导入数据" />
                <a-step title="完成" />
            </a-steps>
            <a-progress class="progress"   :percent="progress" :showInfo="false" />
            <p class="result-text">导入完成，共{{ResumeUrl.length}}条数据 <span class="result-total">成功导入{{imported.length}}条</span></p>
            <div v-for="item in imported" class="result-file-name">{{item.FileName}}</div>
            <div v-show="not_imported.length" >
                <div class="checkAll">
                    <a-checkbox   :checked="checkAll" @change="onCheckAllChange">
                        勾选失败简历重新上传
                    </a-checkbox>
                </div>
                <div class="check-fail" v-for="(item,index) in not_imported">
                    <a-checkbox   :checked="item.Status==1?true:false" @change="CheckChange($event,index)">
                        {{item.FileName}}
                    </a-checkbox>
                    <p>{{item.reason}}</p>
                </div>
            </div>

            
        </div>
        <div
                :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                取消
            </a-button>
            <a-button type="primary" @click="_action()">
                确定
            </a-button>
        </div>
    </div>
</template>

<script>
    import {TreeSelect} from 'ant-design-vue'
    const SHOW_PARENT = TreeSelect.SHOW_PARENT
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    export default {
        components:{person},
        name: "resumeImport",
        data() {
            return {
                SHOW_PARENT,
                posts_type_arr: '',
                cityList: [],
                Position: '',
                FSource:"0",
                FSourceArr:["2","3"],
                SourceTypeArr: [
                    {name: '社会招聘', value: '0'},
                    {name: '内部推荐', value: "1"},
                    {name: '校园招聘', value: "2"},
                    {name: '其他', value: "3"}
                ],
                WorkPlace:'',
                ResumeUrl:[],
                resultStatus:false,
                checkAll: false,
                current:0,
                imported:[],
                not_imported:[],
                progress:0,
                Source:undefined,
                SourceArr: [
                    '58同城',
                    '猎聘网',
                    '拉勾网',
                    '智联招聘',
                    '中华英才网',
                    'BOSS直聘',
                    '前程无忧(51job)',
                    '其他网络招聘'
                ],
                NeedId:'',
                NeedList:[],
                partObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent22', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                RecommendUserArr:'',
                childrenTreeList:[],
                childrenTreeId:'',
                DutiesId:'',
                DutiesName:'',

            }
        },
        mounted() {
            if (!sessionStorage.getItem('posts_type_arr')) {
                this.$axios.get(8007, {}, res => {
                    if (res.data.code == 1) {
                        this.posts_type_arr = res.data.data
                        window.sessionStorage.setItem(
                            'posts_type_arr',
                            JSON.stringify(res.data.data)
                        )
                    } else {
                        this.$message.error('获取数据失败!')
                    }
                })
            } else {
                this.posts_type_arr = JSON.parse(
                    window.sessionStorage.getItem('posts_type_arr')
                )
            }
            this.$axios.get(1257, {}, res => {
                this.cityList = res.data.data
            })
        },
        created() {
             this.getNeedList();
        },
        methods: {
            getNeedList(){
                let self = this;
                this.$message.loading('加载中...',0);
                this.$axios.get(129424,{},res=>{
                    this.$message.destroy();
                    if(res.data.code==0){
                        self.NeedList = res.data.data;
                    }else{
                        self.NeedList = [];
                        this.$message.error(res.data.msg);
                    }
                })
            },
            onCheckAllChange(e){
                let self = this;
                let status = 0;

                if(e.target.checked){
                    status = 1;
                    this.checkAll = true;
                }else{
                    this.checkAll = false;
                }
                for (let i=0;i<self.not_imported.length;i++){
                    self.not_imported[i].Status   = status;
                    self.not_imported[i].FileName = status+this.not_imported[i].FileName;
                }
                console.log(this.not_imported);
            },
            CheckChange(e,index){
                let status = 0;
                if(e.target.checked){
                    this.not_imported[index].Status = 1;
                    status = 1;
                }else{
                    this.not_imported[index].Status = 0;
                }

                this.not_imported[index].FileName = status+this.not_imported[index].FileName;

            },
            ChangeSearchUser(value, name) {
                if(name=='RecommendUserArr'){
                    this.RecommendUserArr = value;
                    console.log(this.RecommendUserArr)
                }
            },
            _action() {
                let self = this;

                if(!this.Position){
                    this.$message.error('请先选择岗位');
                    return false;
                }
                if(!this.FSource){
                    this.$message.error('请先选择渠道');
                    return false;
                }
                if(!this.WorkPlace){
                    this.$message.error('请先选择意向城市');
                    return false;
                }

                if(this.resultStatus){
                    this.ResumeUrl = [];
                    if(this.not_imported.length>0){
                       for (let i=0;i<self.not_imported.length;i++){
                           let arr = {};
                           if(self.not_imported[i].Status ==1){
                               arr = self.not_imported[i].OriginalData;
                               self.ResumeUrl.push(arr);
                           }
                       }
                       console.log(self.ResumeUrl);
                       if(self.ResumeUrl.length ==0){
                           this.$message.error('请先选择要重新上传的简历');
                           return false;
                       }
                    }else{
                       self.onClose();
                    }
                }
                if(this.ResumeUrl.length==0){
                    this.$message.error('请先上传简历');
                    return false;
                }
                if(this.FSource !=0){
                    this.Source = undefined || '';
                }
                if(this.FSource !=1){
                    this.RecommendUserArr ='';
                }
                this.$message.loading('加载中....', 0);

                this.$axios.post(12910,{
                    Position:self.Position,
                    FSource:self.FSource,
                    WorkPlace:self.WorkPlace,
                    importResune:JSON.stringify(self.ResumeUrl),
                    Source:self.Source || '',
                    NeedId:self.NeedId,
                    DutiesId:self.DutiesId,
                    DutiesName:self.DutiesName,
                    RecommendUserArr:self.RecommendUserArr?JSON.stringify(self.RecommendUserArr):'',
                },res=>{
                    this.$message.destroy();
                    if(res.data.code == 1){
                        self.resultStatus = true;
                        self.not_imported = res.data.data.not_imported;
                        if(res.data.data.not_imported.length>0){
                            for (let i=0;i<self.not_imported.length;i++){
                                self.not_imported[i].Status = 0;
                            }
                            self.current = 1;
                            this.$message.error(res.data.msg);
                        }else{
                            this.$message.success(res.data.msg);
                            self.current = 2;
                            self.not_imported = [];
                        }
                        self.imported     = res.data.data.imported;
                        if(res.data.data.imported.length>0){
                            self.progress = parseInt(self.ResumeUrl.length/res.data.data.imported.length);
                        }else{
                            self.progress = 0;
                        }
                    }else{
                        this.$message.success(res.data.msg);
                    }
                })

            },
            _empty() {
                this.Position  = '';
                this.FSource   = '';
                this.Source    = undefined;
                this.WorkPlace ='';
                this.RecommendUserArr ='';
                this.resultStatus = false;
                this.not_imported =[];
                this.current =0;
                this.NeedId = '';
                this.imported = [];
                this.ResumeUrl = [];
                this.DutiesId ='';
                this.DutiesName = '';
                this.childrenTreeList = [];
                this.childrenTreeId ='';
            },
            onClose() {
                this._empty()
                this.$emit('visible', false)
            },
            changeTree(value) {
                console.log(value)
                this.Position = value.label;
                this.childrenTreeId = value.value;
                this.getChildrenTree();
            },
            changeChildrenTree(value){
                console.log(value)
                this.DutiesId = value.key;
                this.DutiesName = value.label;

            },
            getChildrenTree(){
                let self = this;
                this.$axios.get(129426,{Id:self.childrenTreeId},res=>{
                    if(res.data.code==0){
                        self.childrenTreeList = res.data.data;
                    }else{
                        self.DutiesId   = '';
                        self.DutiesName ='';
                        self.childrenTreeList = [];
                    }

                })
            },
            _upload(e, name) {
                this.$message.loading('正在上传中....', 0)
                let self = this
                let file = []
                let files = document.getElementById(name).files
                this.__uploadFile__(files).then(res => {
                    console.log(res)
                    this.$message.destroy()
                    if (res.length > 0) {
                        let url = res[0]['FileUrl']
                        if (name == 'Photo') {
                            // self.Photo = url
                        } else if (name == 'ResumeUrl') {
                            for (let i=0;i<res.length;i++){
                                self.ResumeUrl.push(res[i])
                            }
                            console.log( self.ResumeUrl);
                        }
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },
            delFile(i){
                if(this.ResumeUrl.length ==1){
                    this.ResumeUrl = [];
                }else{
                    this.ResumeUrl.splice(i,1);
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .content-result{
          .progress{
              margin-top: 32px;
              margin-bottom: 12px;
              height: 18px;
          }
        .result-text{
            color: #121316;
            font-size: 12px;
            .result-total{
                color: #2994FF;
                font-size: 14px;
            }
            margin-bottom: 15px;
        }
        .result-file-name{
            color: #2994FF;
            font-size: 14px;
            margin-bottom: 10px;
        }
        .checkAll{
            height: 32px;
            border-bottom: 1px solid #E9E9E9;
            color: #121316;
            font-size: 14px;
        }
        .check-fail{
            margin-top: 20px;
            p{
                color: #121316;
                font-size: 12px;
                padding-left: 26px;
                margin-top: 10px;
            }
        }
    }
    .group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .group-label {
            width: 80px;
            text-align: left;
        }
        .group-item {
           width: 420px;
            div{
                /*border-bottom: 1px solid  rgba(0, 0, 0, 0.06);*/
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .file-name{
                    color: #000000;
                    font-size: 14px;
                    flex: 1;
                }
                .file-close{
                    color: #000000;
                    font-size: 14px;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

        }
        .upload{
            width: 112px ;
            position: relative;
            .upload-bth{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }
    }
</style>