<template>
    <div class="needsContent">
        <div class="table-search">
            <div class="search">
                <div class="search-list">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan3 search-list-icon"></i>
                        <div class="search-list-text">
                            {{ DepartmentName }}
                            <a-icon v-if="DepartmentId" @click="clearTree('DepartmentId')" class="tree-close"
                                    type="close-circle"/>
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <a-tree-select
                        v-if="treeData"
                        show-search
                        allow-clear
                        class="search-tree"
                        :tree-data="treeData"
                        treeNodeFilterProp="title"
                        @select="_treeSelect"
                        selectedKeys="selectedKeys"
                        labelInValue
                    >
                    </a-tree-select>

                </div>
                <div class="search-list">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan4 search-list-icon"></i>
                        <div class="search-list-text">
                            {{ needTypeName }}
                            <a-icon v-if="needTypeKey" @click="clearTree('needTypeKey')" class="tree-close"
                                    type="close-circle"/>
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>

                    <a-tree-select
                        v-if="needLeftList"
                        show-search
                        allow-clear
                        class="search-tree"
                        :tree-data="needLeftList"
                        treeNodeFilterProp="title"
                        tree-default-expand-all
                        @select="_treeSelect2"

                    >
                    </a-tree-select>
                </div>
                <div class="search-list" @click="() => searchModal(true)">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan5 search-list-icon" :style="!isSearch()?'color: #2994FF;':''"></i>
                        <div class="search-list-text" @click.stop="closeSearch()"
                             :style="!isSearch()?'color: #2994FF;z-index:999;':''">{{ isSearch() ? '筛选' : '取消筛选' }}
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <div class="search-model" v-if="modalVisible">
                        <div class="search-group">
                            <div class="search-label">岗位：</div>
                            <a-select show-search style="width: 100%;" placeholder="请选择岗位" v-model="PositionLabel"
                                      option-filter-prop="children">
                                <a-select-option v-for="item in positionArrCount" :value="item.PositionLabel">
                                    {{ item.PositionName }}({{ item.count }})
                                </a-select-option>

                            </a-select>
                        </div>
                        <div class="search-group">
                            <div class="search-label">程度：</div>
                            <div class="search-group-item">
                                <a-button @click="searchSelect('UrgentLavel','')" :type="UrgentLavel?'':'primary'">全部
                                </a-button>
                                <a-button v-for="item in UrgentLavelArr" @click="searchSelect('UrgentLavel',item.value)"
                                          :type="UrgentLavel == item.value?'primary':''">{{ item.name }}
                                </a-button>
                            </div>
                        </div>
                        <div class="search-group">
                            <div class="search-label">审批状态：</div>
                            <div class="search-group-item">
                                <a-button @click="searchSelect('CheckStatus','')" :type="CheckStatus?'':'primary'">全部
                                </a-button>
                                <a-button v-for="item in CheckStatusArr" @click="searchSelect('CheckStatus',item.value)"
                                          :type="CheckStatus == item.value?'primary':''">{{ item.name }}
                                </a-button>
                            </div>
                        </div>
                        <div class="search-group">
                            <div class="search-label">招聘负责人：</div>
                            <div class="search-group-item">
                                <person
                                    class="item"
                                    v-bind="recruitObj"
                                    @getData="ChangeSearchUser($event, 'InchargeUserName')"
                                ></person>
                            </div>
                        </div>
                        <div class="search-group">
                            <div class="search-label">期望完成时间：</div>
                            <div class="search-group-item">
                                <a-range-picker @change="changeTime"/>
                            </div>
                        </div>
                        <div class="search-group">
                            <div class="search-label">完成状态：</div>
                            <div class="search-group-item">
                                <a-button @click="searchSelect('StatusStr','')" :type="StatusStr?'':'primary'">全部
                                </a-button>
                                <a-button :type="StatusStr == 2?'primary':''" @click="searchSelect('StatusStr',2)">
                                    未完成({{ needCompleteData.notCompletedCount }})
                                </a-button>
                                <a-button :type="StatusStr == 1?'primary':''" @click="searchSelect('StatusStr',1)">
                                    已完成({{ needCompleteData.completedCount }})
                                </a-button>
                                <a-button :type="StatusStr == 3?'primary':''" @click="searchSelect('StatusStr',3)">
                                    已关闭({{ needCompleteData.closedCount }})
                                </a-button>
                            </div>
                        </div>
                        <div class="search-bottom">
                            <a-button @click.stop="toSearch" type="primary" class="search-bottom-bth">确定</a-button>
                            <a-button @click.stop="() => searchModal(false)" class="search-bottom-bth">取消</a-button>

                        </div>
                    </div>
                </div>

            </div>
            <a-button style="width: 120px;" type="primary" @click="add()">添加</a-button>
        </div>
        <div class="table">
            <div class="table-title">
                <div class="title-base " style="text-align: left;padding-left: 20px">基本信息</div>
                <div class="title-item">招聘负责人</div>
                <div class="title-item">审批状态</div>
                <div class="title-item">期望完成时间</div>
                <div class="title-item">候选人数/ 入职数</div>
                <div class="title-item">招聘状态</div>
            </div>
<!--            @mouseover="changeIndex(index)"-->
<!--            @mouseout="_emptyKey" :class="listIndex ==index ?'table-list-index':''" -->
            <div class="table-title table-list" v-for="(item,index) in tableList"
                 >
                <div class="list-base" >
                    <a-radio v-if="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0" class="checkbox" :checked="RadioValue==item.Id?true:false" name="radioGroup" @change="changeRadio" :value="item.Id"></a-radio>
                    <div class="list-base-info" @click="_needsInfo(item.Id)">
                        <div class="base-detail">
                            <span class="UrgentLavel">{{ item.UrgentLavel }}</span>
                            <span
                                class="position-number">{{ item.PositionName }} | {{
                                    item.Number
                                }} | {{ item.DemandUserName }}</span>
                        </div>
                        <div class="DepartmentName">{{ item.DepartmentName }}</div>
                    </div>
                </div>
                <div class="title-item list-item" :style="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0?'width:120px':''">{{ item.InchargeUserName }}</div>
                <div class="title-item list-item" :style="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0?'width:120px':''">{{ item.CheckStatus }}</div>
                <div class="title-item list-item" :style="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0?'width:120px':''">{{ item.ExpectedCompletionDate }}</div>
                <div class="title-item list-item " :style="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0?'width:120px':''" @click="_ResumeLis(item.Id)">
                    <div class="list-CandidateNum">
                        {{ item.CandidateNum }}/{{ item.EntryNum }}
                    </div>
                </div>
                <div class="title-item list-item">
                    <a-popover trigger="click">
                        <div slot="content">
                            <a-popconfirm
                                v-for="Statusitem in StatusArr"
                                title="您确定要更改招聘状态吗?"
                                @confirm="handleMenuClick(item.Id,item.Status,Statusitem.value)"
                                okText="确定"
                                cancelText="取消"
                            >
                                <div class="list-Status"
                                     :class="Statusitem.value==1?'list-Status-one':Statusitem.value==2?'list-Status-two':Statusitem.value==3?'list-Status-close':''">
                                    {{ Statusitem.text }}
                                </div>
                            </a-popconfirm>

                        </div>
                        <div class="list-Status"
                             :class="item.Status==1?'list-Status-one':item.Status==2?'list-Status-two':item.Status==3?'list-Status-close':''">
                            {{ StatusObject[item.Status] }}
                        </div>
                    </a-popover>
                </div>

            </div>
        </div>
        <a-pagination
            v-if="total"
            style="margin-top: 35px;text-align: right;margin-right: 20px"
            v-model="Page"
            @change="ChangePage"
            :pageSize="Limit"
            :total="total"
            :page-size-options="pageSizeOptions"
            show-less-items
            show-size-changer
            @showSizeChange="onShowSizeChange"
        />

        <!--招聘需求操作-->
        <a-drawer
            title="招聘需求"
            width="1000"
            :closable="false"
            :visible="visible"
            @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <needsContentAction
                    v-if="visible"
                    :Id="Id"
                    @visible="onClose"
                ></needsContentAction>
            </div>
        </a-drawer>
        <!--候选人总数列表-->
        <a-drawer
            title="候选人列表"
            width="1000"
            :closable="false"
            :visible="NeedResumeListVisible"
            @close="_ResumeLisClose"
        >
            <div style="margin-bottom: 60px">
              <resume :ProgressNeedId="NeedResumeListId" resumeProgress="resumeScreen" :NeedContentId="NeedResumeListId" ProgressStage="waitScreen"></resume>
<!--                <NeedResumeList-->
<!--                    v-if="NeedResumeListVisible"-->
<!--                    :Id="NeedResumeListId"-->
<!--                    @_ResumeLisClose="_ResumeLisClose"-->
<!--                ></NeedResumeList>-->
            </div>
        </a-drawer>
        <!--招聘需求详情-->
        <a-drawer

            width="1000"
            :closable="false"
            :visible="Infovisible"
            @close="InfoClose"
        >
            <div style="margin-bottom: 60px">
                <needsContentInfo
                    v-if="Infovisible"
                    :Id="Id"
                    @visible="InfoClose"
                ></needsContentInfo>
            </div>
        </a-drawer>
<!--        简历关联-->
        <div
            :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
            v-if="typeof(NeedResumeId) !='undefined' && NeedResumeId.length>0"
        >
            <a-button style="marginRight: 8px" @click="resumeClose">
                取消
            </a-button>
            <a-button type="primary" @click="resumeAction()">
                确认
            </a-button>
        </div>
    </div>
</template>

<script>
import needsContentAction from 'pages/Hr/needsContentAction';
import needsContentInfo from 'pages/Hr/needsContentInfo';
import NeedResumeList from 'pages/Hr/NeedResumeList';
import resume from 'pages/Hr/resume';
import person from '../../components/SelectPersonnel/SelectPersonnel'

export default {
    components: {needsContentAction, NeedResumeList, needsContentInfo, person,resume},
    name: 'needsContent',
    data() {
        return {
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            total: '',
            Page: 0,
            Limit: 10,
            tableList: [],

            positionArrCount: [],
            needCompleteData: {"completedCount": 0, "notCompletedCount": 0, 'closedCount': 0},
            treeData: '',
            StatusStr: 2,
            needLeftList: [],
            positionArr: [],
            StatusObject: {"0": "未开始", "1": "已完成", "2": "招聘中", "3": "已关闭"},
            StatusArr: [{'value': 0, 'text': '未开始'}, {'value': 1, 'text': '已完成'}, {
                'value': 2,
                'text': '招聘中'
            }, {'value': 3, 'text': '已关闭'}],
            listIndex: -1,
            visible: false,
            Id: '',
            checkStatus: false,
            checkedList: [],
            DepartmentName: '组织架构',
            DepartmentId: '',
            selectedKeys: [],
            selectedKeys2: [],
            needTypeKey: 'all',
            needTypeName: '与我相关',
            modalVisible: false,
            UrgentLavelArr: [
                {name: '一般', value: '一般'},
                {name: '很急', value: '很急'},
                {name: '紧急', value: '紧急'},
                {name: '非常紧急', value: '非常紧急'}
            ],
            UrgentLavel: '',
            CheckStatusArr: [{name: '待处理', value: '0'},
                {name: '通过', value: '1'},
                {name: '不通过', value: '2'},
            ],
            CheckStatus: '',
            PositionLabel: undefined,
            StartDate: '',
            EndDate: '',
            //招聘负责人
            recruitObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            InchargeUserName: '',
            InchargeUserNameTitle:'',
            overviewTag: this.$route.query.overviewTag ? this.$route.query.overviewTag : '',
            NeedResumeListVisible: false,
            NeedResumeListId: '',
            Infovisible: false,
            RadioValue:'',
            OrgId:'',
        }
    },
    props: {
        NeedResumeId: {
            type: Array
        }
    },
    created() {
        this.positionList()
        this.loadData();
        this.getneedLeft();
        // this.needCompleteInfo();
        this.getHrOrgTree();

    },
    methods: {

        //公司组织架构
        getHrOrgTree() {
            let self = this;
            this.$axios.get(1255, {
                TreeStatus: self.StatusStr,
            }, res => {
                this.treeData = res.data
            })
        },
        needCompleteInfo() {
            let self = this;
            this.$axios.get(12933, {OrgId:self.OrgId}, res => {
                if (res.data.code == 0) {

                    self.needCompleteData.completedCount = res.data.data.completedCount;
                    self.needCompleteData.notCompletedCount = res.data.data.notCompletedCount;
                    self.needCompleteData.closedCount = res.data.data.closedCount;

                }
            })
        },

        getneedLeft() {

            let self = this;
            this.$axios.get(12932, {
                TreeStatus: self.StatusStr,
            }, res => {
                if (res.data.code == 0) {
                    self.needLeftList = res.data.data;
                } else {
                    self.needLeftList = [];
                    this.$message.error(res.data.msg);
                }
            })
        },
        positionList() {
            let self = this
            this.$axios.get(1252, {}, res => {
                if (res.data.code == 0) {
                    // self.personData = res.data.data
                    // for (let j = 0; j < res.data.data.length; j++) {
                    //     let arr = {}
                    //     arr.name = res.data.data[j].PositionName
                    //     arr.value = res.data.data[j].PositionLabel
                    //     self.personData[res.data.data[j].PositionLabel] = res.data.data[j].PositionName;
                    //     self.positionArr.push(arr)
                    // }
                }
            })
        },
        loadData() {
            let self = this
            this.$message.loading('加载中...', 0);

            let data = {
                pagesize: self.Limit,
                p: self.Page,
                needTypeKey: self.needTypeKey,
                DepartmentId: self.DepartmentId,
                PositionLabel: self.PositionLabel || '',
                CheckStatus: self.CheckStatus,
                UrgentLavel: self.UrgentLavel,
                StartDate: self.StartDate,
                EndDate: self.EndDate,
                InchargeUserName: self.InchargeUserName,
                TreeStatus: self.StatusStr,
                overviewTag: self.overviewTag,
                // Position: self.Position,
            };
            this.$axios.get(
                1251,
                data,
                res => {
                    this.$message.destroy()
                    self.needCompleteInfo();
                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.total;
                        self.positionArrCount = res.data.positionCount;
                    } else {
                        self.tableList = []
                        self.positionArrCount = []
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        onShowSizeChange(current, pageSize) {
            this.Page = 0;
            this.Limit = pageSize;
            this.loadData();
        },
        ChangePage(val) {
            this.Page = val
            this.loadData()
        },
        //modal搜索
        isSearch() {
            if ((this.PositionLabel && typeof (this.PositionLabel) != 'undefined') || this.UrgentLavel || this.CheckStatus || this.InchargeUserName || this.StartDate || this.EndDate || this.StatusStr != 2) {
                return false;
            } else {
                return true;
            }

        },
        closeSearch() {
            if (!this.isSearch()) {
                this.PositionLabel = undefined;
                this.UrgentLavel = '';
                this.CheckStatus = '';
                this.InchargeUserName = '';
                this.InchargeUserNameTitle ='';
                this.recruitObj = {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent112', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                };
                this.StartDate = '';
                this.EndDate = '';
                this.StatusStr = 2;
                this.Page = 0;
                this.modalVisible = false;
                this.loadData()
            } else {
                this.modalVisible = true;
            }
        },
        searchModal(val) {
            if(val && this.InchargeUserName){
                let arr = {};
                arr.title = this.InchargeUserNameTitle;
                arr.id = this.InchargeUserName;
                this.recruitObj.selectArr = [];
                this.recruitObj.selectArr.push(arr);
            }
            this.modalVisible = val;

        },
        toSearch() {
            this.modalVisible = false;
            this.Page = 0;
            this.loadData()
        },
        ChangeSearchUser(val, name) {
            if (val.length > 0) {
                if (name == 'InchargeUserName') {
                    this.InchargeUserName = val[0].id;
                    this.InchargeUserNameTitle = val[0].title;
                }

            }
        },
        changeTime(date, dateString) {
            this.StartDate = dateString[0];
            this.EndDate = dateString[1];
        },
        searchSelect(name, val) {
            if (name == 'UrgentLavel') {
                this.UrgentLavel = val;
            } else if (name == 'CheckStatus') {
                this.CheckStatus = val;
            } else if (name == 'StatusStr') {
                this.StatusStr = val;
            }
        },
        //组织结构树
        _treeSelect(value, e) {
            this.OrgId = e.value;
            this.needTypeKey = '';
            this.needTypeName = '全部';
            this.DepartmentId = e.value;
            this.DepartmentName = e.title;

            this.Page = 0;
            this.loadData()
        },
        clearTree(name) {
            if (name == 'needTypeKey') {
                this.needTypeKey = '';
                this.needTypeName = '全部';
            } else if (name == 'DepartmentId') {
                this.DepartmentId = '';
                this.DepartmentName = '组织架构';
            }
            this.Page = 0;
            this.loadData()
        },
        //与我相关
        _treeSelect2(value, e) {
            this.needTypeKey = e.value;
            this.needTypeName = e.title;
            this.DepartmentId = '';
            this.DepartmentName = '组织架构';
            this.Page = 0;
            this.loadData()
        },
        changeIndex(i) {
            this.listIndex = i;
        },
        _emptyKey() {
            this.listIndex = -1;
        },
        //更改招聘状态
        handleMenuClick(Id, status, value) {
            if (status == 1) {
                this.$message.error('当前招聘状态是已完成，不能更改');
                return false;
            } else {
                let ids = [];
                ids.push(Id);
                this.changeStatus(ids, value);
            }


        },
        changeStatus(Id, value) {
            this.$message.loading('加载中...', 0);
            let self = this;
            this.$axios.get(12912, {
                Status: value,
                Id: Id.join(',')
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.loadData();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //添加
        add() {
            this.$nextTick(() => {
                // 在 DOM 中添加 my-component 组件
                this.visible = true
            })
        },
        onClose() {
            this.Id = ''
            this.visible = false
            this.loadData()
        },
        //全选
        searckIndex(val) {
            if (this.checkedList.length > 0) {
                let active = 0;
                for (let i = 0; i < this.checkedList.length; i++) {
                    if (val == this.checkedList[i]) {
                        active = 1;

                    }
                }
                if (active == 1) {
                    return true;
                }
            } else {
                return false;
            }
        },
        onCheckAllChange(e) {
            this.checkStatus = e.target.checked;
            this.checkedList = [];
            if (this.checkStatus) {
                for (let i = 0; i < this.tableList.length; i++) {
                    this.checkedList.push(i);
                }
            }
        },
        checkChange(e, val) {
            let active = 0;
            let num = 0;
            for (let i = 0; i < this.checkedList.length; i++) {
                if (val == this.checkedList[i]) {
                    active = 1;
                    num = i;
                }
            }
            if (e.target.checked) {
                //选中
                if (active != 1) {
                    this.checkedList.push(val);
                    if ((this.checkedList.length + 1) == this.Limit) {
                        this.checkStatus = true;
                    }
                }
            } else {
                //取消
                if (active == 1) {
                    if (this.checkedList.length == 1) {
                        this.checkedList = [];
                        this.checkStatus = false;
                    } else {
                        this.checkedList.splice(num, 1);
                    }

                }
            }

        },
        //候选人总数列表
        _ResumeLisClose() {
            this.NeedResumeListId = '';
            this.NeedResumeListVisible = false;
        },
        _ResumeLis(id) {
            this.NeedResumeListId = id;
            this.NeedResumeListVisible = true;
        },
        _needsInfo(id) {
            this.Id = id;
            this.Infovisible = true;
        },
        InfoClose() {
            this.Id = ''
            this.Infovisible = false
            this.loadData()
        },
        //简历关联
        changeRadio(val){
            this.RadioValue = val.target.value;
        },
        resumeAction(){
            if(!this.RadioValue){
                this.$message.error('请先选择要关联的简历');
                return false;
            }
            let self = this
            this.$message.loading('加载中', 0)
            this.$axios.post(
                1266,
                {
                    resumeId: JSON.stringify(self.NeedResumeId),
                    needId: self.RadioValue
                },
                res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        self.$emit('visible', false)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        resumeClose(){
            this.$emit('visible', false)
        },

    }
}
</script>

<style scoped lang="less">
.needsContent {
    background: #ffffff;
    width: 100%;
    padding: 20px 0px 20px 20px;
    min-height: 500px;

    .table-search {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right: 20px;

        .search {
            flex: 1;
            display: flex;
            align-items: center;

            .check-all {
                width: 34px;
                height: 36px;
                border: 1px solid #E8E9EE;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .search-list {
                position: relative;
                min-width: 138px;
                margin-right: 8px;
                margin-left: 8px;
                border: 1px solid #E8E9EE;
                height: 36px;
                border-radius: 4px;

                .search-model {
                    position: absolute;
                    left: 0;
                    top: 46px;
                    width: 500px;
                    background: #FFFFFF;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 5px;
                    z-index: 1000;
                    padding: 15px 18px 0px 18px;

                    .search-group {
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;

                        .search-label {
                            color: #A8A9AA;
                            font-size: 12px;
                            margin-right: 6px;
                            min-width: 82px;
                        }

                        .search-group-item {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }

                    .search-bottom {
                        display: flex;
                        height: 53px;
                        background: #F9F9F9;
                        border-radius: 0px 0px 5px 5px;
                        align-items: center;
                        width: 100%;
                        padding-right: 20px;
                        text-align: right;
                        flex-direction: row-reverse;

                        .search-bottom-bth {
                            margin-left: 8px;
                        }
                    }
                }

                .search-list-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    padding: 0px 8px;

                    .search-list-icon {
                        color: #999999;
                    }

                    .search-list-text {
                        cursor: pointer;
                        margin: 0px 5px;
                        display: flex;
                        align-items: center;

                        .tree-close {
                            color: #999999;
                            margin-left: 5px;
                            z-index: 1000;
                            font-size: 12px;
                        }
                    }

                    .search-list-down {
                        color: #999999;
                    }
                }

                .search-tree {
                    width: 100%;
                    opacity: 0;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 100;
                }
            }

        }
    }

    .table {
        border: 1px solid #EBEEF5;

        .table-title {
            background: #F7F8F9;
            //height: 38px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0px 12px 0px;

            .title-base {
                flex: 1;
                text-align: center;
                width: 360px;
            }

            .title-item {
                width: 15%;
                margin: auto;
                text-align: left;
                white-space: nowrap;

            }

            .title-item:last-child {
                margin-right: 20px;
            }
        }

        .table-list-index {
            background: #F7F8F9 !important;
            cursor: pointer;
        }

        .table-list {
            background: #ffffff;

            .list-base {
                display: flex;
                align-items: center;
                flex: 1;
                margin-left: 20px;
                .checkbox {

                    margin-right: 30px;
                }

                .list-base-info {
                    .base-detail {
                        white-space: nowrap;

                        .UrgentLavel {
                            background: #FFF1F0;
                            border: 1px solid #FFA39E;
                            border-radius: 2px;
                            padding: 5px;
                            margin-right: 11px;
                            color: #FF4D4F;
                        }

                        .position-number {
                            color: #121316;
                            font-size: 16px;
                        }
                    }

                    .DepartmentName {
                        color: #575D6A;
                        font-size: 14px;
                        margin-top: 4px;

                    }
                }
            }

            .list-item {
                .list-CandidateNum {
                    background: #F4F4F5;
                    border-radius: 5px;
                    padding: 5px 20px;
                    color: #2994FF;
                    cursor: pointer;
                    width: 100px;
                    //margin: auto;
                    text-align: center;
                }


            }


        }
        .table-list:hover{
            background: #F7F8F9 !important;
            cursor: pointer;
        }
    }
}

.list-Status {
    cursor: pointer;
    font-size: 12px;
    padding: 8px 20px;
    color: #2994FF;
    background: #F4F4F5;
    width: 100px;
    text-align: center;
    //margin: auto;
    margin-bottom: 5px;
    border-radius: 6px;
}

.list-Status-one {
    color: #A4A4A4;
}

.list-Status-two {
    color: #FF9C08;
    background: #FFF4DE;
}

.list-Status-close {
    background: #DBDBDB;
    color: #6D6D6D;
}
</style>
