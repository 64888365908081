var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NeedResumeList"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tabs"},_vm._l((_vm.statisticsData),function(item){return (_vm.statisticsData.length > 0)?_c('div',{staticClass:"tab-item",class:_vm.tabKey == item.value ? 'tab-item-active' : '',on:{"click":function($event){return _vm._tabs(item.value)}}},[_c('div',{staticClass:"tab-item-count"},[_vm._v(_vm._s(item.count))]),_c('div',{staticClass:"tab-item-text"},[_vm._v(_vm._s(item.Stage))])]):_vm._e()}),0)]),_c('div',{staticStyle:{"z-index":"100000"},style:({
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'left',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px'
    })},[_c('a-button',{staticStyle:{"marginRight":"8px"},on:{"click":_vm.onClose}},[_vm._v(" 关闭 ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }