<template>
    <div class="resumeAction">
        <div class="content" v-if="Status">
            <div class="group">
                <a-input
                        placeholder="请输入姓名"
                        v-model="Name"
                        class="group-item"
                        style="width: 140px"
                />
                <a-tree-select
                        v-if="posts_type_arr && Position"
                        @change="changeTree"
                        class="group-item"
                        :tree-data="posts_type_arr"
                        placeholder="请选择岗位2"
                        :defaultValue="{ value: PositionId,label: Position}"

                        labelInValue
                        treeNodeFilterProp="title"
                        show-search
                />
                <!--:defaultValue="{ value: PositionLabel,label:PositionName }"-->
                <a-tree-select
                        v-if="posts_type_arr && !Position"

                        @change="changeTree"
                        class="group-item"
                        :tree-data="posts_type_arr"
                        placeholder="请选择岗位"
                        labelInValue
                        :show-checked-strategy="SHOW_PARENT"
                        treeNodeFilterProp="title"
                        show-search
                />
                <a-select v-if="childrenTreeList.length>0"  class="group-item"    placeholder="选择详细职务" @change="changeChildrenTree" :default-value="{ key: DutiesId }"  label-in-value>
                    <a-select-option v-for="item in childrenTreeList" :value="item.Id">{{item.PositionName }}</a-select-option>
                </a-select>

                <a-select class="group-item" placeholder="选择渠道" v-model="FSource">
                    <a-select-option v-for="item in SourceTypeArr" :value="item.value">{{item.name }}</a-select-option>
                </a-select>

                <a-select class="group-item" v-if="FSource ==0" placeholder="选择详细渠道" v-model="Source">
                    <a-select-option v-for="item in SourceArr" :value="item">{{item }}</a-select-option>
                </a-select >

                <person style="width: 156px" v-if="FSource==1"  class="group-item"
                         v-bind="RecommendUserObj"
                         @getData="ChangeSearchUser($event, 'RecommendUserArr')"
                ></person>

                <a-date-picker
                        class="group-item"
                        v-if="Birthday"
                        :default-value="moment(Birthday, 'YYYY-MM-DD')"
                        @change="onChange"
                        placeholder="请选择生日"
                />
                <a-date-picker
                        class="group-item"
                        v-if="!Birthday"
                        @change="onChange"
                        placeholder="请选择生日"
                />
            </div>
            <div class="group">
                <a-select
                        class="group-item"
                        style="width: 84px;margin-right: 8px"
                        v-model="Sex"
                        placeholder="性别"
                >
                    <a-select-option value="0">
                        男
                    </a-select-option>
                    <a-select-option value="1">
                        女
                    </a-select-option>
                    <a-select-option value="2">
                        未知
                    </a-select-option>
                </a-select>
                <a-input-number
                        class="group-item"
                        style="width: 56px;margin-right: 8px"
                        v-model="Age"
                        :min="1"
                        placeholder="年龄"
                />

                <a-input class="group-item" v-model="Mobile" placeholder="手机号码"/>
                <a-input placeholder="邮箱地址" v-model="Email" class="group-item"/>
                <a-select
                        class="group-item"
                        placeholder="工作经验"
                        v-model="WorkExperience"
                >
                    <a-select-option v-for="item in ageArr" :value="item">
                        {{ item }}
                    </a-select-option>
                </a-select>
                <a-select
                        class="group-item"
                        placeholder="最高学历"
                        v-model="Qualification"
                >
                    <a-select-option v-for="item in educationArr" :value="item.value">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
                <a-select class="group-item" placeholder="意向城市" v-model="WorkPlace">
                    <a-select-option v-for="item in cityList" :value="item">
                        {{ item }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="group">
                <a-checkbox @change="ResumeTypeChange">直接上传至人才库</a-checkbox>
            </div>
            <a-tabs
                    style="margin: 0;margin-top: 8px;"
                    default-active-key="1"
                    @change="callback"
            >
                <a-tab-pane key="1" tab="标准简历" style="display: flex;">
                    <div class="tab-left">
                        <div class="content-title">
                            <div class="content-title-text">头像</div>
                        </div>
                        <div class="avatar" v-viewer>
                            <a-avatar
                                    style="cursor: pointer"
                                    :size="60"
                                    icon="user"
                                    :src="Photo"
                            />
                            <div style="margin-left: 34px;">
                                <a-button class="upload">
                                    <a-icon type="upload"/>
                                    上传照片
                                    <input
                                            accept=".jpg,.png,.gif,.jpeg"
                                            type="file"
                                            class="upload-bth"
                                            id="Photo"
                                            @change="_upload($event, 'Photo')"
                                    />
                                </a-button>
                                <div class="upload-tips">
                                    支持JPG JPRG PNG等图片格式不得超过2M
                                </div>
                            </div>
                        </div>
                        <div class="content-title">
                            <div class="content-title-text">个人信息</div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">婚姻情况</div>
                                <a-select class="tab-item" v-model="IsMarry">
                                    <a-select-option value="1">已婚</a-select-option>
                                    <a-select-option value="2">未婚</a-select-option>
                                    <a-select-option value="3">未知</a-select-option>
                                </a-select>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">身份证号</div>
                                <a-input class="tab-item" v-model="CardId"/>
                            </div>
                            <div class="tab-group" >
                                <div class="tab-label">详细地址</div>
                                <a-input
                                        style="width: 400px"
                                        class="tab-item"
                                        v-model="DetailedAddress"
                                />
                            </div>
                        </div>

                        <div class="content-title">
                            <div class="content-title-text">求职意向</div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">当前薪资</div>
                                <a-input class="tab-item" v-model="SalaryNow"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">期望薪资</div>
                                <a-input class="tab-item" v-model="SalaryExpect"/>
                            </div>
                        </div>
                        <div class="content-title">
                            <div class="content-title-text">工作经历</div>
                            <div class="content-add" @click="addInput('workExperienceMore')">
                                +添加
                            </div>
                        </div>
                        <div
                                v-for="(item, index) in workExperienceMore"
                                class="tab-content"
                                :style="index != 0 ? 'padding:0' : ''"
                        >
                            <div
                                    class="content-title"
                                    v-if="index != 0"
                                    style="margin-bottom: 16px"
                            >
                                <div class="" style="flex: 1"></div>
                                <div
                                        class="content-add"
                                        @click="Remove(index, 'workExperienceMore')"
                                >
                                    -删除
                                </div>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">起止时间</div>
                                <a-range-picker
                                        class="tab-item"
                                        v-if="item.StartDate"
                                        :default-value="[
                    moment(item.StartDate, 'YYYY-MM-DD'),
                    moment(item.EndDate, 'YYYY-MM-DD')
                  ]"
                                        format="YYYY-MM-DD"
                                        :placeholder="['开始时间', '结束时间']"
                                        allowClear
                                        @change="ChangeTime($event, 'workExperienceMore', index)"
                                />

                                <a-range-picker
                                        class="tab-item"
                                        v-if="!item.StartDate"
                                        format="YYYY-MM-DD"
                                        :placeholder="['开始时间', '结束时间']"
                                        @change="ChangeTime($event, 'workExperienceMore', index)"
                                />
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">公司名称</div>
                                <a-input class="tab-item" v-model="item.CompayName"/>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">职位</div>
                                <a-input class="tab-item" v-model="item.Position"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">薪酬</div>
                                <a-input class="tab-item" v-model="item.Pay"/>
                            </div>
                            <div class="tab-group" style="width: 400px;">
                                <div class="tab-label tab-group-active">工作职责</div>
                                <a-textarea class="tab-item"  style="width: 576px;" v-model="item.OperatingDuty"></a-textarea>
                            </div>
                        </div>
                        <div class="content-title">
                            <div class="content-title-text">教育背景</div>
                            <div class="content-add" @click="addInput('educational')">
                                +添加
                            </div>
                        </div>
                        <div
                                class="tab-content"
                                v-for="(item, index) in educational"
                                :style="index != 0 ? 'padding:0' : ''"
                        >
                            <div
                                    class="content-title"
                                    v-if="index != 0"
                                    style="margin-bottom: 16px"
                            >
                                <div class="" style="flex: 1"></div>
                                <div class="content-add" @click="Remove(index, 'educational')">
                                    -删除
                                </div>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">就读时间</div>
                                <a-range-picker
                                        class="tab-item"
                                        v-if="item.StartDate"
                                        :default-value="[
                    moment(item.StartDate, 'YYYY-MM-DD'),
                    moment(item.EndDate, 'YYYY-MM-DD')
                  ]"
                                        format="YYYY-MM-DD"
                                        :placeholder="['开始时间', '结束时间']"
                                        allowClear
                                        @change="ChangeTime($event, 'educational', index)"
                                />
                                <a-range-picker
                                        class="tab-item"
                                        v-if="!item.StartDate"
                                        format="YYYY-MM-DD"
                                        :placeholder="['开始时间', '结束时间']"
                                        @change="ChangeTime($event, 'educational', index)"
                                />
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">学历</div>
                                <a-select class="tab-item" v-model="item.Education">
                                    <a-select-option
                                            v-for="items in educationArr"
                                            :value="items.name"
                                    >
                                        {{ items.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">学校名称/培训机构名称</div>
                                <a-input class="tab-item" v-model="item.SchoolName"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">专业名称/课程名称</div>
                                <a-input class="tab-item" v-model="item.MajorName"/>
                            </div>
                            <div class="tab-group tab-group-active" style="width: 400px;">
                                <div class="tab-label">学历/学位/证书</div>
                                <a-textarea class="tab-item" style="width: 576px;" v-model="item.AcademicDegree"></a-textarea>
                            </div>
                        </div>
                        <div class="content-title">
                            <div class="content-title-text">家庭情况</div>
                            <div class="content-add" @click="addInput('Family')">+添加</div>
                        </div>
                        <div
                                class="tab-content"
                                v-for="(item, index) in Family"
                                :style="index != 0 ? 'padding:0' : ''"
                        >
                            <div
                                    class="content-title"
                                    v-if="index != 0"
                                    style="margin-bottom: 16px"
                            >
                                <div class="" style="flex: 1"></div>
                                <div class="content-add" @click="Remove(index, 'Family')">
                                    -删除
                                </div>
                            </div>

                            <div class="tab-group tab-group-active">
                                <div class="tab-label">姓名</div>
                                <a-input class="tab-item" v-model="item.Name"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">年龄</div>
                                <a-input-number class="tab-item" v-model="item.Age"/>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">关系</div>
                                <a-input class="tab-item" v-model="item.Relationship"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">电话</div>
                                <a-input class="tab-item" v-model="item.Phone"/>
                            </div>
                            <div class="tab-group tab-group-active">
                                <div class="tab-label">工作内容</div>
                                <a-input class="tab-item" v-model="item.JobContent"/>
                            </div>
                            <div class="tab-group">
                                <div class="tab-label">工作单位</div>
                                <a-input class="tab-item" v-model="item.JobUnit"/>
                            </div>
                            <div class="tab-group tab-group-active" style="width: 400px;">
                                <div class="tab-label">居住地</div>
                                <a-textarea class="tab-item" style="width: 576px;" v-model="item.Residence"></a-textarea>
                            </div>
                            <div class="tab-group tab-group-active" style="width: 400px;">
                                <div class="tab-label">项目经验</div>
                                <a-textarea class="tab-item" style="width: 576px;" v-model="ProjectExperience"></a-textarea>
                            </div>
                            <div class="tab-group tab-group-active" style="width: 400px;">
                                <div class="tab-label">自我评价</div>
                                <a-textarea class="tab-item" style="width: 576px;" v-model="SelfEvaluation"></a-textarea>
                            </div>

                        </div>
                    </div>
                    <div class="tab-right">
                        <a-button class="upload resume" type="primary">
                            上传简历
                            <a-icon type="upload"/>
                            <input
                                    type="file"
                                    class="upload-bth"
                                    id="ResumeUrl"
                                    @change="_upload($event, 'ResumeUrl')"
                            />
                        </a-button>
                        <a-tag
                                style="text-align: center;margin-top: 20px"
                                v-if="FileName"
                                :key="FileName"
                                :closable="closable"
                                @close="() => handleClose()"
                                class="tags-active tags"
                        >
                            {{ FileName }}
                        </a-tag>
                    </div>
                </a-tab-pane>
                <!--<a-tab-pane key="2" tab="原始简历" force-render>-->
                <!--<div id="container" style="min-height: 800px"></div>-->
                <!--</a-tab-pane>-->
            </a-tabs>
        </div>
        <div
                :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
                style=" z-index: 100000"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                取消
            </a-button>
            <a-button type="primary" @click="_action()">
                确定
            </a-button>
        </div>
    </div>
</template>

<script>
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import {TreeSelect} from 'ant-design-vue'
    import moment from 'moment'
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)
    const SHOW_PARENT = TreeSelect.SHOW_PARENT
    export default {
        name: 'resumeAction',
        components: {person},
        data() {
            return {
                SHOW_PARENT,
                posts_type_arr: '',
                PositionName: '',
                ageArr: [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20
                ],
                PositionLabel: '',
                educationArr: [
                    {value: '0', name: '未知'},
                    {value: '1', name: '初中及以下'},
                    {value: '3', name: '中专/中技术'},
                    {value: '2', name: '高级中学'},
                    {value: '4', name: '大学专科'},
                    {value: '6', name: '大学本科'},
                    {value: '8', name: '硕士研究生'},
                    {value: '9', name: '博士'},
                    {value: '10', name: '博士后'},
                    {value: '11', name: '不限'},
                ],
                //招聘负责人
                ObjArr: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                cityList: [],
                Photo: '',
                workExperienceMore: [
                    {
                        StartDate: '',
                        EndDate: '',
                        CompayName: '',
                        Position: '',
                        Pay: '',
                        OperatingDuty: ''
                    }
                ],
                educational: [
                    {
                        StartDate: '',
                        EndDate: '',
                        Education: '',
                        SchoolName: '',
                        MajorName: '',
                        AcademicDegree: ''
                    }
                ],
                Family: [
                    {
                        Name: '',
                        Age: '',
                        Relationship: '',
                        Phone: '',
                        JobContent: '',
                        JobUnit: '',
                        Residence: ''
                    }
                ],
                ResumeUrl: '',
                Sex: undefined,
                Age: '',
                Mobile: '',
                Email: '',
                WorkExperience: undefined,
                Qualification: undefined,
                WorkPlace: undefined,
                Position: '',
                Name: '',
                ResumeType: 0,
                Source: undefined,
                SourceType: '',
                FSource: undefined,
                RecommendUserArr:'',
                RecommendUserObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent22', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                SalaryNow: '',
                SalaryExpect: '',
                SelfEvaluation:'',
                ProjectExperience:'',
                SourceTypeArr: [
                    {name: '社会招聘', value: '0'},
                    {name: '内部推荐', value: 1},
                    {name: '校园招聘', value: 2},
                    {name: '其他', value: 3}
                ],
                SourceArr: [
                    '58同城',
                    '猎聘网',
                    '拉勾网',
                    '智联招聘',
                    '中华英才网',
                    'BOSS直聘',
                    '前程无忧(51job)',
                    '其他网络招聘'
                ],
                Birthday: '',
                IsMarry: '2',
                DetailedAddress: '',
                CardId: '',
                Status: true,
                FileName: '',
                closable: true,
                PositionId:'',
                childrenTreeList:[],
                childrenTreeId:'',
                DutiesId:'',
                DutiesName:'',
            }
        },
        props: {
            Id: {
                type: String
            }
        },
        mounted() {
            if (!sessionStorage.getItem('posts_type_arr')) {
                this.$axios.get(8007, {}, res => {
                    if (res.data.code == 1) {
                        this.posts_type_arr = res.data.data
                        window.sessionStorage.setItem(
                            'posts_type_arr',
                            JSON.stringify(res.data.data)
                        )
                    } else {
                        this.$message.error('获取数据失败!')
                    }
                })
            } else {
                this.posts_type_arr = JSON.parse(
                    window.sessionStorage.getItem('posts_type_arr')
                )
            }
            this.$axios.get(1257, {}, res => {
                this.cityList = res.data.data
            })
        },
        created() {
            if (this.Id) {
                this.Status = false
                this.loadData()
            }
        },
        methods: {
            moment,
            loadData() {
                let self = this
                this.$message.loading('加载中....', 0)
                this.$axios.get(
                    1261,
                    {
                        Id: self.Id
                    },
                    res => {
                        this.$message.destroy()

                        if (res.data.code == 0) {
                            let infoData = res.data.data
                            console.log(infoData)
                            self.Name = infoData.Name
                            self.ResumeUrl = infoData.ResumeUrl
                            if(infoData.FSource || infoData.FSource===0){
                                self.FSource = parseInt(infoData.FSource)  ;

                            }else{
                                self.FSource = undefined;
                            }
                           
                            console.log(typeof self.FSource)
                            self.Sex = infoData.Sex + ''
                            self.Age = infoData.Age
                            self.Mobile = infoData.Mobile
                            self.Email = infoData.Email
                            self.WorkExperience = infoData.WorkExperience?infoData.WorkExperience:undefined;
                            self.Qualification = infoData.Qualification + ''
                            self.WorkPlace = infoData.WorkPlace?infoData.WorkPlace:undefined;
                            self.RecommendUserObj.selectArr = [];
                            if(infoData.RecommendUserId){
                                let userObject = {};
                                userObject.id = infoData.RecommendUserId;
                                userObject.title =infoData.RecommendUserName;
                                self.RecommendUserObj.selectArr.push(userObject);
                                let userArr = [];
                                self.RecommendUserArr = userArr.push(userObject);
                            }


                            self.Position = infoData.Position
                            self.PositionId = infoData.PositionId;

                            self.DutiesId = infoData.DutiesId;
                            self.DutiesName = infoData.DutiesName;
                            if(self.DutiesId){
                                self.getChildrenTree();
                            }
                            self.ResumeType = infoData.ResumeType
                            self.Source = infoData.Source?infoData.Source:undefined;
                            self.SalaryNow = infoData.SalaryNow
                            self.SalaryExpect = infoData.SalaryExpect
                            self.Photo = infoData.UserImage
                            self.FileName = infoData.FileName
                            if (infoData.WorkExperienceList) {
                                self.workExperienceMore = JSON.parse(infoData.WorkExperienceList)
                            }
                            if (infoData.EducationalExperienceList) {
                                self.educational = JSON.parse(infoData.EducationalExperienceList)
                            }
                            if (infoData.FamilyList) {
                                self.Family = JSON.parse(infoData.FamilyList)
                            }
                            if(infoData.Birthday=='0000-00-00'){
                                self.Birthday = undefined;
                            }else{
                                self.Birthday = infoData.Birthday;
                            }

                            self.CardId = infoData.CardId
                            self.IsMarry = infoData.IsMarry + ''
                            self.DetailedAddress = infoData.DetailedAddress
                            self.SelfEvaluation = infoData.SelfEvaluation
                            self.ProjectExperience = infoData.ProjectExperience
                            self.Status = true
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            ChangeSearchUser(value, name) {
                if (name == 'Source') {
                    // this.DepartmentId = value[0].id;
                    this.Source = value[0].title
                }else if(name=='RecommendUserArr'){
                     this.RecommendUserArr = value;
                }
            },
            changeTree(value) {
                this.Position = value.label;
                this.PositionId= value.value;
                this.getChildrenTree();
            },
            changeChildrenTree(value){
                this.DutiesId = value.key;
                this.DutiesName = value.label;

            },
            getChildrenTree(){
                let self = this;
                this.$axios.get(129426,{Id:self.PositionId},res=>{
                    if(res.data.code==0){
                        self.childrenTreeList = res.data.data;
                    }else{
                        self.DutiesId   = '';
                        self.DutiesName ='';
                        self.childrenTreeList = [];
                    }

                })
            },

            onChange(value) {
                let str = value._d

                let date = new Date(str)
                let startDate =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1) +
                    '-' +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())

                this.Birthday = startDate
                console.log(this.Birthday)
            },
            _action() {
                let self = this
                // if (!this.Mobile) {
                //     this.$message.error('请输入手机号码')
                //     return false
                // }
                // if (this.Mobile.length != 11) {
                //     this.$message.error('请输入正确的手机号码')
                //     return false
                // }
                if (!this.Name) {
                    this.$message.error('请输入姓名')
                    return false
                }
                // if(!this.Email){
                //     this.$message.error('请输入邮箱')
                //     return false
                // }
                if(this.FSource != 0){
                    this.Source = '';
                }
                if(this.FSource != 1){
                    this.RecommendUserArr ='';
                }


                let data = {
                    Name: self.Name,
                    Position: self.Position,
                    Source: self.Source,
                    SourceType: self.SourceType,
                    FSource: self.FSource,
                    Sex: self.Sex,
                    Age: self.Age,
                    Mobile: self.Mobile,
                    Email: self.Email,
                    WorkExperience: self.WorkExperience,
                    Qualification: self.Qualification,
                    WorkPlace: self.WorkPlace,
                    ResumeType: self.ResumeType, //0简历库 1人才库
                    ResumeUrl: self.ResumeUrl,
                    UserImage: self.Photo, //头像
                    SalaryNow: self.SalaryNow, //目前薪资
                    SalaryExpect: self.SalaryExpect, //期望薪资
                    WorkExperienceList: JSON.stringify(self.workExperienceMore), //工作经历
                    EducationalExperienceList: JSON.stringify(self.educational), //教育背景
                    FamilyList: JSON.stringify(self.Family), //家庭情况
                    Id: self.Id,
                    Birthday: self.Birthday,
                    CardId: self.CardId,
                    IsMarry: self.IsMarry,
                    FileName: self.FileName,
                    DetailedAddress: self.DetailedAddress,
                    SelfEvaluation:self.SelfEvaluation,
                    ProjectExperience:self.ProjectExperience,
                    RecommendUserArr:self.RecommendUserArr?JSON.stringify(self.RecommendUserArr):'',
                    DutiesId:self.DutiesId,
                    DutiesName:self.DutiesName,
                }
                this.$axios.post(1259, data, res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        self.onClose()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            onClose() {
                this._empty()
                this.$emit('visible', false)
            },
            callback(i) {
            },
            ResumeTypeChange(e) {
                if (e.target.checked) {
                    this.ResumeType = 1
                } else {
                    this.ResumeType = 0
                }
                console.log(e)
            },
            addInput(name) {
                if (name == 'workExperienceMore') {
                    let arr = {
                        StartDate: '',
                        EndDate: '',
                        CompayName: '',
                        Position: '',
                        Pay: '',
                        OperatingDuty: ''
                    }
                    this.workExperienceMore.push(arr)
                } else if (name == 'educational') {
                    let arr = {
                        StartDate: '',
                        EndDate: '',
                        Education: '',
                        SchoolName: '',
                        MajorName: '',
                        AcademicDegree: ''
                    }
                    this.educational.push(arr)
                } else if (name == 'Family') {
                    let arr = {
                        Name: '',
                        Age: '',
                        Relationship: '',
                        Phone: '',
                        JobContent: '',
                        JobUnit: '',
                        Residence: ''
                    }
                    this.Family.push(arr)
                }
            },
            ChangeTime(value, name, i) {
                let str1 = value[0]._d
                let str2 = value[1]._d

                let date1 = new Date(str1)
                let startDate =
                    date1.getFullYear() +
                    '-' +
                    (date1.getMonth() + 1 < 10
                        ? '0' + (date1.getMonth() + 1)
                        : date1.getMonth() + 1) +
                    '-' +
                    (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
                let date2 = new Date(str2)
                let endDate =
                    date2.getFullYear() +
                    '-' +
                    (date2.getMonth() + 1 < 10
                        ? '0' + (date2.getMonth() + 1)
                        : date2.getMonth() + 1) +
                    '-' +
                    (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())

                if (name == 'workExperienceMore') {
                    this.workExperienceMore[i]['StartDate'] = startDate
                    this.workExperienceMore[i]['EndDate'] = endDate
                } else if (name == 'educational') {
                    this.educational[i]['StartDate'] = startDate
                    this.educational[i]['EndDate'] = endDate
                }
            },
            Remove(i, name) {
                if (name == 'Family') {
                    this.Family.splice(i, 1)
                } else if (name == 'educational') {
                    this.educational.splice(i, 1)
                } else if (name == 'workExperienceMore') {
                    this.workExperienceMore.splice(i, 1)
                }
                console.log(i)
                // this.FAQ.splice(i,1);
            },
            handleClose() {
                this.FileName = ''
                this.ResumeUrl = ''
            },
            _upload(e, name) {
                this.$message.loading('正在上传中....', 0)
                let self = this
                let file = []
                let files = document.getElementById(name).files
                this.__uploadFile__(files).then(res => {
                    console.log(res)
                    this.$message.destroy()
                    if (res.length > 0) {
                        let url = res[0]['FileUrl']
                        if (name == 'Photo') {
                            self.Photo = url
                        } else if (name == 'ResumeUrl') {
                            self.FileName = res[0]['FileName']
                            console.log(self.FileName)
                            self.ResumeUrl = url
                        }
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },
            _empty() {
                let self = this
                self.Name = ''
                self.ResumeUrl = ''
                self.Sex = ''
                self.Age = ''
                self.Mobile = ''
                self.Email = ''
                self.WorkExperience = ''
                self.Qualification = ''
                self.WorkPlace = ''
                self.Position = ''
                self.ResumeType = 0
                self.Source = ''
                self.SalaryNow = ''
                self.SalaryExpect = ''
                self.Photo = ''
                self.FSource = '0'
                self.Birthday = ''
                self.CardId = ''
                self.IsMarry = '2'
                self.DetailedAddress = ''
                self.FileName = ''
                self.PositionId ='';
                self.DutiesId   = '';
                self.DutiesName ='';
                self.childrenTreeList = [];
                this.Source = '';
                this.RecommendUserArr ='';
                self.RecommendUserObj.selectArr = [];
                self.workExperienceMore = [
                    {
                        StartDate: '',
                        EndDate: '',
                        CompayName: '',
                        Position: '',
                        Pay: '',
                        OperatingDuty: ''
                    }
                ]
                self.educational = [
                    {
                        StartDate: '',
                        EndDate: '',
                        Education: '',
                        SchoolName: '',
                        MajorName: '',
                        AcademicDegree: ''
                    }
                ]
                self.Family = [
                    {
                        Name: '',
                        Age: '',
                        Relationship: '',
                        Phone: '',
                        JobContent: '',
                        JobUnit: '',
                        Residence: ''
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="less">
    .group {
        flex-wrap: wrap;
        /*justify-content: space-between;*/
        display: flex;

        /*align-items: center;*/
        .group-item {
            width: 156px;
            margin-right: 10px;
            margin-bottom: 20px;
        }
    }

    .content-title {
        width: 400px;
        border-bottom: 1px solid #dddfe3;
        display: flex;
        padding-bottom: 5px;
        .content-title-text {
            color: #121316;
            font-size: 14px;
            font-weight: 600;
            flex: 1;
            position: relative;
        }
        .content-title-text::after {
            content: '';
            width: 67px;
            height: 3px;
            background: #2994ff;
            position: absolute;
            left: 0;
            bottom: -5px;
        }
        .content-add {
            color: #2994ff;
            font-size: 14px;
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .avatar {
        display: flex;
        padding: 23px 0px;
        .upload-tips {
            color: #979daa;
            font-size: 12px;
        }
    }

    .upload {
        width: 280px;
        height: 40px;
        background: #f4f4f5;
        border: 1px solid #c1c5cc;
        opacity: 1;
        border-radius: 3px;
        color: #121316;
        position: relative;
        .upload-bth {
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .tab-content {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        padding: 12px 0px;
        .tab-group {
            width: 180px;
            margin-bottom: 20px;

            .tab-label {
                color: #242424;
                font-size: 14px;
            }
            .tab-item {
                width: 180px;
            }
        }
        .tab-group-active {
            margin-right: 36px;
        }
        /*.tab-group:nth-child(2n) {*/
        /*margin-right: 0;*/
        /*}*/
    }

    .tab-left {
        margin-top: 4px;
        margin-right: 20px;
    }

    .tab-right {
        background: #f4f4f5;
        flex: 1;
        /*margin: auto;*/
        text-align: center;
        .resume {
            display: block;
            margin: auto;
            width: 262px;
            height: 41px;
            text-align: center;
            color: #ffffff;
            background: #2994ff;
            margin-top: 100px;
        }
    }
</style>
