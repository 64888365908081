<template>
    <div class="NeedResumeList">
        <div class="content">
            <div class="tabs">
                <div
                        v-if="statisticsData.length > 0"
                        v-for="item in statisticsData"
                        :class="tabKey == item.value ? 'tab-item-active' : ''"
                        class="tab-item"
                        @click="_tabs(item.value)"
                >
                    <div class="tab-item-count">{{ item.count }}</div>
                    <div class="tab-item-text">{{ item.Stage }}</div>
                </div>
            </div>




        </div>

        <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                style=" z-index: 100000"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                关闭
            </a-button>
            <!--<a-button type="primary" @click="previewStatusClose">-->
            <!--关闭-->
            <!--</a-button>-->
        </div>






    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import E from 'wangeditor'
    export default {
        name: "NeedResumeList",
        components: {person},
        data() {
            return {
                OpenType:'Need',
              statisticsData: [],
              tabKey:'',
              isResumeProgress:2,//1招聘进展2阶段名称
            }
        },
        props: {
            Id: {
                type: String
            }
        },
        computed: {
            ...mapState({
                showNav: state => state.app.showNav
            }),

        },
        created() {

            // this.loadData();
            this.statisticsStageNum();
        },
        methods: {
            loadData() {
                let self = this;
                let data = {
                    NeedId: self.Id,
                    pagesize: self.Limit,
                    p: self.Page,
                    resumeProgress:'resumeScreen',
                    ProgressNeedId:self.Id,

                };
                this.$axios.get(1260, data, res => {
                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.total;
                    } else {
                        self.tableList = [];
                        self.total = '';
                    }
                })
            },

            onClose() {
                  
                this.$emit('_ResumeLisClose', false)
            },


            statisticsStageNum() {
                let self = this
                this.$axios.get(1264, {
                    // NeedId: self.Id,
                    resumeProgress:'resumeScreen',
                    ProgressNeedId:self.Id,
                    ProgressStage:'waitScreen'
                }, res => {
                    if (res.data.code == 0) {
                        self.statisticsData = res.data.data;
                        //isResumeProgress:2,//1招聘进展2阶段名称
                        self.isResumeProgress = res.data.isResumeProgress;

                    }
                })
            },
            _tabs(value) {
                if(this.tabKey ==value){
                    return false;
                }
                this.tabKey = value
                let self = this;
                let activity = 0;
                let num = '';
                for (let i=0;i<self.searchList.length;i++){
                    if(self.searchList[i].field =='Stage' || self.searchList[i].field == 'ProgressStage'){
                        activity = 1;
                        num =i;
                    }
                }
                if(activity ==1){
                    self.searchList[num].value = value;
                }else{
                    let arr = {};
                    if(this.isResumeProgress==1){
                        arr.name = '需求进度';
                        arr.field = 'ProgressStage';
                        arr.value = value;
                        arr.type = 'content';
                        self.searchList.push(arr);
                    }else{
                        arr.name = '阶段名称';
                        arr.field = 'Stage';
                        arr.value = value;
                        arr.type = 'select';
                        self.searchList.push(arr);
                    }
                }
                this.loadData();
            },
            
        }
    }
</script>

<style scoped lang="less">
    .search {
        display: flex;
        padding: 0px 15px;

        .tag-group {
            flex: 1;
            display: flex;
            align-items: center;
            .tag-item {
                width: auto;
                border: 1px solid #dddddd;
                border-radius: 15px;
                padding: 0px 5px;
                margin-right: 15px;
                .tags {
                    border: 0;
                    margin: 0;
                }
                .tags-active {
                    color: #2994ff;
                }
            }
        }
    }
    .tabs {
        display: flex;
        border-bottom: 1px solid #dddfe3;
        padding: 10px;
        margin-bottom: 20px;
        .tab-item {
            width: 89px;
            padding-left: 10px;
            /*margin-right: 24px;*/
            cursor: pointer;
            .tab-item-count {
                color: #242424;
                font-size: 22px;
                /*text-align: center;*/
            }
            .tab-item-text {
                color: #999999;
                font-size: 12px;
                /*text-align: center;*/
            }
        }
        .tab-item-active {
            position: relative;
        }
        .tab-item-active::after {
            content: '';
            width: 56px;
            height: 2px;
            background: #2994ff;
            position: absolute;
            left: 0;
            bottom: -10px;
        }
    }
    .table-action{
        color: #2994ff;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
    }
    .recommend-group {
        display: flex;
        margin-top: 18px;
        .recommend-group-label {
            width: 120px;
            .recommend-group-label-active {
                color: #ff0000;
            }
        }
        .recommend-group-item {
            width: 438px;
        }
    }
    .chack-num{
        color: #999999;
        font-size: 12px;
        margin-right: 15px;
    }
</style>