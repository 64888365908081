<template>
    <div class="resumeInfo">
        <div class="content" v-if="infoData">
            <div class="userInfo" v-viewer>
                <a-avatar :size="20" icon="user" :src="infoData.UserImage"/>
                <div class="userInfo-name">{{ infoData.Name }}
                    <a-button style="margin-left: 20px">
                        {{infoData.DutiesName?infoData.DutiesName:infoData.Position}}.{{infoData.RecommendUserId?'内部推荐':FSourceArr[infoData.FSource]}}.{{infoData.RecommendUserId?infoData.RecommendUserName:infoData.Source}}
                    </a-button>
                    
                    <i @click="_actionSave" :class="mouseKey=='save'?'color-blue':''" @mouseover="changeSave('save')" @mouseout="_emptyKey" class="iconfont icon-bianji head-item"></i>

                    <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_del"
                            okText="确定"
                            cancelText="取消"
                    >
                        <i :class="mouseKey=='del'?'color-blue':''" @mouseover="changeSave('del')" @mouseout="_emptyKey" class="iconfont icon-shanchu- head-item"></i>
                    </a-popconfirm>

                    <a-icon  @click="onClose" :class="mouseKey=='close'?'color-blue':''" @mouseover="changeSave('close')" @mouseout="_emptyKey" type="close-circle" class="head-item"/>

                </div>
                <!--<a-button type="primary">已申请一次</a-button>-->
            </div>
            <div class="base-info">
                {{ infoData.Sex == 1 ? '女' : infoData.Sex == 2 ? '' : '男' }} ·
                {{ infoData.Age?infoData.Age+'岁·':'' }}  {{ infoData.Mobile?infoData.Mobile+'·':'' }}
                {{ infoData.WorkExperience?infoData.WorkExperience+'年·':'' }} {{ infoData.QualificationStr?infoData.QualificationStr+'·':'' }}
                {{ infoData.WorkPlace?infoData.WorkPlace:'' }} {{infoData.Email?infoData.Email:''}}
            </div>
            <div class="tab-content">
                <a-tabs style="flex: 1;" :tabBarGutter="tabBarGutter" :tabBarStyle="{'padding':'8px 8px'}" size="small" v-model="tabKey" @change="callback">
                    <a-tab-pane style="padding: 12px 6px;"  key="1" tab="基本信息">
                        <div style="margin-bottom: 20px">
                            <a-button style="marginRight: 8px;background: #f0f7ff;border-radius: 35px;color: #52a9ff;border: 0px">标准简历</a-button>
                            <a-button style="marginRight: 8px;border-radius: 35px;border: 0px;background: #f6f6f6;color: #121316" @click="getPreviewUrl">查看原始简历</a-button>
                            <!--<a-button style="marginRight: 8px;border-radius: 35px;border: 0px;background: #f6f6f6;color: #121316" @click="_print">打印简历</a-button>-->
                            <!--<a-button type="primary" @click="_actionSave">编辑简历</a-button>-->
                            <a-button type="primary"  style="margin-bottom: 20px;margin-left: 20px" v-print="printObj">打印</a-button>

                        </div>

                        <div id="print_view">
                            <div class="userInfo" v-viewer>
                                <a-avatar :size="20" icon="user" :src="infoData.UserImage"/>
                                <div class="userInfo-name">{{ infoData.Name }}</div>

                            </div>
                            <div class="base-info">
                                {{ infoData.Sex == 1 ? '女' : infoData.Sex == 2 ? '' : '男' }} ·
                                {{ infoData.Age?infoData.Age+'岁·':'' }}  {{ infoData.Mobile?infoData.Mobile+'·':'' }}
                                {{ infoData.WorkExperience?infoData.WorkExperience+'年·':'' }} {{ infoData.QualificationStr?infoData.QualificationStr+'·':'' }}
                                {{ infoData.WorkPlace?infoData.WorkPlace:'' }} {{infoData.Email?infoData.Email:''}}
                            </div>
                            <div class="userInfo-title">个人信息</div>
                            <div class="userDetail">
                                <div class="userDetail-item">身份证号</div>
                                <div class="userDetail-item">{{ infoData.CardId }}</div>
                            </div>
                            <div class="userDetail">
                                <div class="userDetail-item">婚姻情况</div>
                                <div class="userDetail-item">
                                    {{
                                    infoData.IsMarry == 1
                                    ? '已婚'
                                    : infoData.IsMarry == 2
                                    ? '未婚'
                                    : '未知'
                                    }}
                                </div>
                            </div>
                            <div class="userDetail">
                                <div class="userDetail-item">详细地址</div>
                                <div class="userDetail-item">{{ infoData.DetailedAddress }}</div>
                            </div>
                            <div class="userInfo-title">求职意向</div>
                            <div class="userDetail" style="    flex-wrap: nowrap;">
                                <div class="userDetail-item">当前薪资</div>
                                <div class="userDetail-item">{{ infoData.SalaryNow?infoData.SalaryNow:'' }}</div>
                                <div class="userDetail-item">期望薪资</div>
                                <div class="userDetail-item" style="margin-right: 0px">{{ infoData.SalaryExpect?infoData.SalaryExpect:'' }}</div>

                            </div>
                            <div class="userDetail">
                                <div class="userDetail-item">期望城市</div>
                                <div class="userDetail-item">{{ infoData.WorkPlace }}</div>
                            </div>

                            <div class="userInfo-title">工作经历</div>
                            <div
                                    class="jop-list"
                                    v-if="WorkExperienceList.length >0"
                                    v-for="item in WorkExperienceList"
                            >

                                <div class="jop-title">
                                    {{ item.StartDate?item.StartDate+' ---- ':'' }}{{ item.EndDate?item.EndDate+' 丨 ':'' }} {{item.CompayName?item.CompayName+' | ':'' }}{{ item.Position?item.Position+' | ':'' }} {{ item.Pay?item.Pay:'' }}
                                </div>
                                <div class="jop-content">
                                    <div v-for="items  in item.OperatingDuty">{{items}}</div>
                                    <!--1.负责公司玩具类的产品设计，具有较强的理解、领悟能力，设计思路清晰，充满激情，具有对设计风格的整体把握能力；-->
                                    <!--2.热爱设计并具备良好的审美观和色彩把握能力,独立完成全套流程（插画、排版、包装平面设计）；-->
                                    <!--3.与产品策划沟通配合，准确领会产品，根据产品内容进行插画创作，排版，设计，有工厂跟色经验优先。-->
                                    <!--4.熟悉相关设计软件，并手绘一些卡通画、插图、插画等；-->
                                    <!--5. 工作细致耐心，会对作品进行多次检查并保证内容的准确性。-->
                                    <!--6. 投简历请附作品集。-->
                                </div>
                            </div>
                            <div class="userInfo-title">项目经验</div>
                            <div
                                    class="jop-list"
                                    v-if="ProjectExperience.length>0"

                            >
                                <div class="jop-content">

                                    <div v-for="items in infoData.ProjectExperience">
                                        {{ items }}
                                    </div>

                                </div>
                            </div>
                            <div class="userInfo-title">教育背景</div>
                            <div
                                    class="jop-list"
                                    v-if="EducationalExperienceList.length>0"
                                    v-for="item in EducationalExperienceList"
                            >
                                <div class="jop-content">
                                    <div class="jop-title">
                                        {{ item.StartDate?item.StartDate+' ---- ':'' }}{{ item.EndDate?item.EndDate+' |':'' }} {{item.Education?item.Education+' |':'' }}
                                         {{ item.SchoolName?item.SchoolName+' |':'' }}{{ item.MajorName?item.MajorName:'' }}
                                    </div>

                                    <p v-for="items in item.AcademicDegree ">{{items}}</p>

                                </div>
                            </div>
                            <div class="userInfo-title">家庭情况</div>
                            <div
                                    class="jop-list"
                                    v-if="FamilyList.length>0"
                                    v-for="item in FamilyList"
                            >
                                <div class="jop-content">
                                    <div>
                                        {{ item.Name?item.Name+' |':'' }}  {{ item.Age?item.Age+'岁  |':'' }} {{ item.Relationship?item.Relationship+' |':'' }}
                                        {{ item.Phone?item.Phone+' |':'' }}  {{ item.JobContent ?item.JobContent+' |':'' }} {{ item.JobUnit?item.JobUnit:'' }}
                                    </div>
                                    <p v-for="items in item.Residence">
                                        {{ items }}
                                    </p>

                                </div>
                            </div>


                            <div class="userInfo-title">自我评价</div>
                            <div
                                    class="jop-list"
                                    v-if="SelfEvaluation.length>0"
                                    
                            >
                                <div class="jop-content">
                                   
                                    <p v-for="items in infoData.SelfEvaluation">
                                        {{ items }}
                                    </p>

                                </div>
                            </div>
                        </div>

                    </a-tab-pane>
                    <a-tab-pane style="padding: 12px 6px;" key="2" tab="面试" force-render>
                        <resumeInterview
                                v-if="tabKey == 2"
                                :infoData="infoData"
                                :Pid="Id"
                        ></resumeInterview>
                    </a-tab-pane>

                    <a-tab-pane style="padding: 12px 6px;" key="4" tab="offer/录用">
                        <resumeOffer
                                v-if="tabKey == 4"
                                :infoData="infoData"
                                :Pid="Id"
                        ></resumeOffer>
                    </a-tab-pane>

                    <a-tab-pane style="padding: 12px 6px;" key="3" :tab="'附加信息'+(EnclosureCount>0?'('+EnclosureCount+')':'')">
                        <resumEnclosure @EnclosureCount="EnclosureCountChange" :infoData="infoData" :Pid="Id"></resumEnclosure>
                    </a-tab-pane>
                    <a-tab-pane style="padding: 12px 6px;" key="5" tab="跟踪记录">
                        <resumeRemark
                                v-if="tabKey == 5"
                                :infoData="infoData"
                                :Pid="Id"
                        ></resumeRemark>
                    </a-tab-pane>
                    <!--<a-tab-pane key="6" tab="附加信息">-->
                    <!--Content of Tab Pane 3-->
                    <!--</a-tab-pane>-->
                    <!--<a-tab-pane key="7" tab="操作记录">-->
                    <!--Content of Tab Pane 3-->
                    <!--</a-tab-pane>-->
                </a-tabs>
                <div class="tab-right">
                    <div class="step">
                        <!--step-content-right-->

                        <div class="step-content" v-for="(item,index) in stageArr"
                             :class="current>index && (stageArr.length-1)!=index?'step-content-right-active':(stageArr.length-1)!=index?'step-content-right':'step-content-width'">
                            <div class="step-content-num" :class="current>=index?'step-content-num-active':''">
                                {{index+1}}
                            </div>
                            <div class="step-content-title"
                                 :class="item.key == infoData.Stage?'step-content-title-active':''">{{item.name}}
                            </div>
                        </div>
                    </div>
                    <!--<div class="tab-right-top">-->
                    <!--<a-steps-->
                    <!--:current="current"-->
                    <!--labelPlacement="vertical"-->
                    <!--type="navigation"-->
                    <!--size="small"-->
                    <!--&gt;-->
                    <!--<a-step>-->
                    <!--<span slot="description" class="currentActive">初筛</span>-->
                    <!--</a-step>-->
                    <!--<a-step>-->
                    <!--<span slot="description">用人部门筛选</span>-->
                    <!--</a-step>-->
                    <!--<a-step>-->
                    <!--<span slot="description">面试</span>-->
                    <!--</a-step>-->
                    <!--<a-step>-->
                    <!--<span slot="description">沟通offer</span>-->
                    <!--</a-step>-->
                    <!--<a-step>-->
                    <!--<span slot="description">待入职</span>-->
                    <!--</a-step>-->
                    <!--</a-steps>-->
                    <!--</div>-->

                    <div class="tab-rigth-bottom">
                        <div class="tab-rigth-button">
                            <a-button-group>
                                <a-button
                                        style="width: 217px;height: 42px"
                                        type="primary"
                                        @click="nextAction"
                                >
                                    {{current==4?'添加员工':(stageArr[current+1].name)}}
                                </a-button>
                                <a-button style="width: 44px;height: 42px" type="primary">
                                    <!--<a-icon type="down" />-->
                                    <a-dropdown :trigger="['click']">
                                        <a
                                                class="ant-dropdown-link"
                                                @click="e => e.preventDefault()"
                                        >
                                            <a-icon type="down"/>
                                        </a>
                                        <a-menu slot="overlay" @click="changeStatus">
                                            <a-menu-item v-for="item in stageArr" :key="item.key">
                                                {{ item.name }}
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </a-button>
                            </a-button-group>
                        </div>

                        <div v-if="OpenType=='Need'">
                            <a-button class="tab-rigth-button" v-if="isHr==1 " @click="_relation">关联招聘需求</a-button>

                            <a-button class="tab-rigth-button" v-if="infoData.ResumeType == 1 && isHr==1" type="primary">
                                人才库
                            </a-button>
                            <a-popconfirm v-if="infoData.ResumeType != 1 && isHr==1" placement="bottom" cancelText="取消"
                                          okText="确定" @confirm="fieldAction('ResumeType', 1)"
                                          @cancel="maxClassify = -1">
                                <template slot="title">
                                    <p>你确定要放入人才库嘛？</p>
                                </template>
                                <a-button
                                        class="tab-rigth-button"
                                        :type="infoData.ResumeType == 1 ? 'primary' : ''"
                                >
                                    放入人才库
                                </a-button>
                            </a-popconfirm>
                            <!--<a-button-->
                                    <!--v-if="isHr!=1"-->
                                    <!--class="tab-rigth-button"-->
                                    <!--:class="infoData.NeedsDepartmentName ? 'tab-rigth-button-active' : ''" -->
                                    <!--@click="_recommend"-->
                            <!--&gt;-->
                                <!--推荐给用人部门负责人-->
                            <!--</a-button>-->

                            <a-button  v-if="isHr!=1" @click="setResumeStatus(3)" class="tab-rigth-button">通过</a-button>
                            <a-button v-if="isHr!=1" class="tab-rigth-button" @click="setResumeStatus(4)">淘汰</a-button>

                            <div class="tab-rigth-button">
                                <a-popconfirm
                                        v-if="infoData.IsFollow == 1 && isHr==1"
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="_IsFollow(0)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>你确定要取消关注嘛？</p>
                                    </template>
                                    <a-button
                                            type="primary"
                                            style="width: 128px;margin-right: 6px;height: 42px"
                                    >关注
                                    </a-button>
                                </a-popconfirm>

                                <a-popconfirm
                                        v-if="infoData.IsFollow != 1 && isHr==1"
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="_IsFollow(1)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>你确定要关注嘛？</p>
                                    </template>
                                    <a-button style="width: 128px;margin-right: 6px;height: 42px"
                                    >关注
                                    </a-button
                                    >
                                </a-popconfirm>
                                <a-button style="width: 128px;height: 42px" v-if="isHr==1" @click="addBlack()">加入黑名单
                                </a-button>

                            </div>
                        </div>
                        <div v-else>
                            <a-button class="tab-rigth-button"  @click="_relation">关联招聘需求</a-button>

                            <a-button class="tab-rigth-button" v-if="infoData.ResumeType == 1 " type="primary">
                                人才库
                            </a-button>
                            <a-popconfirm v-if="infoData.ResumeType != 1" placement="bottom" cancelText="取消"
                                          okText="确定" @confirm="fieldAction('ResumeType', 1)"
                                          @cancel="maxClassify = -1">
                                <template slot="title">
                                    <p>你确定要放入人才库嘛？</p>
                                </template>
                                <a-button
                                        class="tab-rigth-button"
                                        :type="infoData.ResumeType == 1 ? 'primary' : ''"
                                >
                                    放入人才库
                                </a-button>
                            </a-popconfirm>
                            <a-button class="tab-rigth-button"
                                    :class="infoData.NeedsDepartmentName ? 'tab-rigth-button-active' : ''"
                                    @click="_recommend">
                                推荐给用人部门负责人
                            </a-button>
                            <div class="tab-rigth-button">
                                <a-popconfirm
                                        v-if="infoData.IsFollow == 1 "
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="_IsFollow(0)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>你确定要取消关注嘛？</p>
                                    </template>
                                    <a-button
                                            type="primary"
                                            style="width: 128px;margin-right: 6px;height: 42px"
                                    >关注
                                    </a-button>
                                </a-popconfirm>

                                <a-popconfirm
                                        v-if="infoData.IsFollow != 1"
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="_IsFollow(1)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>你确定要关注嘛？</p>
                                    </template>
                                    <a-button style="width: 128px;margin-right: 6px;height: 42px"
                                    >关注
                                    </a-button
                                    >
                                </a-popconfirm>
                                <a-button style="width: 128px;height: 42px"  @click="addBlack()">加入黑名单
                                </a-button>

                            </div>

                        </div>

                        <div class="tab-rigth-group">
                            <div class="tab-rigth-title">候选人归属</div>
                            <div class="tab-rigth-item">
                <span
                >{{ infoData.NeedsDepartmentName }}丨{{
                    infoData.NeedsPositionName
                  }}
                  丨 {{ infoData.NeedsNumber }}人 丨
                  {{ infoData.NeedsDemandUserName
                  }}<i
                            class="iconfont icon-bianji icon-btn"
                            style="font-size: 20px"
                            @click="_relation"
                    ></i>&nbsp;</span>
                            </div>
                        </div>
                        <div class="tab-rigth-group">
                            <div class="tab-rigth-title">招聘负责人</div>
                            <div class="tab-rigth-item">
                                <span style="margin-right: 15px">{{ infoData.NeedsInchargeUserName }}</span>
                                <i
                                        class="iconfont icon-bianji icon-btn"
                                        style="font-size: 20px;position: relative"
                                        @click="_relation"
                                >
                                    <person
                                            style="position: absolute;left: 0;top: 0;opacity: 0"
                                            class="recommend-group-item"
                                            v-bind="NeedsInchargeUserNameObj"
                                            @getData="ChangeSearchUser($event, 'NeedsInchargeUserName')"
                                    ></person>
                                </i>
                            </div>
                        </div>
                        <div class="tab-rigth-group">
                            <div class="tab-rigth-title">录入人</div>
                            <div class="tab-rigth-item">
                                <span>{{ infoData.CreateUserName }}({{infoData.CreateTime}})</span>
                            </div>
                        </div>
                        <div class="tab-rigth-group">
                            <div class="tab-rigth-title">自定义标签</div>
                            <div class="tab-rigth-item">
                                <template v-for="(tag, index) in tags">
                                    <a-tag
                                            :key="tag"
                                            :closable="closable"
                                            style="margin-bottom: 10px"
                                            @close="() => handleClose(tag)"
                                    >
                                        {{ tag }}
                                    </a-tag>
                                </template>
                                <a-input
                                        v-if="inputVisible"
                                        ref="input"
                                        type="text"
                                        size="small"
                                        :style="{ width: '78px' }"
                                        :value="inputValue"
                                        @change="handleInputChange"
                                        @keyup.enter="handleInputConfirm"
                                />
                                <!--@blur="handleInputConfirm"-->
                                <a-tag
                                        v-else
                                        style="background: #fff; borderStyle: dashed;"
                                        @click="showInput"
                                >
                                    <a-icon type="plus"/>
                                    自定义
                                </a-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
                :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
                style=" z-index: 100000"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                关闭
            </a-button>
            <!--<a-button type="primary" @click="_action()">-->
            <!--关闭-->
            <!--</a-button>-->
        </div>
        <!--        关联招聘需求-->
        <a-drawer
            title="招聘需求"
            width="1000"
            :closable="false"
            :visible="needvisible"
            @close="needonClose"
        >
            <div style="margin-bottom: 60px">
                <needsContent  :NeedResumeId="NeedResumeId" @visible="needonClose"></needsContent>
            </div>
        </a-drawer>


        <a-drawer
                title="招聘需求"
                width="1100"
                :closable="false"
                :visible="relationStatus"
                @close="relationClose"
        >
            <div style="margin-bottom: 60px">
                <relationNeed
                        v-if="relationStatus"
                        @visible="relationClose"
                        :resumeId="resumeId"
                        :NeedResumeId="NeedResumeId"
                ></relationNeed>
            </div>
        </a-drawer>
        <a-drawer
                title="推荐给用人部门负责人"
                width="600"
                :closable="false"
                :visible="recommendStatus"
                @close="recommendonClose"
        >
            <a-input :default-value="infoData.Name" readonly/>
            <div class="recommend-group">
                <div class="recommend-group-label">
                    转发到<span class="recommend-group-label-active">*</span>
                </div>
                <person
                        style="width: 438px"
                        class="recommend-group-item"
                        v-bind="recruitObj"
                        @getData="ChangeSearchUser($event, 'recommendUserList')"
                ></person>
            </div>
            <div class="recommend-group">
                <div class="recommend-group-label">告诉用人部门</div>
                <a-textarea
                        style="height: 120px"
                        class="recommend-group-item"
                        placeholder="例如：大公司从业经历，有成功产品案例"
                        v-model="Remark"
                />
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'

        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="recommendonClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_recommendAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <a-drawer
                title="简历预览"
                width="1100"
                :closable="false"
                :visible="previewStatus"
                @close="previewStatusClose"
        >
            <a-popconfirm

                    placement="bottom"
                    cancelText="取消"
                    okText="确定"
                    @confirm="downFile"
                    @cancel="maxClassify = -1"
            >
                <template slot="title">
                    <p>你确定要下载简历嘛？</p>
                </template>
                <a-button type="primary"  style="margin-bottom: 20px">下载</a-button>

            </a-popconfirm>
            
            <div id="container" style="min-height: 800px">
                <img v-if="ResumeUrlType" style="width: 80%" :src="infoData.ResumeUrl" alt="">
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="previewStatusClose">
                    关闭
                </a-button>
                <!--<a-button type="primary" @click="previewStatusClose">-->
                <!--关闭-->
                <!--</a-button>-->
            </div>
        </a-drawer>

        <!--编辑简历-->
        <a-drawer
                title="编辑简历"
                width="800"
                :closable="false"
                :visible="Actionvisible"
                @close="ActiononClose"

        >
            <div style="margin-bottom: 60px">
                <resumeAction
                        v-if="Actionvisible "
                        :Id="infoData.Id"
                        @visible="ActiononClose"
                ></resumeAction>

            </div>
        </a-drawer>
        <!--加入黑名单-->
        <a-drawer
                title="加入黑名单"
                width="600"
                :closable="false"
                :visible="BlackStatus"
                @close="blackClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 50px">原因<span
                        class="recommend-group-label-active">*</span></div>
                <a-textarea
                        style="height: 120px"
                        class="recommend-group-item"
                        placeholder="请输入原因"
                        v-model="BlackContent"
                />
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="blackClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_blackAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--驳回候选人-->
        <a-drawer
                :title="infoData.Name"
                width="600"
                :closable="false"
                :visible="rejectStatus"
                @close="rejectClose"
        >

            <div class="reject-title">你拒绝了{{infoData.Name}}进入面试环节</div>
            <div class="reject-text">候选人将直接纳入人才库</div>

            <a-textarea
                    style="height: 120px"
                    class="recommend-group-item"
                    placeholder="填写相关信息"
                    v-model="rejectContent"
            />

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                    style=" z-index: 100000"
            >
                <a-button style="marginRight: 8px" @click="rejectClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_rejection()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--通过候选人-->
        <a-drawer
                :title="infoData.Name"
                width="800"
                :closable="false"
                :visible="adobtVisible"
                @close="adobtClose"

        >
            <div style="margin-bottom: 60px">
                <resumeAdopt
                        v-if="adobtVisible "
                        :ResumeId="infoData.Id"
                        @visible="adobtClose"
                ></resumeAdopt>

            </div>
        </a-drawer>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import resumeInterview from 'pages/Hr/resumeInterview'
    import resumeOffer from 'pages/Hr/resumeOffer'
    import moment from 'moment'
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'
    import relationNeed from 'pages/Hr/relationNeed'
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import resumeAction from 'pages/Hr/resumeAction'
    import resumeRemark from 'pages/Hr/resumeRemark'
    import resumeAdopt from 'pages/Hr/resumeAdopt'
    import resumEnclosure from 'pages/Hr/resumEnclosure';
    import Print from 'vue-print-nb'
    import needsContent from 'pages/Hr/needsContent'
    Vue.use(Print);  //注册
    Vue.use(Viewer)
    export default {
        components: {resumeInterview, resumeOffer, relationNeed, person, resumeAction, resumeRemark,resumeAdopt,resumEnclosure,needsContent},
        name: 'resumeInfo',
        data() {
            return {
                tabBarGutter:6,
                infoData: '',
                current: 0,
                tags: ['22', '33'],
                inputVisible: false,
                inputValue: '',
                closable: true,
                tabKey: "1",
                stageArr: [
                    {key: '10', name: '初筛'},
                    {key: '20', name: '用人部门筛选'},
                    {key: '30', name: '面试'},
                    {key: '40', name: '沟通offer'},
                    {key: '50', name: '待入职'}
                ],
                FSourceArr: {"0": "社会招聘", "2": "校园招聘", "3": "其他"},
                InterviewId: '',
                resumeId: [],
                relationStatus: false,
                recommendStatus: false,
                recruitObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 100, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent11', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                NeedsInchargeUserNameObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent11', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                recommendUserList: [],
                previewStatus: false,
                Remark: '',
                ResumeUrlType: false,
                Actionvisible: false,
                BlackStatus: false,
                BlackContent: '',
                WorkExperienceList: [],
                EducationalExperienceList: [],
                FamilyList: [],
                isHr: 0,//1hr 2用人部门负责人
                rejectStatus: false,
                rejectContent: '',
                adobtVisible:false,
                printObj: {
                    id: "print_view",
                    popTitle: '',
                    extraCss: 'https://www.google.com,https://www.google.com',
                    extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
                },
                SelfEvaluation:[],
                ProjectExperience:[],
                mouseKey:'',
                EnclosureCount:0,
                needvisible:false,
                NeedResumeId:[],
            }
        },
        props: {
            Id: {
                type: String
            },
            OpenType: {
                type: String
            },
            ResumeKey: {
                type: String
            },

        },
        created() {
            this.loadData();
        },
        methods: {
            needonClose() {
                this.needvisible = false;
               
            },
            relationClose() {
                this.resumeId = []
                this.relationStatus = false
                this.loadData()
            },
            _relation() {
                this.resumeId.push(this.Id)
                this.NeedResumeId.push(this.Id);
                this.relationStatus = true
                // console.log(222);
                // this.NeedResumeId = [];
                // this.needvisible = true;
                // this.NeedResumeId.push(this.Id);
                // console.log(this.NeedResumeId)

                
            },
            EnclosureCountChange(data){
                this.EnclosureCount = data;
            },
            _del(){
                let self = this
                this.$message.loading('操作中....', 0)
                let ids = [];
                ids.push(self.Id);
                this.$axios.get(1262, {Id: JSON.stringify(ids)}, res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        self.onClose();
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            changeSave(val) {
                console.log(val);
                this.mouseKey = val;
            },
            _emptyKey(){
                this.mouseKey ='';
            },
            downFile(){
                if(!this.infoData.ResumeUrl){
                    this.$message.error('未上传简历');
                    return false;
                }
                let arrayResult = this.infoData.ResumeUrl.split('.');
                let fileType = arrayResult[arrayResult.length-1];
                let fileUrl = this.infoData.ResumeUrl+'?attname='+this.infoData.Name+'.'+fileType;

                window.open(fileUrl);
            } ,
            //通过候选人
            adobtClose(){
                this.loadData()
                this.adobtVisible = false;
            },
            //驳回候选人
            rejectClose() {
                this.rejectStatus = false;
                this.rejectContent = '';
            },
            _rejection() {
                if (!this.rejectContent) {
                    this.$message.error('填写相关信息');
                    return false;
                }
                self = this;
                this.$message.loading('操作中...', 0);
                this.$axios.get(12914, {
                    ResumeId: self.Id,
                    Status: 4,
                    Remark: self.rejectContent
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        self.rejectClose();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            //加入黑名单
            addBlack() {
                this.BlackContent = this.infoData.ToBackReason;
                this.BlackStatus = true;
            },
            setResumeStatus(value) {
                if (value == 4) {
                    this.rejectStatus = true;
                } else {
                    self = this;
                    this.$message.loading('操作中...', 0);
                    this.$axios.get(12914, {
                        ResumeId: self.Id,
                        Status: value,
                    }, res => {
                        this.$message.destroy();
                        if (res.data.code == 0) {
                            self.adobtVisible = true;
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            },
            blackClose() {
                this.BlackContent = '';
                this.BlackStatus = false;
                this.loadData();
            },
            _blackAction() {
                let self = this;
                if (!this.BlackContent) {
                    this.$message.error('请输入拉入黑名单的原因');
                    return false;
                }
                this.$axios.post(12994, {
                    Id: self.Id,
                    ToBackReason: self.BlackContent,
                }, res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        self.blackClose();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _actionSave() {
                this.Actionvisible = true;
            },
            ActiononClose() {

                this.loadData()
                this.Actionvisible = false
            },
            moment,
            loadData() {
                let self = this
                this.$message.loading('加载中....', 0)
                this.$axios.get(
                    1261,
                    {
                        Id: self.Id
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            self.EnclosureCount = res.data.EnclosureCount;
                            self.isHr = res.data.isHr;
                            self.infoData = res.data.data;
                            if(typeof(this.ResumeKey) !='undefined' ){
                                this.tabKey = this.ResumeKey;

                            }else{
                                this.tabKey = "1";
                            }

                            this.printObj.popTitle =  res.data.data.Name;
                            //this.adobtVisible = true;
                            self.tags = res.data.data.Tags;
                            if (self.infoData.WorkExperienceList) {
                                self.WorkExperienceList = JSON.parse(self.infoData.WorkExperienceList);
                                if (self.WorkExperienceList.length > 0) {
                                    for (let i = 0; i < self.WorkExperienceList.length; i++) {
                                        let OperatingDuty = [];
                                        if (self.WorkExperienceList[i].OperatingDuty) {
                                            if(self.WorkExperienceList[i].OperatingDuty.indexOf('\r') != -1){

                                                OperatingDuty = self.WorkExperienceList[i].OperatingDuty.split('\r');
                                            }else{
                                                OperatingDuty = self.WorkExperienceList[i].OperatingDuty.split('\n');
                                            }

                                            // console.log(OperatingDuty);
                                        }

                                        self.WorkExperienceList[i].OperatingDuty = OperatingDuty;
                                    }
                                }
                            }


                            if (self.infoData.EducationalExperienceList) {
                                self.EducationalExperienceList = JSON.parse(self.infoData.EducationalExperienceList);
                                if (self.EducationalExperienceList.length > 0) {
                                    for (let i = 0; i < self.EducationalExperienceList.length; i++) {
                                        let AcademicDegree = [];
                                        if (self.EducationalExperienceList[i].AcademicDegree) {
                                            if(self.EducationalExperienceList[i].AcademicDegree.indexOf('\r') != -1){
                                                AcademicDegree = self.EducationalExperienceList[i].AcademicDegree.split('\r');
                                            }else{
                                                AcademicDegree = self.EducationalExperienceList[i].AcademicDegree.split('\n');
                                            }

                                        }
                                        self.EducationalExperienceList[i].AcademicDegree = AcademicDegree;

                                    }
                                }
                            }
                            if (self.infoData.FamilyList) {
                                self.FamilyList = JSON.parse(self.infoData.FamilyList);
                                if (self.FamilyList.length > 0) {
                                    for (let i = 0; i < self.FamilyList.length; i++) {
                                        let Residence = [];
                                        if (self.FamilyList[i].Residence) {
                                            if(self.FamilyList[i].Residence.indexOf('\r') != -1){
                                                Residence = self.FamilyList[i].Residence.split('\r');
                                            }else{
                                                Residence = self.FamilyList[i].Residence.split('\n');
                                            }
                                        }
                                        self.FamilyList[i].Residence = Residence;

                                    }
                                }
                            }

                            if (self.infoData.SelfEvaluation) {

                                let SelfEvaluation = [];
                                if(self.infoData.SelfEvaluation.indexOf('\r') != -1){
                                    SelfEvaluation = self.infoData.SelfEvaluation.split('\r');
                                }else{
                                    SelfEvaluation = self.infoData.SelfEvaluation.split('\n');
                                }
                                if(SelfEvaluation.length >0){
                                    for (let i=0;i<SelfEvaluation.length;i++){
                                        self.SelfEvaluation.push(SelfEvaluation[i]);
                                    }
                                }
                                self.infoData.SelfEvaluation = SelfEvaluation;
                            }
                            if (self.infoData.ProjectExperience) {

                                let ProjectExperience = [];
                                if(self.infoData.ProjectExperience.indexOf('\r') != -1){
                                    ProjectExperience = self.infoData.ProjectExperience.split('\r');
                                }else{
                                    ProjectExperience = self.infoData.ProjectExperience.split('\n');
                                }
                                if(ProjectExperience.length >0){
                                    for (let i=0;i<ProjectExperience.length;i++){
                                        self.ProjectExperience.push(ProjectExperience[i]);
                                    }
                                }
                                self.infoData.ProjectExperience = ProjectExperience;
                            }


                            if (res.data.data.NeedsInchargeUserName) {
                                let arr = {};
                                self.NeedsInchargeUserNameObj.selectArr = [];
                                arr.title = res.data.data.NeedsInchargeUserName;
                                arr.id = res.data.data.NeedsInchargeUserId;
                                self.NeedsInchargeUserNameObj.selectArr.push(arr);
                            }
                            for (let i = 0; i < self.stageArr.length; i++) {
                                if (res.data.data.Stage == self.stageArr[i].key) {
                                    self.current = i
                                }
                            }
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },

            StudentInfo(studentid, studentName) {
                console.log(studentid, studentName);
                let data = {
                    type: 'UserAdd',
                    name: studentName,
                    id: studentid
                }
                window.parent.postMessage(data, '*')
                //window.open("http://ims.qitianzhen.cn/index.php?m=Kms&c=Student&a=edit&UserId="+studentid,'target','');
            },
            _action() {
                this.infoData = ''
                this.$emit('visible', false)
            },
            nextAction() {
                let i = this.current + 1;
                if (i >= this.stageArr.length) {
                    console.log(222);
                    this.StudentInfo(this.infoData.Id, this.infoData.Name);
                    return false
                }

                this.fieldAction('Stage', this.stageArr[i].key)
            },
            _IsFollow(i) {
                let self = this
                this.$message.loading('操作中....', 0)
                this.$axios.get(
                    1272,
                    {
                        IsFollow: i,
                        ResumeId: self.Id
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.data)
                            self.loadData()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            changeStatus(key) {
                console.log(key)
                this.fieldAction('Stage', key.key)
            },
            handleClose(removedTag) {
                const tags = this.tags.filter(tag => tag !== removedTag)
                this.tags = tags
                this.fieldAction('Tags', this.tags.toString())
            },

            showInput() {
                this.inputVisible = true
                this.$nextTick(function () {
                    this.$refs.input.focus()
                })
            },

            handleInputChange(e) {
                this.inputValue = e.target.value
                // console.log(this.inputValue);
            },

            handleInputConfirm() {
                const inputValue = this.inputValue
                let tags = this.tags
                if (inputValue && tags.indexOf(inputValue) === -1) {
                    tags = [...tags, inputValue]
                }
                Object.assign(this, {
                    tags,
                    inputVisible: false,
                    inputValue: ''
                })
                this.fieldAction('Tags', this.tags.toString())
            },
            fieldAction(field, value) {
                let self = this
                this.$axios.get(
                    1268,
                    {
                        Id: self.Id,
                        Field: field,
                        Value: value
                    },
                    res => {
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg);
                            self.loadData();
                        } else {
                            this.$message.error(res.data.msg);
                        }

                    }
                )
            },
            onClose() {
                this.infoData = ''
                this.$emit('visible', false);
            },
            callback(value) {
                this.tabKey = value
                console.log(value)
            },
            _recommend() {
                this.recruitObj.selectArr = [];
                let arr = {};
                arr.title = this.infoData.NeedsDemandUserName;
                arr.id = this.infoData.NeedsDemandUserId;
                this.recommendUserList.push(arr);
                this.recruitObj.selectArr.push(arr);
                this.recommendStatus = true
            },
            ChangeSearchUser(value, name) {
                let self = this;
                if (name == 'recommendUserList') {
                    this.recommendUserList = value
                } else if (name == 'NeedsInchargeUserName') {
                    if (value.length > 0) {
                        if (!self.infoData.NeedId) {
                            this.$message.error('请先关联招聘需求');
                            return false;
                        }
                        console.log(value);
                        this.$axios.get(1297, {
                            InchargeUserId: value[0].id,
                            Id: self.infoData.NeedId
                        }, res => {
                            if (res.data.code == 0) {
                                this.$message.success(res.data.msg);
                                self.loadData();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    }

                }
            },
            recommendonClose() {
                this.recommendStatus = false
            },
            _recommendAction() {
                let self = this;
                if (self.recommendUserList.length == 0) {
                    this.$message.error('请先选择要转发的人');
                    return false;
                }
                this.$axios.get(1285, {
                    ApprovalerList: JSON.stringify(self.recommendUserList),
                    Remark: self.Remark,
                    ResumeId: self.infoData.Id,
                }, res => {
                    if (res.data.code == 0) {
                        this.recommendStatus = false
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })

            },
            //简历预览
            getPreviewUrl() {
                this.previewStatus = true
                let self = this;
                let ResumeUrl = this.infoData.ResumeUrl;
                if (ResumeUrl.indexOf('png') != -1) {
                    this.ResumeUrlType = true;
                } else if (ResumeUrl.indexOf('jpg') != -1) {
                    this.ResumeUrlType = true;
                } else if (ResumeUrl.indexOf('jpeg') != -1) {
                    this.ResumeUrlType = true;
                } else if (ResumeUrl.indexOf('gif') != -1) {
                    this.ResumeUrlType = true;
                } else {
                    this.$axios.get(
                        1281,
                        {
                            Id: self.Id
                        },
                        res => {
                            if (res.data.code == 1) {
                                let PreviewURL = res.data.data.PreviewURL
                                let aliyuns = require('../../assets/js/aliyun.js')
                                let demo = aliyun.config({
                                    mount: document.querySelector('#container'),
                                    url: PreviewURL //设置文档预览URL地址。
                                })
                                //设置AccessToken。
                                demo.setToken({token: res.data.data.AccessToken})
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        }
                    )
                }

            },
            previewStatusClose() {
                this.previewStatus = false
            }
        }
    }
</script>

<style scoped lang="less">
    .reject-title{
        color: #121316;
        font-size: 16px;
        margin-bottom: 6px;
    }
    .reject-text{
        color: #ABABAB;
        font-size: 12px;
        margin-bottom: 12px;
    }
    .step {
        display: flex;
        align-items: center;
        width: 100%;
        background: #ebedee;
        padding: 42px 15px 10px 15px;
        .step-content {
            width: 90px;

            .step-content-num {
                border-radius: 50%;
                width: 20px;
                height: 20px;
                color: #89909E;
                background: #ffffff;
                text-align: center;
                position: relative;
                margin-bottom: 14px;
                line-height: 20px;
                font-size: 11px;
            }
            .step-content-title {
                color: #89909E;
                font-size: 12px;
                white-space: nowrap;
            }
            .step-content-num-active {
                background: #1890FF;
                color: #ffffff;
            }
            .step-content-title-active {
                color: #1890FF;
            }
        }
        .step-content-right {
            position: relative;
        }
        .step-content-right::after {
            content: '';
            width: 70px;
            height: 4px;
            position: absolute;
            left: 20px;
            top: 8px;
            background: #ffffff;
        }
        .step-content-right-active {
            position: relative;
        }
        .step-content-right-active::after {
            content: '';
            width: 70px;
            height: 4px;
            position: absolute;
            left: 20px;
            top: 8px;
            background: #1890FF;
        }
        .step-content-width {
            width: 40px !important;
        }
    }

    .userInfo {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        .userInfo-name {
            color: #666666;
            font-size: 20px;
            margin-left: 19px;
            margin-right: 14px;
        }
    }

    .recommend-group {
        display: flex;
        margin-top: 18px;
        .recommend-group-label {
            width: 120px;
            .recommend-group-label-active {
                color: #ff0000;
            }
        }
        .recommend-group-item {
            width: 438px;
        }
    }

    .base-info {
        color: #121316;
        font-size: 14px;
    }

    .userInfo-title {
        margin-top: 28px;
        color: #121316;
        font-size: 14px;
        width: 85px;
        padding-bottom: 7px;
        border-bottom: 1px solid #dddfe3;
    }

    .userDetail {
        display: flex;
        width: 400px;
        flex-wrap: wrap;
        .userDetail-item {
            color: #121316;
            font-size: 14px;
            margin-right: 49px;
            margin-top: 10px;
        }
    }

    .jop-list {
        background: #f4f4f6;
        margin-bottom: 20px;
        padding: 8px 49px 16px 21px;
        margin-top: 16px;
        .jop-title {
            color: #121316;
            font-size: 12px;
            font-weight: 600;
        }
        .jop-content {
            color: #121316;
            font-size: 12px;
            /*div{*/
                /*width: 716px;*/
            /*}*/
        }
    }

    .tab-content {
        display: flex;
        .tab-right {
            width: 400px;
            margin-top: 42px;
            margin-left: 20px;
            .tab-right-top {
                background: #ebedee;
                padding: 42px 0px 10px 0px;
                .currentActive {
                    color: #2994ff;
                }
            }

            .tab-rigth-bottom {
                background: #f4f4f5;
                padding: 42px 26px 42px 26px;
                .tab-rigth-button {
                    margin-bottom: 24px;
                    width: 262px;
                    height: 42px;
                    display: block;
                }
                .tab-rigth-button-active {
                    color: #121316;
                    background: #ebedee;
                }
                .tab-rigth-group {
                    margin-bottom: 28px;
                    .tab-rigth-title {
                        margin-bottom: 12px;
                        color: #89909e;
                        font-size: 14px;
                    }
                    .tab-rigth-item {
                        span {
                            color: #121316;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .ant-steps-item-process > .ant-steps-item-tail::after {
        background: #ffffff !important;
    }
    .color-blue{
        color: rgb(24, 144, 255) !important;
    }
    .head-item {
        margin-left: 8px;
        font-size: 16px;
        color: #9c9c9c;
    }
    .ant-tabs-tab{
        padding: 12px 6px;
    }
</style>
