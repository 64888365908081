<template>
    <div class="needsContentInfo" v-if="infoData">
        <div class="head">
            <div class="title">招聘需求详情</div>
            <i @click="_toAction" :class="mouseKey=='save'?'color-blue':''" @mouseover="changeSave('save')"
               @mouseout="_emptyKey" class="iconfont icon-bianji head-item"></i>
            <a-popconfirm
                title="您确定要删除吗?"
                @confirm="_del"
                okText="确定"
                cancelText="取消"
            >
                <i :class="mouseKey=='del'?'color-blue':''" @mouseover="changeSave('del')" @mouseout="_emptyKey"
                   class="iconfont icon-shanchu- head-item"></i>
            </a-popconfirm>

            <a-icon @click="onClose" :class="mouseKey=='close'?'color-blue':''" @mouseover="changeSave('close')"
                    @mouseout="_emptyKey" type="close-circle" class="head-item"/>

        </div>
        <!--<a-divider/>-->
        <div class="content">
            <div class="content-list">
                <div class="content-list-label">职位名称<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.PositionName }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">需求类型<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.DemandType }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">所属部门<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.DepartmentName }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">地址<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.FullDetailAddr }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">招聘人数<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.Number }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">紧急程度<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.UrgentLavel }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">期望完成时间<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.ExpectedCompletionDate }}</div>
            </div>
            <div class="content-list">
                <div class="content-list-label">需求创建<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.CreateTime }}({{ infoData.CreateUserName }})</div>
            </div>
            <div class="list-line">
                <div class="list-line-label" @mouseover="changeSave('PositionDescribe')" @mouseout="_emptyKey">职位描述<i
                    @click="_toAction" v-show="mouseKey=='PositionDescribe'"
                    class="iconfont icon-bianji head-item action-icon"></i></div>
                <div class="list-line-item" v-html="infoData.PositionDescribe"></div>
            </div>
            <div class="content-list">
                <div class="content-list-label">招聘原因<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.Remark }}</div>
            </div>
            <div class="content-list" @mouseover="changeSave('InchargeUserName')" @mouseout="_emptyKey">
                <div class="content-list-label">招聘负责人</div>
                <div class="content-list-item">{{ infoData.InchargeUserName }}<i v-show="mouseKey=='InchargeUserName'"
                                                                                 @click="_toAction"
                                                                                 class="iconfont icon-bianji head-item action-icon"></i>
                </div>
            </div>
            <div class="content-list" @mouseover="changeSave('AssistStaff')" @mouseout="_emptyKey">
                <div class="content-list-label">协助招聘负责人</div>
                <div class="content-list-item">{{ AssistStaff }}<i @click="_toAction" v-show="mouseKey=='AssistStaff'"
                                                                   class="iconfont icon-bianji head-item action-icon"></i>
                </div>
            </div>
            <div class="content-list" @mouseover="changeSave('DemandUserName')" @mouseout="_emptyKey">
                <div class="content-list-label">用人部门负责人<span class="label-require">*</span></div>
                <div class="content-list-item">{{ infoData.DemandUserName }}<i
                    class="iconfont icon-bianji head-item action-icon" @click="_toAction"
                    v-show="mouseKey=='DemandUserName'"></i>
                </div>
            </div>
            <div class="content-list" @mouseover="changeSave('Interviewers')" @mouseout="_emptyKey">
                <div class="content-list-label">面试官</div>
                <div class="content-list-item">{{ Interviewers }}<i @click="_toAction" v-show="mouseKey=='Interviewers'"
                                                                    class="iconfont icon-bianji head-item action-icon"></i>
                </div>
            </div>
            <div class="list-line">
                <div class="list-line-label">需求审批链
                    <span class="label-require">*</span>
                    <span class="check-status" v-viewer @click="previewImg">查看审批流程
                    <img style="display: none" id="checkimages"
                         src="https://sk-fenxiao.qitianzhen.cn/4444444444.png"
                         alt="">
                    </span>
                    <span class="checkStatus"
                          :class="infoData.CheckStatus==1?'checkStatus-tongguo':infoData.CheckStatus==2?'checkStatus-bohui':''">{{ infoData.CheckStatus == 1 ? '通过' : infoData.CheckStatus == 2 ? '驳回' : '审批中' }}</span>
                </div>
                <div class="person-list">
                    <div
                        class="person-user"
                        v-if="Approvers.length>0"
                        v-for="(item, index) in Approvers">

                        <a-popconfirm placement="bottom" ok-text="提醒审批" cancel-text="取消"
                                      @confirm="sendNeedMsgToUser($event,item.id)">
                            <a-icon slot="icon" type="null"/>
                            <template slot="title">
                                <div class="Approvers-userList" v-if="ApproversUserList">
                                    <a-avatar class="Approvers-userList-item" :size="36" icon="user" :src="item.icon"/>
                                    <div class="Approvers-userList-item">
                                        <p>{{ item.title }}</p>
                                        <p>{{ ApproversUserList.ParentName }}</p>
                                    </div>
                                    <div class="Approvers-userList-item">{{ ApproversUserList.KeyPostName }}</div>
                                </div>

                            </template>

                            <div @click="getUserInfo(item.id)">

                                <i v-if="UserStatus" @click.capture.stop="delPerson(index)"
                                   class="iconfont iconfont-active icon-shanchu-"></i>

                                <i v-if="!UserStatus" class="iconfont iconfont-active"
                                   :class="item.Status === 3? 'icon-tongyi': item.Status === 2? 'icon-shenpizhong': item.Status === 4? 'icon-bohui': 'icon-daishenpi'"
                                ></i>
                                <a-avatar :size="36" icon="user" :src="item.icon"/>
                            </div>

                        </a-popconfirm>
                    </div>

                    <div class="add-person">
                        <span>+</span>
                        <person
                            class="add-person-bth"
                            v-bind="neetObj"
                            @getData="ChangeSearchUser($event, 'Approvers')"
                        ></person>
                    </div>
                    <div class="add-person" @click="saveStatus()">
                        <span>-</span>
                    </div>
                </div>
            </div>
            <!--            评论-->
            <div style="margin-top: 20px;margin-left: 12px">
                <a-timeline>
                    <a-timeline-item v-for="item in remarkList">
                        <div slot="dot">
                            <a-avatar :size="36" icon="user"
                                      :src="item.Icon"></a-avatar>
                        </div>
                        <div class="comment-list">
                            <div class="comment-user-info">
                                <div class="comment-user-name">{{item.UserName}}</div>
                                <div class="comment-user-time">{{item.CreateTime}}</div>
                            </div>
                            <div class="comment-remark">
                                {{item.Remark}}
                            </div>
                        </div>
                    </a-timeline-item>
                    <a-timeline-item v-for="item in commonRemarkList">
                        <div slot="dot">
                            <a-avatar :size="36" icon="user" :src="item.Icon"></a-avatar>
                        </div>
                        <div class="comment-list">
                            <div class="comment-user-info">
                                <div class="comment-user-name">{{item.UserName}}</div>
                                <div class="comment-user-time">{{item.CreateTime}}</div>
                            </div>
                            <div class="comment-remark">
                                {{item.Remark}}
                            </div>
                        </div>
                    </a-timeline-item>

                </a-timeline>
            </div>


        </div>
        <!--招聘需求操作-->
        <a-drawer
            title="招聘需求"
            width="1000"
            :closable="false"
            :visible="visible"
            @close="actionClose"
        >
            <div style="margin-bottom: 60px">
                <needsContentAction
                    v-if="visible"
                    :Id="Id"
                    @visible="actionClose"
                ></needsContentAction>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import person from '../../components/SelectPersonnel/SelectPersonnel'
import needsContentAction from 'pages/Hr/needsContentAction';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'

Vue.use(Viewer)
export default {
    name: "needsContentInfo",
    components: {needsContentAction, person},
    data() {
        return {
            infoData: '',
            visible: false,
            Approvers: [],
            UserStatus: false,
            ApproversUserList: "",
            //招聘需求链
            neetObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 100, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            AssistStaff: '',
            Interviewers: '',
            mouseKey: '',
            remarkList:'',
            commonRemarkList:'',
        }
    },
    props: {
        Id: {
            type: String
        }
    },
    created() {
        this.loadData();
        this.getRemark();
    },
    methods: {
        getRemark(){
            let self = this;
           this.$axios.get(129427,{
               Pid:self.Id,
               Type:5
           },res=>{
               if(res.data.code==0){
                   self.remarkList =res.data.data;
                   self.commonRemarkList = res.data.commonRemarkList;
               }
           })
        } ,
        changeSave(val) {
            console.log(val);
            this.mouseKey = val;
        },
        _emptyKey() {
            this.mouseKey = '';
        },
        loadData() {
            let self = this
            this.$message.loading('加载中...', 0)
            this.$axios.get(
                1253,
                {
                    Id: self.Id
                },
                res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.infoData = res.data.data;
                        self.neetObj.selectArr = [];
                        if (res.data.data.ApproverList) {
                            let ApproversArr = res.data.data.ApproverList
                            self.Approvers = ApproversArr
                            for (let i = 0; i < ApproversArr.length; i++) {
                                self.neetObj.selectArr.push(ApproversArr[i])
                            }
                        }
                        //协助招聘负责人
                        if (res.data.data.AssistStaff) {
                            let AssistStaffArr = JSON.parse(res.data.data.AssistStaff);
                            let AssistStaff = [];
                            for (let i = 0; i < AssistStaffArr.length; i++) {
                                AssistStaff.push(AssistStaffArr[i].title)
                            }
                            self.AssistStaff = AssistStaff.toString();
                        }
                        //面试官
                        if (res.data.data.Interviewers) {
                            let InterviewersArr = JSON.parse(res.data.data.Interviewers);
                            let Interviewers = [];
                            for (let i = 0; i < InterviewersArr.length; i++) {
                                Interviewers.push(InterviewersArr[i].title)
                            }
                            self.Interviewers = Interviewers.toString();
                        }


                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },

        _toAction() {
            this.visible = true;
        },
        actionClose() {
            this.visible = false;
            this.loadData();
        },
        onClose() {
            this.remarkList ='';
            this.commonRemarkList ='';
            this.$emit('visible', false)
        },
        getUserInfo(id) {
            console.log(id);
            if (this.ApproversUserList && this.ApproversUserList.Id == id) {
                return false;
            }
            let self = this;
            this.$axios.get(12936, {Id: id}, res => {
                if (res.data.code == 0) {
                    self.ApproversUserList = res.data.data;

                } else {
                    self.ApproversUserList = '';
                }
            })
        },
        _del() {
            let self = this
            this.$message.loading('操作中', 0)
            this.$axios.get(
                12911,
                {
                    Id: self.Id,
                    Type: 1
                },
                res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        self.onClose();
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        previewImg() {
            var e = document.createEvent('MouseEvents') //这里的clickME可以换成你想触发行为的DOM结点
            e.initEvent('click', true, true) //这里的click可以换成你想触发的行为
            document.getElementById('checkimages').dispatchEvent(e)
        },
        ChangeSearchUser(value, name) {
            if (name == 'Approvers') {
                let arr = []
                for (let i = 0; i < value.length > 0; i++) {
                    value[i].Status = 0
                    arr.push(value[i])
                }
                this.Approvers = arr
                this.UserStatus = false
                this.personAction();
            }

        },
        personAction() {
            let self = this;
            this.$axios.get(12937, {
                Id: self.Id,
                Approvers: JSON.stringify(self.Approvers),
            }, res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.loadData();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        delPerson(i) {
            console.log(222)
            this.Approvers.splice(i, 1);
            this.neetObj.selectArr = this.Approvers;
            this.personAction();
        },
        saveStatus() {
            this.UserStatus = !this.UserStatus
        },
        sendNeedMsgToUser(e, id) {

            let self = this;
            this.$axios.get(12935, {
                NeedId: self.Id,
                UserId: id,
            }, res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },

    }
}
</script>

<style scoped lang="less">
.comment-list {
    margin-left: 20px;

    .comment-user-info {
        display: flex;
        align-items: center;

        .comment-user-name {
            color: rgba(0, 0, 0, .65);
            font-size: 14px;
            margin-right: 8px;
        }

        .comment-remark {
            color: #999;
            font-size: 12px;
        }
    }
    .comment-r{
        color: rgba(0,0,0,.65);
        font-size: 14px;
    }

}

.Approvers-userList {
    display: flex;

    .Approvers-userList-item {
        margin-right: 12px;
    }
}

.head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    padding-bottom: 16px;

    .title {
        color: #666666;
        font-size: 16px;
    }

    .head-item {
        margin-left: 8px;
        font-size: 16px;
        color: #9c9c9c;
    }
}

.content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;

    .content-list {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;

        .content-list-label {
            /*width: 120px;*/
            color: #ABB0BF;
            font-size: 14px;

        }

        .content-list-item {
            color: #666666;
            font-size: 14px;
            margin-left: 6px;
        }
    }

    .list-line {
        width: 100%;
        margin-bottom: 20px;

        .list-line-label {
            color: #ABB0BF;
            font-size: 14px;
            margin-bottom: 12px;
        }

        .list-line-item {
            color: #666666;
            font-size: 14px;
            margin-left: 6px;
        }
    }
}

.action-icon {
    color: #2994FF;
    margin-left: 8px;
}

.label-require {
    color: red;
    margin-left: 5px;
}

.check-status {
    color: #2994ff !important;
    cursor: pointer;
    margin-left: 5px;
}

.checkStatus {
    margin-left: 40px;
    color: #2994ff !important;
}

.checkStatus-tongguo {
    color: rgb(82, 196, 26) !important;
}

.checkStatus-bohui {
    color: rgb(255, 0, 0) !important;
}

.icon-daishenpi {
    color: rgb(171, 176, 191);;
}

.icon-shenpizhong {
    color: rgb(24, 144, 255);
}

.color-blue {
    color: rgb(24, 144, 255) !important;
}

.icon-bohui {
    color: rgb(255, 0, 0);;
}

.icon-tongyi {
    color: rgb(82, 196, 26);;
}

.person-list {
    display: flex;

    .person-user {
        position: relative;
        cursor: pointer;
        margin-right: 18px;

        .iconfont-active {
            position: absolute;
            width: 12px;
            height: 12px;
            top: -10px;
            z-index: 10;
            right: -5px;
        }
    }

    .add-person {
        width: 36px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #c1c5cc;
        border-radius: 50%;
        text-align: center;
        /*line-height: 28px;*/
        position: relative;
        font-size: 24px;
        color: #c1c5cc;
        cursor: pointer;
        margin-right: 18px;

        span {
            font-size: 24px;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .add-person-bth {
            opacity: 0;
            z-index: 10;
            height: 100%;
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            /*width: 100%;*/
            /*height: 100%;*/
            /*z-index: 1;*/
        }
    }
}
</style>