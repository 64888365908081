<template>
     <div class="interview">
         <div class="content" style="flex: 1;">
             <div v-if="dataList.length>0" v-for="(item, index) in dataList" class="content" style="margin-bottom: 20px">
                 <div class="interview-message">
                     <div class="interview-message-title" :class="item.StatusColor ==1?'status-color':''">

                         {{ item.Week }} {{ item.TheDate }} ·
                         {{ InterviewstypeArr[item.InterviewType] }}
                     </div>
                     <a-select
                             class="interview-message-feedback"
                             placeholder="候选人反馈"
                             v-model="item.InterviewStatus"
                             @change="changeInterviewStatus($event, item.Id)"
                     >
                         <a-select-option value="0">候选人未反馈</a-select-option>
                         <a-select-option value="1">候选人同意面试</a-select-option>
                         <a-select-option value="2">候选人拒绝面试</a-select-option>
                     </a-select>
                     <a-dropdown :trigger="['click']" class="interview-message-action">
                         <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                             <a-icon type="more" style="font-size: 28px"/>
                         </a>
                         <a-menu slot="overlay" @click="changeStatus($event, index)">
                             <a-menu-item key="3">
                                 取消面试
                             </a-menu-item>
                             <a-menu-item key="1">
                                 修改面试
                             </a-menu-item>
                             <a-menu-item key="2">
                                 重新发送面试邮件
                             </a-menu-item>
                             <a-menu-item key="4">
                                 邀约未到面
                             </a-menu-item>
                         </a-menu>
                     </a-dropdown>
                 </div>

                 <div class="content-interview">
                     <div class="interview-group">
                         <div class="interview-label"
                              :class="item.StatusColor ==1?'status-color':''">
                             面试地点
                         </div>
                         <div class="interview-item"
                              :class="item.StatusColor ==1?'status-color':''">
                             {{ item.InterviewAddress }}
                         </div>
                     </div>
                     <!--<div class="interview-group" style="margin-bottom: 29px">-->
                         <!--<div class="interview-label"-->
                              <!--:class="item.StatusColor ==1?'status-color':''">-->
                             <!--面试负责人-->
                         <!--</div>-->
                         <!--<div class="interview-item"-->
                              <!--:class="item.StatusColor ==1?'status-color':''">-->
                             <!--{{ infoData.NeedsDemandUserName }}-->
                         <!--</div>-->
                     <!--</div>-->
                     <div class="interview-group" style="margin-bottom: 29px">
                         <div class="interview-label"
                              :class="item.StatusColor ==1?'status-color':''">
                             招聘负责人
                         </div>
                         <div class="interview-item"
                              :class="item.StatusColor ==1?'status-color':''">
                             {{ infoData.NeedsInchargeUserName }}
                         </div>
                     </div>


                     <div v-if="item.Stage.length > 0" v-for="(items, key) in item.Stage">
                         <div class="interview-group">
                             <div class="interview-label"
                                  :class="item.StatusColor ==1?'status-color':''">
                                 {{key==0?'面试信息':''}}
                             </div>
                             <div
                                     class="first-try"
                                     :style="
            'background:' +
            (items.InterviewType == 20
            ? '#2994FF'
            : items.InterviewType == 30
            ? '#6C63FF'
            : '')
            "
                             >
                                 {{ InterviewTypeArr[items.InterviewType] }}
                             </div>
                             <div class="interview-item"
                                  :class="item.StatusColor ==1?'status-color':''">
                                 {{ items.InterviewTime }} 时长：{{ items.InterviewMinute }}分钟
                             </div>
                         </div>
                         <div class="interview-group interview-group-active"
                              style="margin-bottom: 0px;height: auto;padding-top: 5px;padding-bottom: 0px"
                              :style="!items.InterviewEvaluation?'margin-bottom: 15px;padding-bottom: 5px':''">
                             <div class="interview-label"
                                  :class="item.StatusColor ==1?'status-color':''">
                                 {{ items.InterviewerName }}
                             </div>
                             <div class="interview-item" style="text-align: left;width: 120px"
                                  :class="item.StatusColor ==1?'status-color':''">
                                 {{ InterviewResultArr[items.InterviewResult] }}
                             </div>
                             <div
                                     class="fill-feedback"
                                     @click="fillFeedback(items.Id,items.InterviewEvaluation,items.InterviewResult)"
                                     :class="item.StatusColor ==1?'status-color':''">
                                 {{items.InterviewResult!=0?'修改反馈':'填写反馈'}}

                             </div>
                             <!--<div class="fill-feedback">{{ items.InterviewStatus }}</div>-->
                         </div>
                         <div class="interview-group interview-group-active" v-if="items.InterviewEvaluation"
                              style="margin-bottom: 15px;padding-bottom: 5px">
                             <!--<div class="interview-label"></div>-->
                             <div style="flex: 1;word-break: break-all;margin-top: 5px;text-align: left;"
                                  :class="item.StatusColor ==1?'status-color':''">
                                 <div v-for="initem in items.InterviewEvaluation.split('\n')">{{initem}}</div>
                                 <!--{{items.InterviewEvaluation}}-->
                             </div>
                         </div>
                     </div>
                     <div class="cancel-interview"
                          :class="item.StatusColor ==1?'status-color':''"
                          v-if="item.StatusColor ==1">{{item.Status ==20?'取消面试':item.Status
                         ==30?'邀约未到面':''}}:{{item.Reason}}
                     </div>
                     <div class="interview-group" style="margin-bottom: 29px"
                          v-if="item.InterviewStatus ==2">
                         <div class="interview-label"
                              :class="item.StatusColor ==1?'status-color':''">
                             拒绝面试
                         </div>
                         <div class="interview-item"
                              :class="item.StatusColor ==1?'status-color':''">
                             {{ item.Reason }}
                         </div>
                     </div>
                 </div>
             </div>
         </div>

         <!--取消面试startr-->
         <a-drawer
                 title="你确定要取消整场面试吗？"
                 width="800"
                 :closable="false"
                 :visible="visible2"
                 @close="onClose2"
         >
             <div class="cancel">
                 <div class="cancel-group">
                     <div class="cancel-lebel">
                         取消原因 <span class="cancel-lebel-active">*</span>
                     </div>
                     <a-textarea
                             @blur="_cancelWaeditor"
                             class="cancel-item"
                             v-model="Reason"

                     />
                 </div>
                 <!--<a-checkbox :checked="checkCabcelMessage" @change="checkCabcelChange">短信通知候选人</a-checkbox>-->
                 <div class="cancel-group">
                     <div class="cancel-lebel"></div>
                     <div
                             id="websiteEditorElem2"
                             class="websiteEditorElem2"
                             style="width: 500px"
                     ></div>
                 </div>
             </div>

             <div
                     :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                     style=" z-index: 100000"
             >
                 <a-button style="marginRight: 8px" @click="onClose2">
                     取消
                 </a-button>
                 <a-button type="primary" @click="_Reason">
                     确认
                 </a-button>
             </div>
         </a-drawer>
         <!--取消面试end-->
         <!--候选人评价start-->
         <a-drawer
                 title="候选人评价"
                 width="800"
                 :closable="false"
                 :visible="visible3"
                 @close="onClose3"
         >
             <div class="feedback-tips">
                 请详细说明候选人的优势和不足
             </div>
             <div class="cancel">
                 <div class="cancel-group">
                     <div class="cancel-lebel">
                         面试评价 <span class="cancel-lebel-active">*</span>
                     </div>
                     <a-textarea class="cancel-item" v-model="InterviewEvaluation"/>
                 </div>
                 <div class="cancel-group">
                     <div class="cancel-lebel">
                         面试结果 <span class="cancel-lebel-active">*</span>
                     </div>
                     <div class="cancel-item" style="width: 400px">
                         <a-button
                                 class="cancel-item-check"
                                 :class="InterviewResult == 2 ? 'cancel-item-check-active' : ''"
                                 @click="_InterviewResult('2')"
                         >合适
                         </a-button>
                         <a-button
                                 class="cancel-item-check"
                                 :class="InterviewResult == 3 ? 'cancel-item-check-active' : ''"
                                 @click="_InterviewResult(3)"
                         >不合适
                         </a-button>
                     </div>
                 </div>
             </div>

             <div
                     :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                     style=" z-index: 100000"
             >
                 <a-button style="marginRight: 8px" @click="onClose3">
                     取消
                 </a-button>
                 <a-button type="primary" @click="tofillFeedback">
                     确认
                 </a-button>
             </div>
         </a-drawer>
         <!--候选人评价end-->
         <!--重新发送面试邮件start-->
         <a-drawer
                 title="重新发送面试邮件"
                 width="800"
                 :closable="false"
                 :visible="visible4"
                 @close="closeEmail"
         >
             <div class="cancel">

                 <!--<a-checkbox :checked="checkCabcelMessage" @change="checkCabcelChange">短信通知候选人</a-checkbox>-->
                 <div class="cancel-group">
                     <!--<div class="cancel-lebel"></div>-->
                     <div
                             id="websiteEditorElem4"
                             class="websiteEditorElem4"
                             style="width: 500px"
                     ></div>
                 </div>
             </div>

             <div
                     :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                     style=" z-index: 100000"
             >
                 <a-button style="marginRight: 8px" @click="closeEmail">
                     取消
                 </a-button>
                 <a-button type="primary" @click="_emailAction">
                     确认
                 </a-button>
             </div>
         </a-drawer>
         <!--重新发送面试邮件end-->
         <!--邀约未到面-->
         <a-drawer
                 :title="infoData.Name+'邀约未到面'"
                 width="800"
                 :closable="false"
                 :visible="InterviewStatusvisible"
                 @close="CloseInterviewStatus"
         >
             <div class="cancel">
                 <div class="cancel-group">
                     <div class="cancel-lebel">
                         邀约未到面原因 <span class="cancel-lebel-active">*</span>
                     </div>
                     <a-textarea

                             class="cancel-item"
                             v-model="InterviewStatusContent"
                     />
                 </div>

             </div>

             <div
                     :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
                     style=" z-index: 100000"
             >
                 <a-button style="marginRight: 8px" @click="CloseInterviewStatus">
                     取消
                 </a-button>
                 <a-button type="primary" @click="_InterviewStatusAction">
                     确认
                 </a-button>
             </div>
         </a-drawer>
     </div>
</template>

<script>
    import E from 'wangeditor'
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    export default {
        components: {person},
        name: "interview",
        data(){
            return{
                dataList:[],
                InterviewstypeArr: {'1': '现场面试', '2': '线上面试'},
                InterviewResultArr: {'0': '未评估', '1': '非常满意', '2': '合适', '3': '不合适', '4': '非常不满意'},
                InterviewTypeArr: {'10': '初试', '20': '复试', '30': '终试'},
                //取消面试
                Reason: '',
                CancelContent:
                    '<p>&nbsp; <p id="UserName"  class="wangeditor-style" style="background: #F4F4F6">你好：</p><br>面试取消 <p id="Reason" style="background: #F4F4F6" class="wangeditor-style">取消原因</p> 面试官临时有事情，给你带来不便还请谅解，感谢<br> 感谢关注！                                                  很高兴收到您的简历，想和您就 <p id="Position" style="background: #F4F4F6" class="wangeditor-style"> 职位安排一次面试。</p> <p id="TheDate" class="wangeditor-style" style="background: #F4F4F6">面试日期</p> <p id="InterviewTime" style="background: #F4F4F6" class="wangeditor-style">面试时间</p> <p id="InterviewMinute" class="wangeditor-style" style="background: #F4F4F6">面试时长</p> （可能视面试情况缩减或延长，请预留一定缓冲时间）<p id="InterviewType"  class="wangeditor-style" style="background: #F4F4F6">面试形式</p> <p id="InterviewAddress"  class="wangeditor-style" style="background: #F4F4F6">面试地址</p> <p id="hrName" style="background: #F4F4F6" class="wangeditor-style">联系人:</p> <p id="hrMovePhone" style="background: #F4F4F6" class="wangeditor-style">电话:</p> <p id="hrMail" style="background: #F4F4F6" class="wangeditor-style"> 邮箱:</p>',
                //重新发送面试邮件
                visible4: false,
                emailContent: '',
                emailId: '',
                InterviewStatusvisible: false,
                InterviewStatusId: '',
                InterviewStatusContent: '',
                resumeLink: 'https://ims.qitianzhen.cn/index.php?m=Kms&c=offer&a=user_login',
                wangeditor: '',
                visible2: false,
                //面试评价
                InterviewId: '',
                InterviewEvaluation: '',
                visible3: false,
                InterviewResult: 0,
                wangeditor2:'',
                interviewObject:{},
                
            }
        },
        props: {
            infoData: {
                type: Object
            },
            Pid: {
                //简历Id
                type: String
            }
        },
        created(){
            this.loadData();
        },
        methods:{
            loadData() {
                let self = this;
                self.$message.loading('加载中...', 0);
                self.dataList =[];
                self.$axios.get(1270, {Pid: self.Pid}, res => {
                    self.$message.destroy()
                    if (res.data.code == 0) {
                        self.dataList = res.data.data;
                        for (let i = 0; i < self.dataList.length; i++) {
                            self.dataList[i].StatusColor = 0;
                            self.dataList[i].InterviewStatus = self.dataList[i].InterviewStatus + '';
                            let status = self.dataList[i].Status;

                            if (status == 20 || status == 30 || self.dataList[i].InterviewStatus == 2) {
                                self.dataList[i].StatusColor = 1;
                            }
                        }
                    } else {
                        self.dataList = []
                    }
                })
            },
            changeStatus(key, index) {
                let self = this;
                if (key.key == 1) {
                    let infoData = this.dataList[index]
                    this.$emit('interviewData', infoData);
                    // this.$emit('interviewData', infoData)

                } else if (key.key == 3) {
                    if (this.dataList[index].Status == 30) {
                        this.$message.error('已经是邀约未到面状态，不能更改');
                        return false;
                    }
                    this.InterviewId = this.dataList[index].Id
                    this.Reason = this.dataList[index].Reason;
                    if (this.dataList[index].CancelContent) {
                        this.CancelContent = this.dataList[index].CancelContent
                    }

                    let infoData = this.dataList[index]
                    if (infoData.InterviewAddress) {
                        self.InterviewAddress = infoData.InterviewAddress
                    } else {
                        self.InterviewAddress = self.infoData.FullDetailAddr
                    }
                    self.TheDate = infoData.TheDate
                    self.InterviewType = infoData.InterviewType + ''
                    self.Stage = infoData.Stage
                    this.interviewObject = infoData;
                    this.visible2 = true
                    this._wangeditor2()
                } else if (key.key == 2) {
                    this.emailContent = this.dataList[index].Content;
                    this.visible4 = true;
                    this.emailId = this.dataList[index].Id;
                    this._wangeditor4();

                } else if (key.key == 4) {
                    if (this.dataList[index].Status == 20) {
                        this.$message.error('已取消面试，不能更改');
                        return false;
                    }
                    this.InterviewStatusId = this.dataList[index].Id;
                    this.InterviewStatusContent = this.dataList[index].Reason;
                    this.InterviewStatusvisible = true;
                }
            },
            changeInterviewStatus(value, id) {
                let self = this
                this.$message.loading('操作中...', 0)
                this.$axios.get(
                    1274,
                    {
                        Id: id,
                        InterviewStatus: value
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            self.loadData()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },

            //取消面试start
            _cancelWaeditor() {
                let self = this;

                document.getElementById("Reason").innerText = self.Reason ? "取消原因:" + self.Reason : '取消原因:';
                this.wangeditor2.change()

            },
            changewangeditor(){
                let self = this;
                let startMinute = ''
                let endMinute = ''
                let num = 0
                if (this.interviewObject.Stage.length > 0) {
                    startMinute = self.interviewObject.Stage[0].InterviewTime
                    if (startMinute) {
                        if (self.interviewObject.Stage.length == 1) {
                            let startMinutestrs = new Array() //定义一数组
                            startMinutestrs = startMinute.split(':') //字符分割
                            if (
                                parseInt(startMinutestrs[1]) +
                                parseInt(self.interviewObject.Stage[0].InterviewMinute) >
                                60
                            ) {
                                endMinute =
                                    parseInt(startMinutestrs[0]) +
                                    1 +
                                    ':' +
                                    (parseInt(startMinutestrs[1]) +
                                        parseInt(self.interviewObject.Stage[0].InterviewMinute) -
                                        60)
                            } else {
                                endMinute =
                                    startMinutestrs[0] +
                                    ':' +
                                    (parseInt(startMinutestrs[1]) +
                                        parseInt(self.interviewObject.Stage[0].InterviewMinute))
                            }
                        } else {
                            let key = parseInt(self.interviewObject.Stage.length) - 1
                            endMinute = self.interviewObject.Stage[key].InterviewTime
                        }
                        let InterviewMinute = 0
                        for (let i = 0; i < self.interviewObject.Stage.length; i++) {
                            if (self.interviewObject.Stage[i].InterviewMinute) {
                                InterviewMinute += self.interviewObject.Stage[i].InterviewMinute
                            }

                        }
                        num = InterviewMinute / 60
                        num = num.toFixed(1) // 输出结果为 2.45
                    }
                }

                document.getElementById("Position").innerText = self.infoData.NeedsPositionName ? self.infoData.NeedsPositionName + "职位安排一次面试" : '职位安排一次面试';
                document.getElementById("UserName").innerText = self.infoData.Name ? self.infoData.Name + '您好：' : '';

                document.getElementById("Reason").innerText = self.Reason ? "取消原因:" + self.Reason : '取消原因:';
                    

                document.getElementById("TheDate").innerText = self.interviewObject.TheDate ? "面试日期:" + self.interviewObject.TheDate : '面试日期:';
                if (startMinute) {
                    document.getElementById("InterviewTime").innerText = "面试时间:" + (startMinute + '-' + endMinute);
                } else {
                    document.getElementById("InterviewTime").innerText = "面试时间:";
                }

                document.getElementById("InterviewMinute").innerText = num > 0 ? "面试时长:" + (num + '小时') : '面试时长:';
                document.getElementById("InterviewType").innerText = self.interviewObject.InterviewType ? "面试形式:" + self.InterviewstypeArr[self.interviewObject.InterviewType] : '面试形式:';
                document.getElementById("InterviewAddress").innerText = self.interviewObject.InterviewAddress ? "面试地址:" + self.interviewObject.InterviewAddress : '面试地址:';



                document.getElementById("hrName").innerText = self.infoData.hrName ? "联系人:" + self.infoData.hrName : '联系人:';
                document.getElementById("hrMovePhone").innerText = self.infoData.hrMovePhone ? "电话:" + self.infoData.hrMovePhone : '电话:';
                document.getElementById("hrMail").innerText = self.infoData.hrMail ? "邮箱:" + self.infoData.hrMail : '邮箱:';

                this.wangeditor2.change()
            },
           
            _wangeditor2() {
                document.getElementsByClassName('websiteEditorElem2').innerHTML = ''
                let wangeditor = new E('#websiteEditorElem2');
                this.wangeditor2 = wangeditor;
                let self = this
                setTimeout(function () {

                    wangeditor.customConfig.customUploadImg = (files, insert) => {
                        self.__uploadFile__(files).then(res => {
                            if (res) {
                                if (res) {
                                    for (var i = 0; i < res.length; i++) {
                                        insert(res[i].FileUrl)
                                    }
                                }
                            }
                        })
                    }
                    wangeditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor', // 文字颜色
                        'backColor', // 背景颜色
                        'list',
                        'link', // 插入链接
                        'justify', // 对齐方式
                        'quote', // 引用
                        'emoticon', // 表情
                        'image', // 插入图片
                        'table' // 表格
                    ]
                    // let wangeditorField =  self.fieldList[e].FieldName;
                    wangeditor.customConfig.onchange = function (html) {
                        self.CancelContent = html
                    }
                    // 创建一个富文本编辑器
                    wangeditor.create();
                    wangeditor.txt.html(self.CancelContent);
                    self.changewangeditor();
                }, 400)
            },
            _Reason() {
                let self = this
                if (!this.Reason) {
                    this.$message.error('请输入取消的原因')
                    return false
                }
                this.$message.loading('操作中...', 0)
                this.$axios.post(
                    1275,
                    {
                        Status: 20,
                        Id: self.InterviewId,
                        Reason: self.Reason,
                        CancelContent: self.CancelContent
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            self.loadData()
                            self.onClose2()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },

            checkCabcelChange(e) {
                this.checkCabcelMessage = e.target.checked
            },
            onClose2() {
                // document.getElementsByClassName('websiteEditorElem2').innerHTML = '';
                this.Reason = ''
                this.InterviewId = ''
                this.TheDate = ''
                this.InterviewType = '1'
                this.InterviewAddress = this.infoData.FullDetailAddr
                this.Stage = [
                    {
                        InterviewType: '',
                        InterviewTime: '',
                        InterviewMinute: '',
                        // InterviewStatus: '面试未进行',
                        InterviewUserName: '',
                        InterviewEvaluation: '',
                        InterviewResult: '未评估',
                        InterviewUserList: [],
                        recruitObj: {
                            rank: 0, //职级 0为不选，1为选
                            nums: 1, //选的数量
                            department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                            contacts: 'recent11', //常用联系人 不要常用联系人传空''
                            selectArr: [] //要传入的数据
                        }
                    }
                ]
                this.CancelContent =
                    '<p>&nbsp; <p id="UserName"  class="wangeditor-style" style="background: #F4F4F6">你好：</p><br>面试取消 <p id="Reason" style="background: #F4F4F6" class="wangeditor-style">取消原因</p> 面试官临时有事情，给你带来不便还请谅解，感谢<br> 感谢关注！                                                  很高兴收到您的简历，想和您就 <p id="Position" style="background: #F4F4F6" class="wangeditor-style"> 职位安排一次面试。</p> <p id="TheDate" class="wangeditor-style" style="background: #F4F4F6">面试日期</p> <p id="InterviewTime" style="background: #F4F4F6" class="wangeditor-style">面试时间</p> <p id="InterviewMinute" class="wangeditor-style" style="background: #F4F4F6">面试时长</p> （可能视面试情况缩减或延长，请预留一定缓冲时间）<p id="InterviewType"  class="wangeditor-style" style="background: #F4F4F6">面试形式</p> <p id="InterviewAddress"  class="wangeditor-style" style="background: #F4F4F6">面试地址</p> <p id="hrName" style="background: #F4F4F6" class="wangeditor-style">联系人:</p> <p id="hrMovePhone" style="background: #F4F4F6" class="wangeditor-style">电话:</p> <p id="hrMail" style="background: #F4F4F6" class="wangeditor-style"> 邮箱:</p>';
                this.interviewObject = {};
                this.visible2 = false;
                this.wangeditor2 = '';
                this.loadData()
            },
            //取消面试end
            //候选人评价start
            fillFeedback(id, InterviewEvaluation, InterviewResult) {
                this.InterviewId = id
                this.InterviewEvaluation = InterviewEvaluation
                this.InterviewResult = InterviewResult

                this.visible3 = true
            },
            _InterviewResult(value) {
                this.InterviewResult = value
                // this.Stage[this.StageKey].InterviewResult = value;
            },
            tofillFeedback() {
                let self = this
                if (!this.InterviewEvaluation) {
                    this.$message.error('请输入面试评价')
                    return false
                }
                if (!this.InterviewResult) {
                    this.$message.error('请选择面试结果')
                    return false
                }
                this.$message.loading('操作中...', 0)
                this.$axios.post(
                    1273,
                    {
                        Id: self.InterviewId,
                        InterviewEvaluation: self.InterviewEvaluation,
                        InterviewResult: self.InterviewResult
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            self.loadData()
                            self.onClose3()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            onClose3() {
                this.InterviewResult = 0
                this.InterviewEvaluation = ''
                this.visible3 = false
            },
            //候选人评价end
            //重新发送面试邮件

            CloseInterviewStatus() {
                this.InterviewStatusId = '';
                this.InterviewStatusvisible = false;
                this.InterviewStatusContent = '';
                this.loadData()
            },
            _InterviewStatusAction() {
                let self = this;
                if (!self.InterviewStatusContent) {
                    this.$message.error('请输入原因');
                    return false;
                }
                this.$message.loading('操作中...', 0)
                this.$axios.post(
                    1275,
                    {
                        Id: self.InterviewStatusId,
                        Status: 30,
                        Reason: self.InterviewStatusContent,
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            self.CloseInterviewStatus();
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )

            },
            _wangeditor4() {
                document.getElementsByClassName('websiteEditorElem4').innerHTML = ''
                let wangeditor = new E('#websiteEditorElem4')
                let self = this
                setTimeout(function () {
                    wangeditor.customConfig.customUploadImg = (files, insert) => {
                        self.__uploadFile__(files).then(res => {
                            if (res) {
                                if (res) {
                                    for (var i = 0; i < res.length; i++) {
                                        insert(res[i].FileUrl)
                                    }
                                }
                            }
                        })
                    }
                    wangeditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor', // 文字颜色
                        'backColor', // 背景颜色
                        'list',
                        'link', // 插入链接
                        'justify', // 对齐方式
                        'quote', // 引用
                        'emoticon', // 表情
                        'image', // 插入图片
                        'table' // 表格
                    ]
                    // let wangeditorField =  self.fieldList[e].FieldName;
                    wangeditor.customConfig.onchange = function (html) {
                        console.log(html)
                        self.emailContent = html
                    }
                    // 创建一个富文本编辑器

                    wangeditor.create()
                    wangeditor.txt.html(self.emailContent)
                }, 400)
            },
            closeEmail() {
                this.emailContent = '<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ，您好<br> <br>感谢关注！                                                  很高兴收到您的简历，想和您就<br><br>职位安排一次面试。<br><br>面试日期<br>面试时间<br>面试时长<br>（可能视面试情况缩减或延长，请预留一定缓冲时间）<br>面试形式<br>面试地址<br><br>联系人<br>联系电话<br>联系邮箱';
                this.visible4 = false;
                this.emailId = '';
            },
            _emailAction() {
                let self = this;
                this.$axios.post(1289, {
                    Id: self.emailId,
                    Content: self.emailContent
                }, res => {
                    if (res.data.code == 0) {
                        self.closeEmail();
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .interview-message {
        display: flex;
        height: 44px;
        align-items: center;
        background: #f9f9fa;
        .interview-message-title {
            margin-left: 26px;
            color: #121316;
            font-size: 14px;
            font-weight: 600;
            flex: 1;
            text-align: left;
        }
        .interview-message-feedback {
            width: 156px;
            height: 32px;
            margin-right: 5px;
        }
        .interview-message-action {
            /*width: 31px;*/
            /*font-size: 28px;*/
            border: 1px solid #ccd1dc;
            border-radius: 2px;
            cursor: pointer;
            margin-right: 5px;
            /*height: 32px;*/
        }
    }
    .content-interview {
        border: 1px solid #e4e4e4;
        border-top: 0px;
        padding: 16px 22px 20px 22px;
        .interview-group {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            /*height: 41px;*/
            .interview-label {
                color: #121316;
                font-size: 14px;
                margin-right: 90px;
                text-align: left;
                width: 80px;
            }
            .interview-item {
                color: #121316;
                font-size: 14px;
            }
            .first-try {
                width: 42px;
                height: 21px;
                background: #00d1db;
                border-radius: 2px;
                margin-right: 8px;
                color: #ffffff;
            }
            .fill-feedback {
                color: #2994ff;
                font-size: 14px;
                /*margin-right: 26px;*/
                cursor: pointer;
                flex: 1;
                text-align: right;
                margin-right: 15px;
            }
        }
        .interview-group-active {
            padding-left: 10px;
            background: #f9f9fa;
        }
    }
    .cancel {
        border-right: 1px solid #e4e4e4;
        min-height: 800px;
        .cancel-group {
            margin-bottom: 14px;
            display: flex;
            .cancel-lebel {
                color: #121316;
                font-size: 12px;
                width: 120px;
                .cancel-lebel-active {
                    color: #ff0000;
                }
            }
            .cancel-item {
                width: 500px;
                height: 116px;
                background: #ffffff;
                /*border: 1px solid #D0D3D8;*/
                .cancel-item-check {
                    width: 148px;
                    height: 39px;
                    background: #f8f9fa;
                    font-size: 12px;
                    color: #666666;
                    margin-right: 8px;
                    margin-bottom: 8px;
                }
                .cancel-item-check-active {
                    color: #fff;
                    background-color: #1890ff;
                    border-color: #1890ff;
                }
            }
        }
        #websiteEditorElem2 {
            width: 500px;
            margin-top: 12px;
        }
    }
    .status-color {
        color: #ABB0BF !important;
    }
</style>